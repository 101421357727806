import React from "react";
import Profile from "components/Profile/Profile";

interface IProps {
  children: React.ReactNode;
}
const WrapperLayout: React.FC<IProps> = ({ children }) => {
  return (
    <section className="container sm:p-8 px-1 py-16 xl:overflow-hidden overflow-y-auto max-h-screen">
      <Profile />
      {children}
    </section>
  );
};

export default WrapperLayout;
