import Toast from "components/Toast/Toast";
import MainLayout from "components/layouts/MainLayout";
import Routes from "routes/Routes";
function App() {
  return (
    <MainLayout>
      <Routes />
      <Toast />
    </MainLayout>
  );
}

export default App;
