import React from "react";

interface IProps extends React.InputHTMLAttributes<HTMLTextAreaElement> {
  label: string;
  error: string | boolean | undefined;
}

const Textarea: React.FC<IProps> = ({
  label,
  placeholder,
  onChange,
  error,
  style,
  value,
  className,
  name,
}) => {
  return (
    <div className={["flex flex-col", className].join(" ")} style={style}>
      <label className="text-sm font-bold font-sans text-[#282082] mb-[19px] dark:text-white">
        {label}
      </label>
      <textarea
        placeholder={placeholder}
        onChange={onChange}
        name={name}
        value={value}
        className="py-4 px-5 border rounded-md border-light text-black font-sans font-bold text-base focus-visible:outline-main resize-none h-[135px] placeholder:font-normal placeholder:text-placeholder dark:text-white dark:bg-darkinput dark:border-darkinput"
      />
      {error && <p className="font-sans text-sm text-error mt-1">{error}</p>}
    </div>
  );
};

export default Textarea;
