import { useEffect } from "react";
import Input from "components/Input/Input";
import Textarea from "components/Textarea/Textarea";
import Button from "components/Button/Button";
import Select from "components/Select/Select2";
import DatePicker from "components/DatePicker/DatePicker";
import { useFormik } from "formik";
import { callLogSchema } from "utils/validationSchema";
import AutoComplete from "components/AutoComplete/AutoComplete";
import {
  CALLLOGINITIALVALUES,
  INFRACTIONOPTIONS,
  DISCIPLINARYOPTIONS,
} from "constants/index";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import { EmployeeSlice } from "redux/features/employee/employeeSlice";
import { LogData, useEmployee } from "hooks/useEmployee";
import {
  getCurrentMonth,
  getCurrentWeek,
  getCurrentYear,
  getInfractionTitle,
} from "utils";
import { UserSlice } from "redux/features/user/userSlice";

const Form = () => {
  const { employees } = useSelector<RootState, EmployeeSlice>(
    (state) => state.employee
  );

  const { user } = useSelector<RootState, UserSlice>((state) => state.user);
  const {
    getEmployeeAbsences,
    deleteEmployeeAbsences,
    getEmployeeNotices,
    callOutLog,
    employeeState,
    changeSelectedEmployee,
  } = useEmployee();
  const { handleSubmit, handleChange, values, touched, errors, setFieldValue } =
    useFormik({
      initialValues: CALLLOGINITIALVALUES,
      validationSchema: callLogSchema,
      onSubmit: async (values, onSubmitProps) => {
        const logData: LogData = {
          employee_name: values.employeeName,
          employee_id: values.employeeID,
          comments: values.comment ?? "",
          infraction_label: getInfractionTitle(values.infractionType),
          infraction_type: Number(
            INFRACTIONOPTIONS.find(
              (infraction) => infraction.label === values.infractionType
            )?.value
          ),
          disciplinary_action: Number(
            DISCIPLINARYOPTIONS.find(
              (action) => action.label === values.disciplinaryAction
            )?.value
          ),
          choose_date: values.date,
        };
        await callOutLog(logData);
        onSubmitProps.setSubmitting(false);
        onSubmitProps.resetForm();
      },
    });
  useEffect(() => {
    const employee = employees.find((emp) => emp.name === values.employeeName);
    if (employee) {
      setFieldValue("employeeID", employee.id);
      const dates = JSON.stringify({
        week: getCurrentWeek(),
        month: `${getCurrentMonth()},${getCurrentYear()}`,
        year: getCurrentYear(),
      });

      changeSelectedEmployee({ ...employee, role: "employee" });
      getEmployeeAbsences(employee, dates);
      getEmployeeNotices(employee);
    }
    return () => {
      deleteEmployeeAbsences();
      changeSelectedEmployee(undefined);
    };
  }, [
    values.employeeName,
    employees,
    setFieldValue,
    getEmployeeAbsences,
    deleteEmployeeAbsences,
    getEmployeeNotices,
    changeSelectedEmployee,
  ]);

  return (
    <form
      className="flex flex-wrap gap-x-7 gap-y-6"
      noValidate
      onSubmit={handleSubmit}
    >
      <AutoComplete
        data={employees}
        label={"Employee Name"}
        placeholder="Robert Anderson"
        name="employeeName"
        error={touched.employeeName && errors.employeeName}
        className="w-full lg:w-[47%] lg:max-w-[397px]"
        handleClick={(item: string) => setFieldValue("employeeName", item)}
      />
      <Input
        label={"Employee ID"}
        placeholder="RA-12323"
        readOnly={true}
        name="employeeID"
        error={touched.employeeID && errors.employeeID}
        // onChange={handleChange}
        value={values.employeeID}
        className="w-full lg:w-[47%] lg:max-w-[397px]"
      />

      <Select
        label="Infraction Type"
        options={INFRACTIONOPTIONS}
        value={values.infractionType}
        className="w-full lg:w-[47%] lg:max-w-[397px]"
        onClick={(value: string) => {
          setFieldValue("infractionType", value);
        }}
      />

      <DatePicker
        label={"Choose A Date"}
        placeholder="MM/DD/YYYY"
        name="date"
        error={touched.date && errors.date}
        onClick={(date: string) => setFieldValue("date", date)}
        className="w-full lg:w-[47%] lg:max-w-[397px] "
      />
      {user?.isAdmin && (
        <Select
          label="Disciplinary Action"
          options={DISCIPLINARYOPTIONS}
          value={values.disciplinaryAction}
          className="w-full lg:w-[47%] lg:max-w-[397px] "
          onClick={(value: string) => {
            setFieldValue("disciplinaryAction", value);
          }}
        />
      )}

      <Textarea
        label={"Add Comment"}
        placeholder="Your Comments"
        type="text"
        name="comment"
        error={touched.comment && errors.comment}
        value={values.comment}
        className="w-full max-w-[822px]"
        onChange={handleChange}
      />
      <Button
        type="submit"
        isLoading={employeeState.isLoading}
        title={"Submit"}
        className="mx-auto dark:bg-success px-10"
      />
    </form>
  );
};

export default Form;
