//@ts-nocheck
import React, { Fragment } from "react";
import leftArrowIcon from "assets/images/leftArrow__icon.png";
import editIcon from "assets/images/edit__icon.png";
import reportIcon from "assets/images/report__iocn.png";
import deleteIcon from "assets/images/delete__icon.png";
import AtoZIcon from "assets/images/AtoZ__icon.png";
import Tooltip from "components/Tooltip/Tooltip";
import { useApp } from "context/AppProvider";
import {
  useTable,
  useGlobalFilter,
  usePagination,
  useSortBy,
} from "react-table";
import { COLUMNS as columns, PATHS } from "constants/index";
import { Link } from "react-router-dom";
import { useEmployee } from "hooks/useEmployee";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import Input from "components/Input/Input";
import { UserSlice } from "redux/features/user/userSlice";
interface IProps {
  data: any[];
}

const Table: React.FC<IProps> = ({ data }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    rows,
    setGlobalFilter,
    state,
  } = useTable({ columns, data }, useGlobalFilter, useSortBy, usePagination);
  const { pageIndex } = state;
  const { globalfilter } = state;
  const { openModal } = useApp();
  const { changeSelectedEmployee } = useEmployee();
  const { user } = useSelector<RootState, UserSlice>((state) => state.user);

  return (
    <Fragment>
      <Input
        value={globalfilter || undefined}
        onChange={(e: React.ChangeEventHandler<HTMLInputElement>) =>
          setGlobalFilter(e.target.value) || undefined
        }
        placeholder="Search by employee name"
        className="w-full max-w-[400px] mb-5"
      />
      <table
        {...getTableProps()}
        className="border-t-[1px] border-[#dbdbdb] w-full"
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(
                    column.Header === "Name"
                      ? column.getSortByToggleProps()
                      : { disableSortBy: true }
                  )}
                  className="py-4 font-sans font-bold text-black dark:text-white text-base relative"
                >
                  {column.render("Header")}

                  <span>
                    {column.Header === "Name" ? (
                      column.isSorted ? (
                        <img
                          src={AtoZIcon}
                          alt="dropdown-icon"
                          className="object-cover absolute right-[20px] bottom-[16px]"
                        />
                      ) : (
                        <img
                          src={AtoZIcon}
                          alt="dropdown-icon"
                          className="object-cover absolute right-[20px] bottom-[16px]"
                        />
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </th>
              ))}
              <th className="py-4 font-sans font-bold text-black dark:text-white text-base">
                Actions
              </th>
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                className="odd:bg-[#f8f8f8] even:bg-[#fcfcfc] dark:odd:bg-[#24364D] dark:even:bg-[#1B2D44] text-center"
              >
                {row.cells.map((cell) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      className="py-3 px-12 font-sans font-medium dark:text-white text-primary text-sm"
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
                <td className="py-3 px-12 font-sans font-medium dark:text-white text-primary text-sm">
                  <div className="flex gap-x-2 justify-center">
                    {row.values.col5 === "employee" && (
                      <Link
                        to={PATHS.ATTENDANCEREPORT}
                        className="tooltip relative"
                        onClick={() => {
                          changeSelectedEmployee({
                            id: row.values.col3,
                            name: row.values.col2,
                            role: row.values.col5,
                          });
                        }}
                      >
                        <img
                          src={reportIcon}
                          alt="report-icon"
                          className="max-w-[22px] object-cover dark:invert-[1]"
                        />
                        <Tooltip text="View" />
                      </Link>
                    )}
                    {user?.isAdmin && (
                      <Fragment>
                        <div
                          className="tooltip relative"
                          onClick={() => {
                            const data = JSON.stringify({
                              id: row.values.col3,
                              role: row.values.col5,
                            });
                            openModal(data, PATHS.TEAM);
                          }}
                        >
                          <img
                            src={deleteIcon}
                            alt="delete-icon"
                            className="max-w-[22px] object-cover dark:invert-[1]"
                          />
                          <Tooltip text="Delete" />
                        </div>
                        <Link
                          to={PATHS.UPDATEEMPLOYEE + `/${row.values.col3}`}
                          className="tooltip relative cursor-pointer"
                          onClick={async () => {
                            changeSelectedEmployee({
                              id: row.values.col3,
                              name: row.values.col2,
                              role: row.values.col5,
                            });
                          }}
                        >
                          <img
                            src={editIcon}
                            alt="edit-icon"
                            className="max-w-[22px] object-cover dark:invert-[1]"
                          />
                          <Tooltip text="Edit" />
                        </Link>
                      </Fragment>
                    )}
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {rows.length === 0 && (
        <h3 className="w-full text-xl font-normal font-sans  text-primary dark:text-disable mt-4 text-center">
          No search result{" "}
        </h3>
      )}

      {rows.length > 9 && (
        <div className="flex flex-row gap-4 align-center justify-center mt-10 w-full">
          <button
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
            className="w-[36px] h-[36px] bg-[#F5F5F5]  dark:bg-success "
          >
            <img
              src={leftArrowIcon}
              alt="dropdown-icon"
              className="mx-auto object-cover dark:invert-[1]"
            />
          </button>
          <span className="font-medium text-xl font-sans bg-[#2A3390] dark:bg-darkinput w-[36px] py-1 text-center text-white">
            <strong>{pageIndex + 1}</strong>
          </span>
          <button
            className="w-[36px] h-[36px] bg-[#F5F5F5] dark:bg-success"
            onClick={() => {
              nextPage();
            }}
            disabled={!canNextPage}
          >
            <img
              src={leftArrowIcon}
              alt="dropdown-icon"
              className="mx-auto object-cover rotate-180 dark:invert-[1]"
            />
          </button>
        </div>
      )}
    </Fragment>
  );
};

export default Table;
