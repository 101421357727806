import Checkbox from "components/Checkbox/Checkbox";
import React from "react";
import { SubRole } from "types";
import { capatalize } from "utils";
interface IProps {
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  error: string | boolean | undefined;
  options: SubRole;
  name: string;
  value: string[];
}

const CheckboxGroup: React.FC<IProps> = ({
  onChange,
  error,
  options,
  name,
  value,
}) => {
  // console.log("value", value);
  return (
    <div className={"flex flex-col w-full"}>
      <label className="text-sm font-bold font-sans text-main mb-[25px] dark:text-white">
        Select Sub Roles
      </label>
      {Object.keys(options).map((item: string, index: number) => (
        <Checkbox
          id={`chk-${index}`}
          key={index}
          value={options[item].toString()}
          checked={value.includes(capatalize(options[item].toString()))}
          name={name}
          label={capatalize(item)}
          className="mb-[15px]"
          onChange={onChange}
        />
      ))}

      {error && <p className="font-sans text-sm text-error mt-1">{error}</p>}
    </div>
  );
};

export default CheckboxGroup;
