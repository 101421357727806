import React, { memo } from "react";
import { useTheme } from "context/ThemeProvider";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { CHARTCOLORS } from "constants/index";
import { Bar } from "react-chartjs-2";
import { BarChart, Infraction, Theme } from "types";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

interface IProps {
  data: BarChart;
}

ChartJS.register(
  ChartDataLabels,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const createLabels = (data: BarChart) => {
  const labels = [];
  for (const [key, value] of Object.entries(data)) {
    const obj = {
      sick: value.sick,
      personal: value.personal,
      fmla: value.fmla,
      lri: value.lri,
      ncns: value.ncns,
      other: value.other,
    };
    const sum = Object.values(obj).reduce((prev, curr) => prev + curr, 0);
    labels.push([key, sum]);
  }
  return labels;
};
const BarGraph: React.FC<IProps> = ({ data }) => {
  const { theme } = useTheme();
  const backgroundColor =
    theme === Theme.LIGHT ? CHARTCOLORS.light : CHARTCOLORS.dark;

  const label = createLabels(data);
  const sickValues = Object.values(data).map((day: any) => day.sick);
  const personalValues = Object.values(data).map((day: any) => day.personal);
  const leValues = Object.values(data).map((day: any) => day.le);
  const fmlaValues = Object.values(data).map((day: any) => day.fmla);
  const lriValues = Object.values(data).map((day: any) => day.lri);
  const ncnsValues = Object.values(data).map((day: any) => day.ncns);
  const otherValues = Object.values(data).map((day: any) => day.other);

  // ! Do not change order
  const tooltipLabels = [
    "Sick",
    Infraction.FMLA,
    "Other",
    "LRI",
    Infraction.NCNS,
    "Personal",
    Infraction.LE,
  ];
  const dataArray = [
    sickValues,
    fmlaValues,
    otherValues,
    lriValues,
    ncnsValues,
    personalValues,
    leValues,
  ];

  const graphData = dataArray.map((value: number[], index) => {
    const data = value.map((num) => {
      if (num === 0) return null;
      else return num;
    });
    return {
      label: tooltipLabels[index],
      data,
      minBarLength: 30,
      backgroundColor: Object.values(backgroundColor)[index],
      datalabels: {
        display: true,
        

      },
    };
  });

  //*custom plugin for total sum at the top
  // const topLabels = {
  //   id: "topLabels",
  //   afterDatasetDraw(chart: ChartJS, args: any, pluginOptions: any) {
  //     const {
  //       ctx,
  //       scales: { x, y },
  //     } = chart;
  //     chart.data.datasets[0].data.forEach((datapoint, index) => {
  //       const datasetArray: any[] = [];
  //       chart.data.datasets.forEach((dataset) => {
  //         datasetArray.push(dataset.data[index]);
  //       });
  //       const step = datasetArray.filter((item) => item !== null).length - 1;
  //       //sum array
  //       let sum = datasetArray.reduce((prev, curr) => prev + curr, 0);
  //       ctx.font = "bolder 20px raleway";
  //       ctx.fillStyle = "rgba(0,0,0,1)";
  //       ctx.textAlign = "center";
  //       if (sum > 0) {
  //         console.log("y. :>> ", chart.getDatasetMeta(step).data[index].y);

  //         chart.ctx.fillText(
  //           sum,
  //           x.getPixelForValue(index),
  //           chart.getDatasetMeta(step).data[index].y
  //         );
  //       }
  //     });
  //   },
  // };
  const options = {
    plugins: {
      legend: {
        display: false,
      },
      // tooltip: {
      //   callbacks: {
      //     afterLabel: (context: any) => {
      //       console.log("context :>> ", context);
      //       return "Total";
      //     },
      //   },
      // },
      responsive: true,
      barThickness: "flex",
      categoryPercentage: 0.6,
      datalabels: {
        color: "black",
        labels: {
          title: {
            font: {
              size: 14,
              weight: 700,
            },
          },
        },

        formatter: (value: any) => {
          return value > 0 ? value : "";
        },
        padding: {
          top: 55,
          bottom: 55,
        },
      },
    },

    maintainAspectRatio: false,

    scales: {
      x: {
        stacked: true,
        ticks: {
          // maxRotation: 0,
          // minRotation: 0,
          autoSkip: false,
          color: "black",
          font: {
            size: 15,
            weight: "700",
          },
        },
        grid: {
          display: false,
          offset: true,
        },
      },

      y: {
        display: false,
        stacked: true,

        grid: {
          display: false,
        },
      },
    },
    aspectRatio: 5 / 3,
    layout: {
      padding: {
        top: 24,
        bottom: 0,
      },
    },
  };

  const bardata = {
    labels: label,
    datasets: graphData,
  };

  return (
    <div className="w-full min-w-[500px] min-h-[1000px] mt-10">
      <Bar
        options={options}
        data={bardata}
        redraw={true}
        // plugins={[topLabels]}
      />
    </div>
  );
};

export default memo(BarGraph);
