import React, { useState } from "react";
import { Employee } from "types";
interface IProps {
  data: Employee[];
  placeholder?: string;
  className?: string;
  onSelect: (value: string) => void;
  value: string;
  setValue: React.Dispatch<
    React.SetStateAction<{
      wordEntered: string;
      selectedEmployee: string;
    }>
  >;
}
const SearchBar: React.FC<IProps> = ({
  data,
  placeholder,
  className,
  onSelect,
  value,
  setValue,
}) => {
  const [filteredData, setFilteredData] = useState<Employee[]>([]);

  const handleFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchWord = event.target.value;
    setValue((prev) => {
      return {
        ...prev,
        wordEntered: searchWord,
      };
    });
    if (searchWord.length > 0) {
      const suggestions = data.filter((value) =>
        value.name.toLowerCase().includes(searchWord.toLowerCase())
      );
      setFilteredData(suggestions);
    } else {
      setFilteredData([]);
    }
  };

  const handleSelect = (value: string) => {
    setValue((prev) => {
      return {
        ...prev,
        wordEntered: value,
      };
    });
    onSelect(value);
    setFilteredData([]);
  };

  return (
    <div
      className={[
        "relative rounded-md border border-main  shadow-2xl dark:bg-darkinput dark:border-darkinput transition-all ease-in duration-150",
        className,
      ].join(" ")}
    >
      <div className="flex justify-between items-center gap-x-2 h-full px-2 md:py-0 py-[10px]">
        <input
          className={
            "font-sans font-base font-bold text-black dark:text-white outline-none border-none  dark:bg-darkinput dark:border-darkinput dark:placeholder-[#D8D8D8] py-3 w-full"
          }
          type="text"
          placeholder={placeholder}
          value={value}
          onChange={handleFilter}
        />
      </div>

      {filteredData.length > 0 && (
        <ul className="list-none rounded-b-md absolute bg-white border border-main w-full dark:border-b-white dark:bg-darkinput max-h-[120px] overflow-y-auto p-0 z-20">
          {filteredData.map((value, key) => {
            return (
              <li
                key={key}
                className="font-sans text-base font-medium text-black  dark:text-white px-4 py-2 border-b-main border-b-[1px] dark:border-b-white"
                role="menuitem"
                onClick={() => {
                  handleSelect(value.name);
                }}
              >
                {value.name}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};
export default SearchBar;
