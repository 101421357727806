import React from "react";
import Card from "components/Card/Card";
import printIcon from "assets/images/article.svg";
import { PRINTDATA, PATHS } from "constants/index";
import { Link } from "react-router-dom";

const PrintForm = () => {
  return (
    <Card icon={printIcon} label="Print Form" className="mb-3">
      <div className="flex flex-col items-start mt-4 gap-y-3">
        <div className="flex gap-x-3 items-start">
          <span className="min-w-[14px] min-h-[14px] bg-success rounded-lg mt-[5px]" />
          <p dangerouslySetInnerHTML={{ __html: PRINTDATA.title }} />
        </div>
        {PRINTDATA.action && (
          <Link
            to={PATHS.FORM}
            className="ml-6 bg-success text-[12px] font-semibold text-white py-2 px-5 rounded-md font-sans"
          >
            {PRINTDATA.action}
          </Link>
        )}
      </div>
    </Card>
  );
};

export default PrintForm;
