import Input from "components/Input/Input";
import Button from "components/Button/Button";
import login__icon from "assets/images/login_btn__icon.png";
import { useFormik } from "formik";
import { forgetPasswordSchema } from "utils/validationSchema";
import { FROGETPASSWORDVALUES, PATHS } from "constants/index";
import { Link } from "react-router-dom";
import { useUser } from "hooks/useUser";
const ForgetPasswordForm = () => {
  const { userState, forgotPassword } = useUser();

  const { handleSubmit, handleChange, values, touched, errors } = useFormik({
    initialValues: FROGETPASSWORDVALUES,
    validationSchema: forgetPasswordSchema,

    onSubmit: async (values, onSubmit) => {
      await forgotPassword(values.email);
      if (!userState.isLoading) {
        onSubmit.setSubmitting(false);
        onSubmit.resetForm();
      }
    },
  });
  return (
    <form
      className="flex flex-col max-w-[504px]"
      noValidate
      onSubmit={handleSubmit}
    >
      <Input
        label={"Your Email"}
        placeholder="Your Email"
        name="email"
        type="email"
        error={touched.email && errors.email}
        className="mb-[19px]"
        onChange={handleChange}
        value={values.email}
      />
      <Button
        type="submit"
        isLoading={userState.isLoading}
        title={"Submit"}
        leftIcon={login__icon}
        className="mx-auto min-w-[297px] mt-[67px] xl:mt-[20px] dark:bg-success"
      />
      <Link
        to={PATHS.LOGIN}
        className="mt-[15px] text-sm font-bold font-sans text-main dark:text-success justify-center self-center"
      >
        Back to Login
      </Link>
    </form>
  );
};

export default ForgetPasswordForm;
