import React, { Fragment, useMemo, useState } from "react";
import { BarChart, TableNames } from "types";
import { TABLEROWNAMES } from "constants/index";
import { capatalize, createState, downloadExcel } from "utils";
import ExcelJS from "exceljs";
interface IProps {
  columns: BarChart;
}
type StateType = {
  [key: string]: {
    [key: string]: number;
  };
};

const Table: React.FC<IProps> = ({ columns }) => {
  const editableInitalState = useMemo(() => {
    return createState(columns);
  }, [columns]);

  const [data, setData] = useState<StateType>(editableInitalState);

  const calculateTotalAbsenteeism = (data: number[]) => {
    return data.reduce((sum, point) => {
      if (typeof point !== "string") {
        return point + sum;
      }
      return sum + 0;
    }, 0);
  };
  const calculateDailyAbsenteeism = (
    totalAbsentees: number,
    noOfEmp: number
  ) => {
    if (noOfEmp > 0) {
      return Number(((totalAbsentees / noOfEmp) * 100).toFixed(2));
    } else {
      return 0;
    }
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    col: string,
    row: string
  ) => {
    switch (row) {
      case TableNames.VACATION:
        setData((prev) => {
          return {
            ...prev,
            [col]: {
              ...prev[col],
              [row]: Number(e.target.value),
            },
          };
        });
        break;
      case TableNames.OOS:
        setData((prev) => {
          return {
            ...prev,
            [col]: {
              ...prev[col],
              [row]: Number(e.target.value),
            },
          };
        });
        break;
      case TableNames.WCI:
        setData((prev) => {
          return {
            ...prev,
            [col]: {
              ...prev[col],
              [row]: Number(e.target.value),
              [TableNames.TOTAL]: calculateTotalAbsenteeism([
                Number(e.target.value),
                prev[col][TableNames.SICK],
                prev[col][TableNames.FMLA],
                prev[col][TableNames.OTHERS],
                prev[col][TableNames["NCNS/PERSONAL"]],
              ]),
              [TableNames.DAILY]: calculateDailyAbsenteeism(
                Number(e.target.value) +
                  prev[col][TableNames.SICK] +
                  prev[col][TableNames.FMLA] +
                  prev[col][TableNames.OTHERS] +
                  prev[col][TableNames["NCNS/PERSONAL"]],
                prev[col][TableNames.NOOFEMPLOYEES]
              ),
            },
          };
        });
        break;
      case TableNames.OTHERS:
        setData((prev) => {
          return {
            ...prev,
            [col]: {
              ...prev[col],
              [row]: Number(e.target.value),
              [TableNames.TOTAL]: calculateTotalAbsenteeism([
                Number(e.target.value),
                prev[col][TableNames.SICK],
                prev[col][TableNames.FMLA],
                prev[col][TableNames.WCI],
                prev[col][TableNames["NCNS/PERSONAL"]],
              ]),
              [TableNames.DAILY]: calculateDailyAbsenteeism(
                Number(e.target.value) +
                  prev[col][TableNames.SICK] +
                  prev[col][TableNames.FMLA] +
                  prev[col][TableNames.WCI] +
                  prev[col][TableNames["NCNS/PERSONAL"]],
                prev[col][TableNames.NOOFEMPLOYEES]
              ),
            },
          };
        });
        break;
      case TableNames.NOOFEMPLOYEES:
        setData((prev) => {
          return {
            ...prev,
            [col]: {
              ...prev[col],
              [row]: Number(e.target.value),
              [TableNames.DAILY]: calculateDailyAbsenteeism(
                prev[col][TableNames.TOTAL],
                Number(e.target.value)
              ),
            },
          };
        });
        break;
    }
  };
  const exportToExcel = () => {
    const jsonData = TABLEROWNAMES.map((item) => {
      let res: { [key: string]: number } = {};
      Object.keys(data).reduce((res, i) => {
        res[i] = data[i][item.name];
        return res;
      }, res);
      return {
        "Daily Absenteeism Bus Operators": item.name,
        ...res,
      };
    });

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet1");
    const columns = Object.keys(jsonData[0]).map((col) => {
      return {
        header: capatalize(col),
        key: col,
        width: col === "Daily Absenteeism Bus Operators" ? 45 : 26,
      };
    });
    worksheet.properties.defaultRowHeight = 25;
    worksheet.columns = columns;
    worksheet.addRows(jsonData);

    //Styling
    worksheet.eachRow((row) => {
      row.font = {
        name: "Raleway",
        size: 13,
      };
    });
    worksheet.getRow(10).eachCell((cell) => {
      if (cell.value !== TableNames.TOTAL) {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "00B4A9" },
        };
      }
    });
    worksheet.getRow(11).numFmt = "0.00%";
    worksheet.getRow(12).numFmt = "0%";

    worksheet.getRow(12).eachCell((cell) => {
      const cellValue = cell.value;
      if (cellValue && cellValue !== TableNames.TARGET) {
        cell.value = Number(cell.value) / 100;
      }
    });

    worksheet.getRow(11).eachCell((cell) => {
      const cellValue = cell.value;
      if (
        cellValue !== TableNames.DAILY &&
        (cellValue === 0 || Number(cellValue) < 9)
      ) {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "00B4A9" },
        };
        cell.value = Number(cellValue) / 100;
      }

      if (cellValue && Number(cellValue) > 9) {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "ff3333" },
        };
        cell.value = Number(cellValue) / 100;
      }
    });
    worksheet.getRow(1).font = {
      name: "Raleway",
      size: 15,
      bold: false,
    };

    worksheet.getCell("A1").font = {
      name: "Raleway",
      size: 15,
      bold: true,
    };
    worksheet.getCell("A1").border = {
      bottom: {
        style: "thick",
        color: { argb: "black" },
      },
    };

    //download excel file
    downloadExcel("reports.xlsx", workbook);
  };
  return (
    <Fragment>
      <button
        onClick={exportToExcel}
        className="px-3 py-1 dark:bg-success bg-primary text-white rounded-md mb-4"
      >
        Export to Excel
      </button>
      <table>
        <thead>
          <tr>
            <th className="font-bold border-b-2 border-black dark:border-white whitespace-nowrap  dark:text-white">
              Daily Absenteeism Bus Operators
            </th>
            {Object.keys(columns).map((col) => (
              <th key={col} className="font-medium dark:text-white">
                {col.toUpperCase()}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {TABLEROWNAMES.map((row, index) => (
            <tr key={index}>
              <td className="border border-dark dark:border-white whitespace-nowrap dark:text-white px-2">
                {row.name}
              </td>
              {Object.keys(columns).map((col, index) =>
                row.editable ? (
                  <td
                    className="border border-dark dark:border-white bg-success "
                    key={index}
                  >
                    <input
                      type="number"
                      autoComplete="off"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleChange(e, col, row.name)
                      }
                      name={`${row.name}_${col}`}
                      value={data[col][row.name]}
                      className="py-1 px-3 border-none text-black font-sans font-normal dark:border-darkinput  text-base focus-visible:outline-0  dark:text-white bg-success "
                    />
                  </td>
                ) : (
                  <td
                    className={`border border-dark dark:text-white dark:border-white px-3 py-1 ${
                      row.name === TableNames.TOTAL
                        ? "bg-success"
                        : row.name === TableNames.DAILY &&
                          data[col][row.name] > 9
                        ? "bg-error"
                        : row.name === TableNames.DAILY &&
                          data[col][row.name] < 9
                        ? "bg-success"
                        : "bg-transparent"
                    }
  
                  `}
                    key={index}
                  >
                    {row.name === TableNames.DAILY ||
                    row.name === TableNames.TARGET
                      ? data[col][row.name] + "%"
                      : data[col][row.name]}
                  </td>
                )
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </Fragment>
  );
};

export default Table;
