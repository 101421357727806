import React from "react";
import Checkbox from "components/Checkbox/Checkbox";
import { CheckboxOptions } from "types";

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  error: string | boolean | undefined;
  className?: string;
  values: CheckboxOptions;
}

const CheckGroup: React.FC<IProps> = ({
  onChange,
  error,
  label,
  className,
  name,
  values,
}) => {
  return (
    <div className={[className].join(" ")}>
      <label className="text-sm font-bold font-sans text-main dark:text-white mb-[9px] mt-[10px]">
        {label}
      </label>
      {Object.entries(values).map(([key, value]) => (
        <Checkbox
          id={`${name}.${key}`}
          key={key}
          checked={value}
          name={`${name}.${key}`}
          label={key}
          onChange={onChange}
        />
      ))}

      {error && <p className="font-sans text-sm text-error mt-1">{error}</p>}
    </div>
  );
};

export default CheckGroup;
