import Input from "components/Input/Input";
import Button from "components/Button/Button";
import login__icon from "assets/images/login_btn__icon.png";
import { useFormik } from "formik";
import { resetPasswordSchema } from "utils/validationSchema";
import { RESETPASSWORDVALUES } from "constants/index";
import { useParams } from "react-router-dom";
import { useUser } from "hooks/useUser";

const ResetPasswordForm = () => {
  const { uid, token } = useParams();

  const { userState, resetPassword } = useUser();
  const { handleSubmit, handleChange, values, touched, errors } = useFormik({
    initialValues: RESETPASSWORDVALUES,
    validationSchema: resetPasswordSchema,

    onSubmit: async (values, onSubmit) => {
      const password = {
        uid: uid!,
        token: token!,
        new_password1: values.password1,
        new_password2: values.password2,
      };
      await resetPassword(password);
      if (!userState.isLoading) {
        onSubmit.setSubmitting(false);
      }
    },
  });

  return (
    <form
      className="flex flex-col max-w-[504px]"
      noValidate
      onSubmit={handleSubmit}
    >
      <Input
        label={"New Password"}
        placeholder="*********"
        type="password"
        name="password1"
        error={touched.password1 && errors.password1}
        className="lg:mb-3 lg:mt-0 md:mb-[1px]"
        onChange={handleChange}
        value={values.password1}
      />
      <Input
        label={"Confirm Password"}
        placeholder="*********"
        type="password"
        name="password2"
        error={touched.password2 && errors.password2}
        className="lg:mb-3 lg:mt-0 md:mb-[1px]"
        onChange={handleChange}
        value={values.password2}
      />
      <Button
        type="submit"
        isLoading={userState.isLoading}
        title={"Submit"}
        leftIcon={login__icon}
        className="mx-auto min-w-[297px] mt-[67px] xl:mt-[20px] dark:bg-success"
      />
    </form>
  );
};

export default ResetPasswordForm;
