import { Options } from "types";
import { api } from "config/api";
import { AxiosError } from "axios";
import { toast } from "react-toastify";

export const fetchDashboardData = async (
  type: Options,
  date: string,
  deptName: string
) => {
  // console.log("deptName", deptName);
  if (type === Options.MONTHLY) {
    const response = await api.get(
      `dashboard-charts?month=${date}&dept=${deptName}`
    );
    return { data: response.data };
  }
  if (type === Options.YEARLY) {
    const response = await api.get(
      `dashboard-charts?year=${date}&dept=${deptName}`
    );
    return { data: response.data };
  } else {
    const response = await api.get(
      `dashboard-charts?dates=${date}&dept=${deptName}`
    );
    return { data: response.data };
  }
};

export const fetchTaskReminders = async () => {
  const response = await api.get("task-reminders-dashboard/");

  return { data: response.data };
};

export const removeReminder = async (id: number) => {
  try {
    const response = await api.get(`undo-reminder/${id}`);
    return { data: response.data };
  } catch (error) {
    if (error instanceof AxiosError && error.response?.status !== 500) {
      const message: string = error.response?.data.error;
      toast.error(message);
    } else {
      toast.error("Something went wrong. Please try again");
    }
  }
};
