import userReducer from "redux/features/user/userSlice";
import teamReducer from "redux/features/team/teamSlice";
import dashboardReducer from "redux/features/dashboard/dashboardSlice";
import employeeReducer from "./features/employee/employeeSlice";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { persistReducer, persistStore } from "redux-persist";

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["dashboard", "team"],
};
const rootReducer = combineReducers({
  user: userReducer,
  team: teamReducer,
  dashboard: dashboardReducer,
  employee: employeeReducer,
});
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});

export default store;
export const persistor = persistStore(store);

export const useAppDispatch: () => AppDispatch = useDispatch;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
