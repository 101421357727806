import React from "react";
import "./styles.css";

interface IProps {
  className?: string;
}
const Loader: React.FC<IProps> = ({ className }) => {
  return (
    <div className="h-[28px] relative bg-transparent flex gap-x-2 items-center justify-center m-auto">
      <span className={["circle", className].join(" ")} />
      <span className={["circle", className].join(" ")} />
      <span className={["circle", className].join(" ")} />
    </div>
  );
};

export default Loader;
