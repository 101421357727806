import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import { UserSlice } from "redux/features/user/userSlice";
import { AuthRoutes } from "./components/AuthRoutes";
import { AppRoutes } from "./components/AppRoutes";
import LeftSidebar from "components/Sidebar/LeftSidebar";

function MyRoutes() {
  const { isAuthenticated } = useSelector<RootState, UserSlice>(
    (state) => state.user
  );

  return (
    <div className="root">
      {isAuthenticated && <LeftSidebar />}
      {isAuthenticated ? <AppRoutes /> : <AuthRoutes />}
    </div>
  );
}

export default MyRoutes;
