/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from "react";
import AutoComplete from "components/AutoComplete/AutoComplete";
import Button from "components/Button/Button";
import DatePicker from "components/DatePicker/DatePicker";
import DatePickerRange from "components/DatePicker/DatePickerRange";
import Textarea from "components/Textarea/Textarea";
import Checkbox from "components/Checkbox/Checkbox";
import CheckboxGroup from "pages/DisciplinaryForm/components/CheckboxGroup";
import Select from "components/Select/Select2";
import Signature from "./SignatureComponent";
import SignatureCanvas from "react-signature-canvas";
import { EmployeeNoticePDF } from "constants/EmployeeNoticePDF";
import { useFormik } from "formik";
import { noticeSchema } from "utils/validationSchema";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import { EmployeeSlice } from "redux/features/employee/employeeSlice";
import { printPDF } from "utils";
import { getUser } from "utils/user";
import {
  NOTICEINITIALVALES,
  DEPARTMENTOPTIONS,
  EMPTYSIGN,
} from "constants/index";
import { Options } from "types";

import { useEmployee } from "hooks/useEmployee";
import UploadSignature from "./UploadSignature";

const Form = () => {
  const { getEmployeeTotalPoints } = useEmployee();
  const { handleSubmit, handleChange, values, touched, errors, setFieldValue } =
    useFormik({
      initialValues: NOTICEINITIALVALES,
      validationSchema: noticeSchema,
      onSubmit: (values) => {
        const data = {
          ...values,
          issuedBy: getUser()?.name,
          position: getUser()?.designation,
        };
        printPDF(
          <EmployeeNoticePDF data={data} />,

          "Attendance_Disciplinary-Action-Form_Rai.pdf",
          1100
        );
      },
    });
  const { employees } = useSelector<RootState, EmployeeSlice>(
    (state) => state.employee
  );
  const [dateOfOccurence, setDateOfOccurence] = useState("");
  const [supervisorSign, setSupervisorSign] = useState<SignatureCanvas | null>(
    null
  );
  const [unionRepSign, setUnionRepSign] = useState<SignatureCanvas | null>(
    null
  );
  const clearSign = useCallback((sign: SignatureCanvas, by: string) => {
    if (EMPTYSIGN !== sign.getTrimmedCanvas().toDataURL()) {
      sign.clear();
      setFieldValue(by, "");
    }
  }, []);
  const saveSign = useCallback((sign: SignatureCanvas | string, by: string) => {
    //url is coming
    if (typeof sign === "string") {
      setFieldValue(by, sign);
    } else if (EMPTYSIGN !== sign.getTrimmedCanvas().toDataURL()) {
      setFieldValue(by, sign.getTrimmedCanvas().toDataURL("image/png"));
    }
  }, []);

  useEffect(() => {
    const employee = employees.find((emp) => emp.name === values.employeeName);
    if (employee && dateOfOccurence.length > 0) {
      const { startDate, endDate } = JSON.parse(dateOfOccurence);
      getEmployeeTotalPoints({
        date: `${startDate},${endDate}`,
        employeeID: employee.id,
        type: Options.CUSTOM,
      }).then((totalPoints) => {
        setFieldValue("infractionPoints", totalPoints);
        setFieldValue("employeeID", employee.id);
      });
    }
  }, [values.employeeName, dateOfOccurence]);

  return (
    <form className="flex flex-wrap gap-7" noValidate onSubmit={handleSubmit}>
      <AutoComplete
        data={employees}
        label={"Employee Name"}
        placeholder="Employee Name"
        name="employeeName"
        error={touched.employeeName && errors.employeeName}
        value={values.employeeName}
        className="w-full lg:w-[47%] lg:max-w-[397px] mb-0"
        handleClick={(item: string) => setFieldValue("employeeName", item)}
      />

      <DatePicker
        label={"Date of Disciplinary Action Given"}
        placeholder="MM/DD/YYYY"
        name="date"
        error={touched.date && errors.date}
        onClick={(date: string) => setFieldValue("date", date)}
        className="w-full lg:w-[47%] lg:max-w-[397px]"
      />
      <DatePickerRange
        label={"Select Date Range (Rolling calendar year)"}
        placeholder={"Date of Occurrence"}
        name="dateOfOccurence"
        error={touched.dateOfOccurence && errors.dateOfOccurence}
        onClick={(date: string) => {
          const values = JSON.parse(date);
          setDateOfOccurence(date);
          if (values.startDate === values.endDate)
            setFieldValue("dateOfOccurence", values.startDate);
          else setFieldValue("dateOfOccurence", "Various");
        }}
        className="w-full lg:w-[47%] lg:max-w-[397px]"
      />
      <Select
        label="Department Name"
        options={DEPARTMENTOPTIONS}
        value={values.employeeDepartment}
        className="w-full lg:w-[47%] lg:max-w-[397px]"
        onClick={(item: string) => setFieldValue("employeeDepartment", item)}
      />
      <div className="flex justify-between w-full flex-wrap gap-x-4 gap-y-4">
        <CheckboxGroup
          label="Disciplinary Action"
          error={touched.typeOfAction && errors.typeOfAction?.toString()}
          name="typeOfAction"
          onChange={handleChange}
          values={values.typeOfAction}
        />
      </div>
      <div className="flex flex-col xl:w-[65%] w-full">
        <label className=" text-xl font-bold font-sans text-main dark:text-white mb-[5px]">
          Previous Warnings
        </label>
        <div className="flex flex-col justify-between lg:flex-row">
          <Checkbox
            label="Oral Counseling"
            onChange={handleChange}
            name={"previousWarnings['Oral Warning'].checked"}
            checked={values.previousWarnings["Oral Warning"].checked}
          />
          <DatePicker
            label={"Date"}
            placeholder="MM/DD/YYYY"
            name="date"
            error={
              touched.previousWarnings?.["Oral Warning"]?.date &&
              errors.previousWarnings?.["Oral Warning"]?.date
            }
            onClick={(date: string) =>
              setFieldValue("previousWarnings.['Oral Warning'].date", date)
            }
            className="w-full lg:w-[47%] lg:max-w-[397px]"
          />
        </div>
        <div className="flex flex-col justify-between lg:flex-row">
          <Checkbox
            label="Written Warning"
            onChange={handleChange}
            name={"previousWarnings['Written Warning'].checked"}
            checked={values.previousWarnings["Written Warning"].checked}
          />
          <DatePicker
            label={"Date"}
            placeholder="MM/DD/YYYY"
            name="date"
            error={
              touched.previousWarnings?.["Written Warning"]?.date &&
              errors.previousWarnings?.["Written Warning"]?.date
            }
            onClick={(date: string) => {
              setFieldValue("previousWarnings.['Written Warning'].date", date);
            }}
            className="w-full lg:w-[47%] lg:max-w-[397px]"
          />
        </div>
        <div className="flex flex-col justify-between lg:flex-row">
          <Checkbox
            label="Suspension without pay 1 day"
            onChange={handleChange}
            name={"previousWarnings['Suspension 1 Day'].checked"}
            checked={values.previousWarnings["Suspension 1 Day"].checked}
          />
          <DatePicker
            label={"Date"}
            placeholder="MM/DD/YYYY"
            name="date"
            error={
              touched.previousWarnings?.["Suspension 1 Day"]?.date &&
              errors.previousWarnings?.["Suspension 1 Day"]?.date
            }
            onClick={(date: string) =>
              setFieldValue("previousWarnings.['Suspension 1 Day'].date", date)
            }
            className="w-full lg:w-[47%] lg:max-w-[397px]"
          />
        </div>
        <div className="flex flex-col justify-between lg:flex-row">
          <Checkbox
            label="Suspension without pay 3 days"
            onChange={handleChange}
            name={"previousWarnings['Suspension 3 Days'].checked"}
            checked={values.previousWarnings["Suspension 3 Days"].checked}
          />
          <DatePicker
            label={"Date"}
            placeholder="MM/DD/YYYY"
            name="date"
            error={
              touched.previousWarnings?.["Suspension 3 Days"]?.date &&
              errors.previousWarnings?.["Suspension 3 Days"]?.date
            }
            onClick={(date: string) =>
              setFieldValue("previousWarnings.['Suspension 3 Days'].date", date)
            }
            className="w-full lg:w-[47%] lg:max-w-[397px]"
          />
        </div>
        <div className="flex flex-col justify-between lg:flex-row">
          <Checkbox
            label="Suspension without pay 5 days"
            onChange={handleChange}
            name={"previousWarnings['Suspension 5 Days'].checked"}
            checked={values.previousWarnings["Suspension 5 Days"].checked}
          />
          <DatePicker
            label={"Date"}
            placeholder="MM/DD/YYYY"
            name="date"
            error={
              touched.previousWarnings?.["Suspension 5 Days"]?.date &&
              errors.previousWarnings?.["Suspension 5 Days"]?.date
            }
            onClick={(date: string) =>
              setFieldValue("previousWarnings.['Suspension 5 Days'].date", date)
            }
            className="w-full lg:w-[47%] lg:max-w-[397px]"
          />
        </div>
      </div>
      <Textarea
        label={"Comments/ Record of conversation"}
        placeholder="Your Comments"
        type="text"
        name="recordOfConvo"
        error={touched.recordOfConvo && errors.recordOfConvo}
        value={values.recordOfConvo}
        className="w-full"
        onChange={handleChange}
      />

      <div className="w-full flex flex-wrap justify-between xl:w-[65%]">
        <Signature
          sign={supervisorSign}
          setSign={setSupervisorSign}
          saveSign={saveSign}
          clearSign={clearSign}
          name={"supervisorSign"}
          label="Supervisor Signature"
        />
        <UploadSignature
          label="Upload Signature"
          name="supervisorSign"
          saveSign={saveSign}
          sign={values.supervisorSign}
        />

        <DatePicker
          label={"Date"}
          placeholder="MM/DD/YYYY"
          name="supervisorSignDate"
          error={touched.supervisorSignDate && errors.supervisorSignDate}
          onClick={(date: string) => setFieldValue("supervisorSignDate", date)}
          className="w-full lg:w-[47%] lg:max-w-[397px] mt-7"
        />
      </div>
      <div className="w-full flex flex-wrap justify-between xl:w-[65%]">
        <Signature
          sign={unionRepSign}
          setSign={setUnionRepSign}
          saveSign={saveSign}
          clearSign={clearSign}
          name={"unionRepSign"}
          label="Union Representative Signature"
        />
        <UploadSignature
          label="Upload Signature"
          name="unionRepSign"
          saveSign={saveSign}
          sign={values.unionRepSign}
        />
        <DatePicker
          label={"Date"}
          placeholder="MM/DD/YYYY"
          name="unionRepSignDate"
          error={touched.unionRepSignDate && errors.unionRepSignDate}
          onClick={(date: string) => setFieldValue("unionRepSignDate", date)}
          className="w-full lg:w-[47%] lg:max-w-[397px] mt-7"
        />
      </div>
      <Checkbox
        onChange={handleChange}
        label="I disagree with the employeer's statement for these reasons."
        name="employeerDisagree"
        checked={values.employeerDisagree}
        className="w-full"
      />

      <Button
        type="submit"
        title={"Submit"}
        className="flex mx-auto px-20 items-center mt-10 justify-center dark:bg-success"
      />
    </form>
  );
};

export default Form;
