import React, { Fragment } from "react";
import { Data } from "types";
import Button from "components/Button/Button2";

interface IProps {
  data: Data[];
}

const List: React.FC<IProps> = ({ data }) => {
  return (
    <div className="flex flex-col items-start mt-4 gap-y-3">
      {data.map((item, index) => {
        return (
          <Fragment key={index}>
            <div className="flex gap-x-3 items-start">
              <span className="min-w-[14px] min-h-[14px] bg-success rounded-lg mt-[5px]" />
              <p dangerouslySetInnerHTML={{ __html: item.title }} />
            </div>
            {item.action && (
              <Button
                variant={"success"}
                title={item.action}
                className="ml-6 bg-success text-white"
              />
            )}
          </Fragment>
        );
      })}
    </div>
  );
};

export default List;
