import { useCallback, useState } from "react";
import { fetchTeamMembers } from "redux/api/team";
import { fetchedSuccess } from "redux/features/team/teamSlice";
import { useAppDispatch } from "redux/store";
import { ApiResponse } from "types";

export const useTeams = () => {
  const [teamState, setTeamState] = useState<ApiResponse>({
    isLoading: false,
    isError: false,
    error: "",
  });
  const dispatch = useAppDispatch();

  const getTeamMembers = useCallback(async () => {
    //set loading to true
    setTeamState((prev) => {
      return { ...prev, isLoading: true };
    });
    try {
      const { data } = await fetchTeamMembers();
      if (data) {
        //save in redux store
        dispatch(fetchedSuccess(data));
      }
      //set loading to false
      setTeamState((prev) => {
        return { ...prev, isLoading: false, isError: false };
      });
    } catch (error) {
      // console.log("error", error);
      //set error to true && loading to false
      setTeamState((prev) => {
        return { ...prev, isError: true, isLoading: false };
      });
    }
  }, [dispatch]);

  return { teamState, getTeamMembers };
};
