import React from "react";
import Card from "components/Card/Card";
import Button from "components/Button/Button2";
import taskIcon from "assets/images/task.svg";
import { Task } from "types/index";
import Loader from "components/Loader/Loader";
import { useApp } from "context/AppProvider";
import { PATHS } from "constants/index";

interface IProps {
  tasks: Task[];
  isLoading: boolean;
}

const TaskReminders: React.FC<IProps> = ({ tasks, isLoading }) => {
  const { openModal } = useApp();
  return (
    <Card
      icon={taskIcon}
      label="Task Reminders"
      className={`dark:bg-gradient dark:border-[#00B4A9]${
        tasks.length === 0 && "min-h-[450px]"
      } `}
    >
      {tasks.length > 0 &&
        tasks.map((item) => {
          return (
            <div
              className="flex items-center mt-8 gap-x-5 justify-between"
              key={item.id}
            >
              <p className="font-sans font-medium text-base">
                <strong>{item.name}</strong> - {item.task}
              </p>
              <Button
                variant={item.status === "pending" ? "warning" : "success"}
                title="Done"
                onClick={() => openModal(item.id, PATHS.HOME)}
              />
            </div>
          );
        })}
      {tasks.length === 0 && !isLoading && (
        <p className="font-sans font-medium text-base text-center mt-4">
          No Task Reminders
        </p>
      )}
      {tasks.length === 0 && isLoading && (
        <Loader className="bg-dark dark:bg-white mt-6" />
      )}
    </Card>
  );
};

export default TaskReminders;
