import Container from "components/Container/Container";
import WrapperLayout from "components/layouts/AppLayout";
import Form from "pages/CallOutLog/components/Form";
import ActionsSummary from "components/ActionsSummary";
import Heading from "components/Heading/Heading";
export const CallOutLog = () => {
  return (
    <WrapperLayout>
      <div className="flex flex-col gap-y-5 xl:flex-row items-start justify-between mt-6 gap-x-6">
        <div className="flex flex-col items-start justify-between gap-y-5 max-w-[960px] w-full">
          <Container className="px-3 sm:px-8 flex items-center w-full h-[119px]">
            <Heading title="Call Out Log" />
          </Container>
          <Container className="card py-10 px-8 flex flex-col items-start w-full overflow-y-auto">
            <Form />
          </Container>
        </div>
        <Container
          className="py-5 px-7 w-full xl:max-w-[390px] overflow-y-auto dark:bg-gradient"
          style={{ maxHeight: "calc(100vh - 135px)" }}
        >
          <ActionsSummary />
        </Container>
      </div>
    </WrapperLayout>
  );
};
