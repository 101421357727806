import React from "react";
import { InfractionNoticeSchema } from "types";
import logo from "assets/images/logo.png";
import pointsTable from "assets/images/points_table.png";
import { INFRACTIONOPTIONS } from "constants/index";
import { formatDateUS } from "utils";
interface IProps {
  data: InfractionNoticeSchema;
}

const absencePoints = (absence: string) => {
  const points = INFRACTIONOPTIONS.find(
    (item) => item.label === absence
  )?.value;
  return points;
};
const totalPoints = (absences: string[]) => {
  let sum = 0;
  const points = INFRACTIONOPTIONS.filter((item) =>
    absences.includes(item.label)
  );
  return points.reduce((sum, { value }) => sum + Number(value), sum);
};
const DiscliplinaryNoticePdf: React.FC<IProps> = ({ data }) => {
  const prevInfractions = data.previousInfractions
  const previousInfractionsPoints =
    prevInfractions?.reduce((prev, { points }) => points! + prev, 0) ?? 0;
  const total =
    previousInfractionsPoints;
  return (
    <div className="w-[870px] h-[1100px] m-auto ml-4 font-times">
      <img
        src={logo}
        alt="logo"
        className="mt-10 mx-auto w-[230px] object-cover"
      />
      <h1 className="text-4xl my-4 text-center font-times font-bold">
        Attendance Policy Infraction Notice
      </h1>
      <div className="ml-20 mt-8 flex justify-between text-black w-[550px]">
        <div className="text-base font-bold flex flex-row justify-between ml-4 relative">
          <p>Operator name:</p>
          <p className="ml-2">{data.employeeName}</p>
          <span className="absolute border-black border-b top-[30px] left-[115px] w-[200px]" />
        </div>
        <div className="text-base font-bold flex flex-row justify-between relative">
          <p className="ml-[-100px]">Date:</p>
          <p className="border-black bottom-b-2 ml-2">
            {data.date ? formatDateUS(data.date) : ""}
          </p>
          <span className="absolute border-black border-b top-[30px] left-[-55px] w-[140px]" />
        </div>
      </div>
      <p className="text-base justify-center items-center text-black ml-24 mt-4 w-[650px]">
        This document serves only as notification of your current standing in
        the attendance point system. Disciplinary action will only result at the
        point accumulation outlined below.
      </p>
      <img
        src={pointsTable}
        alt="pointTable"
        className={`${
          data.previousInfractions &&
          data.previousInfractions?.length > 15 &&
          data.comment &&
          data.comment?.length > 0
            ? "mt-20"
            : "mt-5"
        } mx-auto flex items-center justify-center w-[400px] object-cover`}
      />
      <p className="text-base justify-center items-center text-black ml-24 w-[650px]">
        The Employee Assistance Program &#40;EAP&#41; is available for all
        employees, RATP DEV encourages employees to contact EAP at 877-533-2363
        if assistance is required.
      </p>
      <div className="mb-2 mt-4">
        <table className="border-black border-collapse border justify-center items-center  ml-24 w-[650px]">
          <colgroup>
            <col width={"60px"} />
            <col width={"180px"} />
            <col width={"60px"} />
          </colgroup>
          <thead>
            <tr>
              <th className="border-2 border-black">Date</th>
              <th className="border-2 border-black">Type of Absence</th>
              <th className="border-2 border-black p-1">
                {" "}
                Point
                <br />
                Accessed
              </th>
            </tr>
          </thead>

          <tbody>
            {data.previousInfractions &&
              data.previousInfractions.map((infraction, index) => (
                <tr key={index}>
                  <td className="border-2 border-black h-7 p-1">
                    {formatDateUS(infraction.date)}
                  </td>
                  <td className="border-2 border-black h-7 p-1">
                    {infraction.title}
                  </td>
                  <td className="border-2 border-black h-7 p-1">
                    {infraction.points}
                  </td>
                </tr>
              ))}
            {data.dateofAbsence0 && (
              <tr>
                <td className="border-2 border-black h-7 p-1">
                  {data.dateofAbsence0 === "Various"
                    ? data.dateofAbsence0
                    : data.dateofAbsence0 === ""
                    ? ""
                    : formatDateUS(data.dateofAbsence0)}
                </td>
                <td className="border-2 border-black h-7 p-1">
                  {data.typeofAbsence0}
                </td>
                <td className="border-2 border-black h-7 p-1">
                  {data.typeofAbsence0 && absencePoints(data.typeofAbsence0)}
                </td>
              </tr>
            )}
            {data.dateofAbsence1 && (
              <tr>
                <td className="border-2 border-black h-7 p-1">
                  {" "}
                  {data.dateofAbsence1 === "Various"
                    ? data.dateofAbsence1
                    : data.dateofAbsence1 === ""
                    ? ""
                    : formatDateUS(data.dateofAbsence1)}
                </td>
                <td className="border-2 border-black h-7 p-1">
                  {data.typeofAbsence1}
                </td>
                <td className="border-2 border-black h-7 p-1">
                  {data.typeofAbsence1 && absencePoints(data.typeofAbsence1)}
                </td>
              </tr>
            )}
            {data.dateofAbsence2 && (
              <tr>
                <td className="border-2 border-black h-7 p-1">
                  {data.dateofAbsence2 === "Various"
                    ? data.dateofAbsence2
                    : data.dateofAbsence2 === ""
                    ? ""
                    : formatDateUS(data.dateofAbsence2)}
                </td>
                <td className="border-2 border-black h-7 p-1">
                  {data.typeofAbsence2}
                </td>
                <td className="border-2 border-black h-7 p-1">
                  {data.typeofAbsence2 && absencePoints(data.typeofAbsence2)}
                </td>
              </tr>
            )}
            {data.dateofAbsence3 && (
              <tr>
                <td className="border-2 border-black h-7 p-1">
                  {data.dateofAbsence3 === "Various"
                    ? data.dateofAbsence3
                    : data.dateofAbsence3 === ""
                    ? ""
                    : formatDateUS(data.dateofAbsence3)}
                </td>
                <td className="border-2 border-black h-7 p-1">
                  {data.typeofAbsence3}
                </td>
                <td className="border-2 border-black h-7 p-1">
                  {data.typeofAbsence3 && absencePoints(data.typeofAbsence3)}
                </td>
              </tr>
            )}
            <tr>
              <td className="border-2 border-black h-7 p-1"></td>
              <td className="border-2 border-black h-7 p-1">Total Points </td>
              <td className="border-2 border-black h-7 p-1">
                {(data.previousInfractions &&
                  data.previousInfractions.length > 1) ||
                (data.dateofAbsence0 &&
                  data.previousInfractions &&
                  data.previousInfractions.length > 0) ||
                data.typeofAbsence1
                  ? total
                  : 0}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="flex flex-row gap-x-3 items-center ml-24 mt-4">
        <input
          type="checkbox"
          id="myCheckbox"
          name="myCheckbox"
          className="mt-3"
          checked={data.disagree}
          readOnly
        />

        <label htmlFor="">
          I disagree with the Employer's statement for these reasons.
        </label>
      </div>
      <div>
        <div className="text-base mt-4 justify-start items-start ml-24 w-[650px]">
          <h4 className="font-bold">Comment</h4>
          <p className="text-base underline">{data.comment}</p>
        </div>
      </div>
    </div>
  );
};

export default DiscliplinaryNoticePdf;
