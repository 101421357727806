import React from "react";
import { ChartData } from "types";

interface IProps {
  data: {
    current: ChartData;
    previous: ChartData;
  };
  type: string;
}

const ReportItems: React.FC<IProps> = ({ data, type }) => {
  return (
    <main className="flex flex-wrap gap-x-5 gap-y-7 justify-center ">
      {Object.keys(data.current).map((item, key) => {
        return (
          <div
            key={key}
            className="relative rounded-md text-center py-2 px-7 w-fit min-w-[187px] bg-second dark:bg-gradient"
          >
            <h3 className="font-sans font-semibold text-xl text-primary dark:text-white">
              {item}
            </h3>
            <span className="w-full h-[1px] bg-[#00000026] dark:bg-light block absolute left-0 " />
            <h4
              className={`font-sans font-medium text-lg ${
                data.previous[item] - data.current[item] >= 0
                  ? "text-success"
                  : "text-error"
              } mt-3`}
            >
              {data.current[item]}%
            </h4>
            <div className="ml-[-20px] flex gap-x-3 mt-3 items-center">
              <span
                className={`font-sans font-medium text-base ${
                  data.current[item] - data.previous[item] >= 0
                    ? "text-success"
                    : "text-error"
                }`}
              >
                {data.previous[item]}%
              </span>
              <span className="font-sans font-medium text-sm dark:text-disable text-[#00000056]">
                last {type}
              </span>
            </div>
          </div>
        );
      })}
    </main>
  );
};

export default ReportItems;
