import React from "react";
import Card from "components/Card/Card";
import calendarIcon from "assets/images/absences.svg";
import { Data } from "types";
import List from "components/List/List";
import Loader from "components/Loader/Loader";

interface IProps {
  data: Data[];
  isLoading: boolean;
}
const Absences: React.FC<IProps> = ({ data, isLoading }) => {
  return (
    <Card icon={calendarIcon} label="Absences" className="mb-3">
      {data.length > 0 && <List data={data} />}

      {data.length === 0 && !isLoading && (
        <p className="font-sans font-medium text-base text-center mt-4">
          No Absences
        </p>
      )}
      {data.length === 0 && isLoading && (
        <Loader className="bg-dark dark:bg-white mt-6" />
      )}
    </Card>
  );
};

export default Absences;
