import { Routes, Route, Navigate } from "react-router-dom";
import { PATHS } from "constants/index";
import { Login } from "pages/Login";
import { ForgotPassword } from "pages/ForgotPassword/ForgotPassword";
import { ResetPassword } from "pages/ResetPassword";

export const AuthRoutes = () => {
  return (
    <Routes>
      <Route path={PATHS.LOGIN} element={<Login />} />
      <Route path={PATHS.FORGOTPASSWORD} element={<ForgotPassword />} />
      <Route path={PATHS.RESETPASSWORD} element={<ResetPassword />} />
      <Route path={PATHS.DEFAULT} element={<Navigate to={PATHS.LOGIN} />} />
    </Routes>
  );
};
