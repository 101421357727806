/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import deleteIcon from "assets/images/delete_icon.png";
import Checkbox from "components/Checkbox/Checkbox";
import { useFormik } from "formik";
import { eventPopupSchema } from "utils/validationSchema";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import { EmployeeSlice } from "redux/features/employee/employeeSlice";
import Loader from "components/Loader/Loader";
import { useEmployee } from "hooks/useEmployee";

interface ModalProps {
  data: string;
  onClose: () => void;
}
const EventPopup: React.FC<ModalProps> = ({ data, onClose }) => {
  const { selectedEmployee } = useSelector<RootState, EmployeeSlice>(
    (state) => state.employee
  );
  const { excuseEmployeeeInfraction, excusedInfractionState } = useEmployee();

  const { isExcused, comment, title, date, id } = JSON.parse(data);
  const { handleSubmit, handleChange, values } = useFormik({
    initialValues: {
      isExcused,
      comment,
    },
    validationSchema: eventPopupSchema,
    onSubmit: async (values, onSubmitProps) => {
      const { isExcused, comment} = values;
      const payload = {
        employeeId: selectedEmployee?.id!,
        isExcused,
        comment,
        date,
      };
      await excuseEmployeeeInfraction(payload, selectedEmployee?.name!, id);
      onSubmitProps.setSubmitting(false);
      onClose();
    },
  });

  return (
    <div
      className="absolute bg-white rounded-md w-[250px] border border-dark flex flex-col gap-y-2 dark:bg-dark dark:border-white top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%] z-[100]
    "
    >
      <header className="flex justify-between border-b border-b-light px-2 py-1">
        <h2 className="text-lg font-semibold text-primary dark:text-white">
          {title}
        </h2>
        <button onClick={onClose}>
          <img src={deleteIcon} className="w-4 h-4 dark:invert" alt="cross" />
        </button>
      </header>

      <form className="px-2 py-1 w-full" onSubmit={handleSubmit}>
        <Checkbox
          label="Excused"
          name="isExcused"
          onChange={handleChange}
          checked={values.isExcused}
        />
        <textarea
          placeholder={"Comments"}
          onChange={handleChange}
          name={"comment"}
          value={values.comment}
          className="py-1 px-3 mt-2 border rounded-md border-light text-black font-sans font-semibold text-sm focus-visible:outline-main resize-none h-[100px] placeholder:font-normal placeholder:text-placeholder dark:text-white dark:bg-darkinput dark:border-darkinput"
        />
        <button
          className="px-3 py-1 bg-main text-white rounded text-sm hover:bg-main float-right disabled:bg-disable"
          type="submit"
          disabled={title === "Excused" || title === "Points Reduced"}
        >
          {excusedInfractionState.isLoading ? (
            <Loader className="bg-white" />
          ) : (
            "Submit"
          )}
        </button>
      </form>
    </div>
  );
};

export default EventPopup;
