import { Fragment, forwardRef, memo } from "react";
import "pages/AttendanceReport/styles.css";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import Indicators from "pages/AttendanceReport/components/Indicators";
import { Event, IndicatorValue, Options } from "types";
import { DatesSetArg, EventClickArg } from "@fullcalendar/core";
import moment from "moment";
import { getDifference } from "utils";

interface IProps {
  events: Event[];
  view: Options;
  comparisonData: IndicatorValue[];
  handleCalendarChange: (arg: DatesSetArg) => void;
  setModalProps: React.Dispatch<React.SetStateAction<string>>;
  customDates: string;
}

const Calendar = forwardRef<FullCalendar, IProps>(
  (
    {
      events,
      view,
      comparisonData,
      handleCalendarChange,
      setModalProps,
      customDates,
    },
    ref
  ) => {
    const dates = customDates.split(",");
    return (
      <Fragment>
        <div className="w-full min-w-[400px] md:min-w-[unset] relative">
          <FullCalendar
            ref={ref}
            plugins={[dayGridPlugin]}
            headerToolbar={{
              left: "",
              right: view === Options.CUSTOM ? "title" : "prev,title,next",
              center: "",
            }}
            initialEvents={[]}
            events={events}
            initialDate={
              view === Options.CUSTOM
                ? moment(dates[0]).toDate()
                : moment().toDate()
            }
            initialView={
              view === Options.WEEKLY
                ? "dayGridWeek"
                : view === Options.MONTHLY
                ? "dayGridMonth"
                : view === Options.YEARLY
                ? "dayGridYear"
                : "customView"
            }
            views={{
              customView: {
                type:
                  getDifference(dates[0], dates[1]) > 15
                    ? "dayGridYear"
                    : "dayGrid",

                visibleRange: {
                  start: moment(dates[0]).format("YYYY-MM-DD"),
                  end: moment(dates[1]).format("YYYY-MM-DD"),
                },
              }
            }}
            monthStartFormat={{month: 'short', day: 'numeric'} }
            datesSet={handleCalendarChange}
            dayMaxEvents={true}
            eventDisplay="block"
            hiddenDays={[0]}
            eventClick={(arg: EventClickArg) => {
              const modalProps = {
                title: arg.event._def.title,
                isExcused: arg.event.extendedProps.isExcused,
                comment: arg.event.extendedProps.comment,
                date: arg.event.startStr,
                id:arg.event._def.publicId
              };

              setModalProps(JSON.stringify(modalProps));
            }}
          />
        </div>
        {comparisonData && <Indicators data={comparisonData} />}
      </Fragment>
    );
  }
);

export default memo(Calendar);
