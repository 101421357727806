import React from "react";
import Card from "components/Card/Card";
import uploadIcon from "assets/images/article.svg";
import { useRef } from "react";
import { UPLOADEDDOCUMENTSDATA } from "constants/index";
import Loader from "components/Loader/Loader";
import { useEmployee } from "hooks/useEmployee";
interface IProps {
  employee: string;
}
const UploadedDocuments: React.FC<IProps> = ({ employee }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { employeeState, uploadEmployeeFile } = useEmployee();
  const handleUpload = (e: any) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("employee_id", employee);
    formData.append("pdf_file", file);
    uploadEmployeeFile(formData);
  };
  return (
    <Card icon={uploadIcon} label="Uploaded Documents" className="my-3">
      {!employee ? (
        <p className="font-sans font-medium text-base text-center mt-4">
          No Employee Selected
        </p>
      ) : (
        <div className="flex flex-col items-start mt-4 gap-y-3">
          <div className="flex gap-x-3 items-start">
            <span className="min-w-[14px] min-h-[14px] bg-success rounded-lg mt-[5px]" />
            <p
              dangerouslySetInnerHTML={{ __html: UPLOADEDDOCUMENTSDATA.title }}
            />
          </div>
          {UPLOADEDDOCUMENTSDATA.action && (
            <button
              className="ml-6 bg-success text-[12px] font-semibold text-white py-2 px-5 rounded-md font-sans"
              onClick={() => inputRef.current?.click()}
            >
              {employeeState.isLoading ? (
                <Loader className="bg-white" />
              ) : (
                UPLOADEDDOCUMENTSDATA.action
              )}
            </button>
          )}
          <input
            ref={inputRef}
            onChange={handleUpload}
            className="hidden"
            type="file"
            accept="application/pdf"
          />
        </div>
      )}
    </Card>
  );
};

export default UploadedDocuments;
