import AuthLayout from "components/layouts/AuthLayout";
// import LoginForm from "./components/LoginForm";
import ForgetPasswordForm from "./components/ForgetPasswordForm";
export const ForgotPassword = () => {
  return (
    <AuthLayout>
      <h1 className="text-2xl text-primary mb-4 lg:text-4xl font-bold font-sans xl:mb-6 2xl:mb-10 sm:mb-4 dark:text-white">
        Employee Attendance <br />
        Tracking
      </h1>
      <ForgetPasswordForm />
    </AuthLayout>
  );
};
