import React from "react";

interface IProps {
  text: string;
}
const Tooltip: React.FC<IProps> = ({ text }) => {
  return (
    <span className="bg-[#333] text-sm font-sans font-normal text-white rounded-md z-10 absolute px-2 py-1 top-[-30px] left-[-20px]  transition-all ease-in duration-200 invisible opacity-0 translate-y-[15px]">
      {text}
    </span>
  );
};

export default Tooltip;
