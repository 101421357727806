import profile from "assets/images/user__icon.png";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import { UserSlice } from "redux/features/user/userSlice";

const Profile = () => {
  const { user } = useSelector<RootState, UserSlice>((state) => state.user);
  return (
    <div className="flex justify-between items-center">
      <div className="flex items-center gap-5">
        <img
          src={profile}
          alt="profile"
          className="w-12 h-12 rounded-full object-cover border border-dark dark:border-white"
        />
        <h4 className="text-black font-sans font-semibold text-xl dark:text-white">
          Welcome {user?.name}
        </h4>
      </div>
      {/* <img
        src={notificationIcon}
        alt="notifications-icon"
        className="max-w-[30px] max-h-[30px] cursor-pointer dark:invert-[1]"
      /> */}
    </div>
  );
};

export default Profile;
