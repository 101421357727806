import { object, array, string, number, ref, boolean } from "yup";
import { Admin } from "types";

export const loginSchema = object({
  email: string().email("Invalid email").required("Email is Required"),
  password: string().required("Password is Required"),
});
export const createEmpSchema = object({
  employeeName: string().required("Employee name is required"),
  workLocation: string(),
  employeeID: string().required("Employee ID is required"),
  deptName: string().required("Department name is required"),
  admin: string().required("Admin is required"),
  email: string().email(),
  designation: string(),
  status: boolean(),
  subRole: array()
    .min(1, "Please select a sub role")
    .of(number().required())
    .required(),
});
export const createSupvSchema = object({
  employeeName: string().required("Employee name is required"),
  employeeID: string().required("Employee ID is required"),
  workLocation: string(),
  designation: string(),
  email: string()
    .email()
    .when("admin", (admin, schema) => {
      if (admin[0] === Admin.Supervisor) {
        return schema.required("Must enter email address");
      } else return schema;
    }),
  deptName: string().required("Department name is required"),
  status: boolean(),
  admin: string().required("Admin is required"),
  subRole: array()
    .min(1, "Please select a sub role")
    .of(number().required())
    .required(),
});
export const forgetPasswordSchema = object({
  email: string().email("Invalid email").required("Email is Required"),
});
export const resetPasswordSchema = object({
  password1: string().required("password is required"),
  password2: string()
    .oneOf([ref("password1")], "Passwords must match")
    .required("Confirm password is required"),
});
export const callLogSchema = object({
  employeeName: string().required("Employee name is required"),
  employeeID: string().required("Employee ID is required"),
  infractionType: string().required("Infraction type is required"),
  disciplinaryAction: string().required("Disciplinary action is required"),
  date: string().required("Date is required"),
  comment: string(),
});

export const noticeSchema = object({
  employeeName: string().required("Employee name is required"),
  employeeID: string(),
  workLocation: string(),
  dateOfOccurence: string().required("Date is required"),
  issuedBy: string(),
  employeeDepartment: string().required("Employee Department is required"),
  date: string().required("Date is required"),
  previousWarnings: object().shape({
    "Oral Warning": object().shape({
      checked: boolean(),
      date: string().when(["checked"], {
        is: (checked: boolean) => checked === true,
        then: (schema) => schema.required("Date is required"),
      }),
    }),
    "Written Warning": object().shape({
      checked: boolean(),
      date: string().when(["checked"], {
        is: (checked: boolean) => checked === true,
        then: (schema) => schema.required("Date is required"),
      }),
    }),
    "Suspension 1 Day": object().shape({
      checked: boolean(),
      date: string().when(["checked"], {
        is: (checked: boolean) => checked === true,
        then: (schema) => schema.required("Date is required"),
      }),
    }),
    "Suspension 3 Days": object().shape({
      checked: boolean(),
      date: string().when(["checked"], {
        is: (checked: boolean) => checked === true,
        then: (schema) => schema.required("Date is required"),
      }),
    }),
    "Suspension 5 Days": object().shape({
      checked: boolean(),
      date: string().when(["checked"], {
        is: (checked: boolean) => checked === true,
        then: (schema) => schema.required("Date is required"),
      }),
    }),
  }),
  typeOfAction: object()
    .shape({
      "Oral Counseling": boolean(),
      "Written Warning": boolean(),
      "Final Written Warning": boolean(),
      Probation: boolean(),
      Dismissal: boolean(),
      "Suspension without pay 1 day": boolean(),
      "Suspension without pay 3 days": boolean(),
      "Suspension without pay 5 days": boolean(),
    })
    .test("at-least-one-true", "Please select an action", (obj) => {
      return Object.values(obj).some((val) => val === true);
    })
    .test("only-one-true", "Only one action can be selected", (obj) => {
      return Object.values(obj).filter((val) => val === true).length === 1;
    }),
  recordOfConvo: string(),
  employeeSign: string(),
  employeeSignDate: string().when(["employeeSign"], {
    is: (employeeSign: string) => employeeSign && employeeSign.length > 0,
    then: (schema) => schema.required("Date is required"),
  }),
  supervisorSign: string(),
  supervisorSignDate: string().when(["supervisorSign"], {
    is: (supervisorSign: string) => supervisorSign && supervisorSign.length > 0,
    then: (schema) => schema.required("Date is required"),
  }),
  unionRepSign: string(),
  unionRepSignDate: string().when(["unionRepSign"], {
    is: (unionRepSign: string) => unionRepSign && unionRepSign.length > 0,
    then: (schema) => schema.required("Date is required"),
  }),
  employeerDisagree: boolean(),
  infractionPoints: number(),
  position: string(),
});
export const infractionNoticeSchema = object({
  employeeName: string().required("Employee name is required"),
  date: string().required("Date is required"),
  startDate: string().required("Dates are required"),
  endDate: string(),
  typeofAbsence0: string(),
  typeofAbsence1: string(),
  typeofAbsence2: string(),
  typeofAbsence3: string(),
  dateofAbsence0: string(),
  dateofAbsence1: string().when(["typeofAbsence1"], {
    is: (typeofAbsence1: string) => typeofAbsence1 && typeofAbsence1.length > 0,
    then: (schema) => schema.required("Date is required"),
  }),
  dateofAbsence2: string().when(["typeofAbsence2"], {
    is: (typeofAbsence2: string) => typeofAbsence2 && typeofAbsence2.length > 0,
    then: (schema) => schema.required("Date is required"),
  }),
  dateofAbsence3: string().when(["typeofAbsence3"], {
    is: (typeofAbsence3: string) => typeofAbsence3 && typeofAbsence3.length > 0,
    then: (schema) => schema.required("Date is required"),
  }),
  previousInfractions: array().of(
    object().shape({
      date: string(),
      title: string(),
      points: number(),
    })
  ),
  comment: string(),
  disagree: boolean(),
});

export const eventPopupSchema = object({
  isExcused: boolean(),
  comment: string(),
});
