/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useCallback, useEffect,  useState } from "react";
import Container from "components/Container/Container";
import Select from "components/Select/Select";
import WrapperLayout from "components/layouts/AppLayout";
import ReportItems from "pages/Dashboard/components/ReportItems";
import Chart from "pages/Dashboard/components/Chart";
import TaskReminders from "components/TaskReminders/TaskReminders";
import Heading from "components/Heading/Heading";
import Loader from "components/Loader/Loader";
import Error from "components/Error/Error";
import BarGraph from "components/BarGraph/BarGraph";
import LineChart from "components/LineChart/LineChart";
import { DEPARTMENTOPTIONS, OPTIONS } from "constants/index";
import { Department, Option, Options } from "types";
import {
  getCurrentMonth,
  getCurrentWeek,
  getCurrentYear,
  getTitle,
} from "utils";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import { DashboardSlice } from "redux/features/dashboard/dashboardSlice";
import { useDashboard } from "hooks/useDashboard";
import { UserSlice } from "redux/features/user/userSlice";
import BarGraphMonthly from "components/BarGraph/BarGraphMonthly";
import moment from "moment";

export const Dashboard = () => {
  const { data, reminders, lineChart } = useSelector<RootState, DashboardSlice>(
    (state) => state.dashboard
  );
  const { user } = useSelector<RootState, UserSlice>((state) => state.user);
  const [selectedOption, setSelectedOption] = useState<Option>(OPTIONS[0]);
  const [department, setDepartment] = useState<Option>(
    DEPARTMENTOPTIONS.find((item) => item.label === user?.department) ??
      DEPARTMENTOPTIONS[0]
  );
  const {
    getChartData,
    chartDataState,
    getTaskReminders,
    tasksState,
    emptyLineChartData,
  } = useDashboard();

  //HANDLERS
  const fetchDashboardData = () => {
    if (selectedOption.label === Options.WEEKLY) {
      getChartData(Options.WEEKLY, getCurrentWeek(), department.label);
    }
    if (selectedOption.label === Options.MONTHLY) {
      const monthly = getCurrentMonth() + "," + getCurrentYear();
      getChartData(Options.MONTHLY, monthly, department.label);
    }
    if (selectedOption.label === Options.YEARLY) {
      getChartData(Options.YEARLY, getCurrentYear(), department.label);
    }
    if (selectedOption.value === Options.CUSTOM) {
      const { startDate, endDate } = JSON.parse(selectedOption.label);
      getChartData(Options.CUSTOM, `${startDate},${endDate}`, department.label);
    }
  };

  const handleSelect = useCallback((item: Option) => {
    setSelectedOption(item);
  }, []);
  const is_date_difference_within_range = useCallback(() => {
    const { startDate, endDate } = JSON.parse(selectedOption.label);
    const difference = moment(endDate).diff(startDate, "days");
    // console.log("difference :>> ", difference);
    if (difference > 10 && difference <= 30) return true;
    return false;
  }, [selectedOption.label]);

  //EFFECTS
  useEffect(() => {
    getTaskReminders();
    //cleanup
    return () => {
      emptyLineChartData();
    };
  }, []);
  useEffect(() => {
    fetchDashboardData();
  }, [department, selectedOption]);

  return (
    <WrapperLayout>
      <div className="flex flex-col gap-y-5 xl:flex-row items-start mt-6 gap-x-6">
        <div className="flex flex-col items-start justify-between gap-y-5 3xl:max-w-[960px] xl:max-w-[65%] w-full">
          <Container className="px-3 sm:px-8 flex justify-between items-center w-full h-[119px]">
            <Heading title="Dashboard" />
            <div className="flex gap-x-4 gap-y-4 flex-col md:flex-row">
              {(user?.isAdmin || user?.department === Department.both) && (
                <Select
                  options={DEPARTMENTOPTIONS}
                  className="w-auto"
                  selected={department}
                  onSelect={(item: Option) => setDepartment(item)}
                  customDate={false}
                />
              )}

              <Select
                className="w-auto"
                options={OPTIONS}
                selected={selectedOption}
                onSelect={handleSelect}
                customDate={true}
              />
            </div>
          </Container>
          <Container
            className={`card py-10 px-8 flex flex-col w-full overflow-y-auto overflow-x-auto  ${
              (chartDataState.isLoading || chartDataState.isError) &&
              "min-h-[500px]"
            }`}
          >
            {chartDataState.isLoading ? (
              <Loader className="bg-dark dark:bg-white" />
            ) : chartDataState.isError ? (
              <Error
                message="Something went wrong. Please try again"
                onClick={() => handleSelect(selectedOption)}
              />
            ) : (
              <Fragment>
                <h2 className="font-sans font-semibold text-xl text-primary underline mb-10 dark:text-white self-start">
                  {getTitle(selectedOption)}
                </h2>

                <ReportItems
                  data={data.reportItems}
                  type={
                    selectedOption.label === Options.MONTHLY
                      ? "month"
                      : selectedOption.label === Options.WEEKLY
                      ? "week"
                      : "year"
                  }
                />
                <Chart
                  title={"Total Absences"}
                  data={data.totalAbsenses}
                  className={"w-[250px]"}
                />
                <h2 className="font-sans font-bold text-primary dark:text-white text-xl sm:text-2xl flex justify-center text-center mt-3 w-full">
                  Trends In Employee Infractions{" "}
                </h2>
                {selectedOption.label === Options.MONTHLY ||
                (selectedOption.value === Options.CUSTOM &&
                  is_date_difference_within_range()) ? (
                  <BarGraphMonthly data={data.barChart} />
                ) : (
                  <BarGraph data={data.barChart} />
                )}

                <h2 className="mt-8 font-sans font-bold text-primary dark:text-white text-xl sm:text-2xl flex justify-center text-center w-full ">
                  Trends In Total Absences Over Time
                </h2>
                <LineChart data={lineChart} />
              </Fragment>
            )}
          </Container>
        </div>
        <Container
          className="py-9 px-7 w-full xl:max-w-[390px] overflow-y-auto min-h-[500px]"
          style={{ maxHeight: "calc(100vh - 135px)" }}
        >
          {tasksState.isError ? (
            <Error
              message="Something went wrong. Please try again"
              onClick={() => getTaskReminders()}
            />
          ) : (
            <TaskReminders tasks={reminders} isLoading={tasksState.isLoading} />
          )}
        </Container>
      </div>
    </WrapperLayout>
  );
};
