import React from "react";
import { IndicatorValue, Theme } from "types";
import indicatorarrowIcon from "assets/images/indicatorarrow__icon.png";
import { useTheme } from "context/ThemeProvider";
import { INDICATORSCOLORS } from "constants/index";
interface IProps {
  data: IndicatorValue[];
}

const Indicators: React.FC<IProps> = ({ data }) => {
  const { theme } = useTheme();
  return (
    <div className="flex flex-wrap gap-x-4 gap-y-5 mt-4">
      {data.map((item, index) => {
        const bgColor1 =
          theme === Theme.LIGHT
            ? INDICATORSCOLORS[item.name].lightBgColor1
            : INDICATORSCOLORS[item.name].darkBgColor1;
        const bgColor2 =
          theme === Theme.LIGHT
            ? INDICATORSCOLORS[item.name].lightBgColor2
            : INDICATORSCOLORS[item.name].darkBgColor2;
        return (
          <div key={index} className="flex flex-row items-center rounded-lg">
            <div
              className="p-3 rounded-l-lg text-white"
              style={{ background: `${bgColor1}` }}
            >
              <p className="text-xs font-sans font-bold">
                {item.name === "workingDays" ? "Working Days" : item.name}
              </p>
              <p className="font-bold text-sm font-sans">{item.value}</p>
            </div>
            <div
              className="p-3 rounded-tr-lg rounded-br-lg"
              style={{ background: `${bgColor2}` }}
            >
              <p className="mb-1 text-xs text-primary dark:text-white">
                {item.lastYear} last year
              </p>
              <div className="flex gap-x-2">
                <img
                  src={indicatorarrowIcon}
                  alt="arrow"
                  className={`w-2 h-3 dark:invert-[1] ${
                    !item.arrow.includes("Up") && "rotate-180"
                  } `}
                />
                <p className="text-xs text-primary dark:text-white">
                  {item.rate}% {item.arrow}
                </p>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Indicators;
