import React from "react";
import Input from "components/Input/Input";
import Button from "components/Button/Button";
import login__icon from "assets/images/login_btn__icon.png";
import { useFormik } from "formik";
import { loginSchema } from "utils/validationSchema";
import { LOGININITIALVALUES, PATHS } from "constants/index";
import { useUser } from "hooks/useUser";
import { Link } from "react-router-dom";
const LoginForm = () => {
  const { userState, signIn } = useUser();
  const { handleSubmit, handleChange, values, touched, errors } = useFormik({
    initialValues: LOGININITIALVALUES,
    validationSchema: loginSchema,

    onSubmit: async (values, onSubmit) => {
      // console.log("values", values);
      const { email, password } = values;
      await signIn({ email, password });
      if (!userState.isLoading) {
        onSubmit.setSubmitting(false);
      }
    },
  });
  return (
    <form
      className="flex flex-col max-w-[504px]"
      noValidate
      onSubmit={handleSubmit}
    >
      <Input
        label={"Your Email"}
        placeholder="Your Email"
        name="email"
        error={touched.email && errors.email}
        className="mb-[19px]"
        onChange={handleChange}
        value={values.email}
      />
      <Input
        label={"Your Password"}
        placeholder="*********"
        type="password"
        name="password"
        error={touched.password && errors.password}
        className="lg:mb-3 lg:mt-0 md:mb-[1px]"
        onChange={handleChange}
        value={values.password}
      />
      <Link
        to={PATHS.FORGOTPASSWORD}
        className="mt-[15px] text-sm font-bold font-sans text-main dark:text-success self-end"
      >
        Forgot Password?
      </Link>
      <Button
        type="submit"
        isLoading={userState.isLoading}
        title={"Sign in"}
        leftIcon={login__icon}
        className="mx-auto min-w-[297px] mt-[67px]  xl:mt-[60px] dark:bg-success"
      />
      {/* <p className="text-lg font-sans font-light text-primary dark:text-white mt-7  text-center">
        Don't have an account?{" "}
        <a
          href={"/"}
          className="font-bold text-main mb-[2px] 2xl:mb-0 xl:mb-0 dark:text-success"
        >
          Sign up
        </a>
      </p> */}
    </form>
  );
};

export default LoginForm;
