import { PATHS } from "constants/index";
import { useTheme } from "context/ThemeProvider";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  fetchUser,
  sendResetPasswordEmail,
  setNewPassword,
} from "redux/api/user";
import { login, logout } from "redux/features/user/userSlice";
import { useAppDispatch } from "redux/store";
import { ApiResponse, User, Theme } from "types";
import { deleteUser, setUser } from "utils/user";

export type signIn = {
  email: string;
  password: string;
};
export type confirmPassword = {
  uid: string;
  token: string;
  new_password1: string;
  new_password2: string;
};

export const useUser = () => {
  const [userState, setUserState] = useState<ApiResponse>({
    isLoading: false,
    isError: false,
    error: "",
  });
  const { setTheme } = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const signIn = useCallback(
    async ({ email, password }: signIn) => {
      //set loading to true
      setUserState((prev) => {
        return { ...prev, isLoading: true };
      });

      const result = await fetchUser({ email, password });
      //set loading to false
      setUserState((prev) => {
        return { ...prev, isLoading: false };
      });
      if (result) {
        //save in redux && localStorage
        const { key, isAdmin, name, isSupervisor, dept, designation } = result.data;
        const user: User = {
          key: key,
          isAdmin: isAdmin,
          isSupervisor: isSupervisor,
          designation,
          name: name,
          department: dept,
        };
        toast.success("Welcome to RATP");
        setUser(user);
        dispatch(login(user));
        navigate(PATHS.HOME);
      }
    },

    [navigate, dispatch]
  );
  const forgotPassword = useCallback(async (email: string) => {
    //set loading to true
    setUserState((prev) => {
      return { ...prev, isLoading: true };
    });

    const result = await sendResetPasswordEmail(email);
    //set loading to false
    setUserState((prev) => {
      return { ...prev, isLoading: false };
    });
    if (result) {
      toast.success("Password reset email sent");
    }
  }, []);

  const resetPassword = useCallback(
    async (password: confirmPassword) => {
      //set loading to true
      setUserState((prev) => {
        return { ...prev, isLoading: true };
      });

      const result = await setNewPassword(password);
      //set loading to false
      setUserState((prev) => {
        return { ...prev, isLoading: false };
      });
      if (result) {
        toast.success("Password changed");
        navigate(PATHS.LOGIN);
      }
    },
    [navigate]
  );
  const signOut = useCallback(() => {
    setTheme(Theme.LIGHT);
    dispatch(logout());
    deleteUser();

    navigate(PATHS.LOGIN);
  }, [dispatch, navigate, setTheme]);

  return { userState, signIn, signOut, forgotPassword, resetPassword };
};
