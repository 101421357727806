import React from "react";

interface IProps {
  children: React.ReactNode;
  icon: string;
  label: string;
  className?: string;
}

const Card: React.FC<IProps> = ({ children, icon, label, className }) => {
  return (
    <div
      className={[
        "border border-solid border-main bg-white dark:bg-darkinput dark:border-darkinput rounded-md pl-3 pt-4 pr-4 pb-7 flex flex-col",
        className,
      ].join(" ")}
    >
      <div className="flex items-center gap-x-2">
        <img
          src={icon}
          alt="icon"
          className="h-5 w-5 object-cover dark:invert-[1]"
        />
        <h3 className="font-sans font-bold text-black dark:text-white text-lg capitalize">
          {label}
        </h3>
      </div>
      <div className="dark:text-white">{children}</div>
    </div>
  );
};

export default Card;
