import React, { Fragment } from "react";
import loginImg from "assets/images/login.png";
import logo from "assets/images/logo.png";

interface IProps {
  children: React.ReactNode;
}
const AuthLayout: React.FC<IProps> = ({ children }) => {
  return (
    <Fragment>
      <div className="grid lg:grid-cols-2 grid-rows-1 grid-cols-1 w-full sm:[landscape:hidden]">
        <div className="relative h-[100vh] hidden lg:block xl:left-1% xl:top-5% lg:left-2%">
          <div className="absolute left-[50%] top-[50%] translate-y-[-50%] translate-x-[-50%] ">
            <img src={loginImg} className="object-cover" alt="login-display" />
          </div>
        </div>

        <div className="bg-white dark:bg-dark dark:border-[#D9D9D9] h-[100vh] flex flex-col px-7 lg:rounded-large lg:border-[1px] lg:border-[#d9d9d9] lg:border-solid lg:shadow-login lg:p-[60px] md:p-[150px] sm:pt-[80px]">
          <img
            src={logo}
            alt="logo"
            className="max-h-[50px] max-w-[270px] mt-12 mb-[47px] 2xl:max-h-[65px] 2xl:max-w-[304px] xl:mb-[57px] xl:mt-0 lg:mt-0"
          />
          {children}
        </div>
      </div>
    </Fragment>
  );
};

export default AuthLayout;
