/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useEffect, useState } from "react";
import AutoComplete from "components/AutoComplete/AutoComplete";
import DatePicker from "components/DatePicker/DatePicker";
import DatePickerRange from "components/DatePicker/DatePickerRange";
import Textarea from "components/Textarea/Textarea";
import Button from "components/Button/Button";
import Select from "components/Select/Select2";
import Checkbox from "components/Checkbox/Checkbox";
import AttendancePolicyPDF from "constants/AttendancePolicyInfractionNoticePDF";
import {
  INFRACTIONOPTIONSACTION,
  INFRACTNOTICEINITIALVALUES,
} from "constants/index";
import { useFormik } from "formik";
import { infractionNoticeSchema } from "utils/validationSchema";
import { printPDF } from "utils";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import { EmployeeSlice } from "redux/features/employee/employeeSlice";
import { Infraction, Options } from "types";
import { useEmployee } from "hooks/useEmployee";

const InfractionForm = () => {
  const { handleSubmit, handleChange, values, touched, errors, setFieldValue } =
    useFormik({
      initialValues: INFRACTNOTICEINITIALVALUES,
      validationSchema: infractionNoticeSchema,

      onSubmit: (values) => {
        // console.log("values", values);
        printPDF(
          <AttendancePolicyPDF data={values} />,
          "Attendance_Infraction-Action-Form_Rai.pdf",
          1250
        );
      },
    });
  const { employees } = useSelector<RootState, EmployeeSlice>(
    (state) => state.employee
  );
  const { getEmployeeAttendanceReport } = useEmployee();

  const [counter, setCounter] = useState(1);

  const handleCounter = (op: string) => {
    if (op === "add") {
      const count = counter + 1;
      setCounter((prev) => prev + 1);
      for (let i = 1; i < count; i++) {
        //@ts-ignore
        if (values[`typeofAbsence${i}`].length === 0)
          setFieldValue(`typeofAbsence${i}`, Infraction.Sick1);
      }
    }
    if (op === "sub" && counter > 1) {
      setCounter((prev) => prev - 1);
      setFieldValue(`dateofAbsence${counter - 1}`, "");
      setFieldValue(`typeofAbsence${counter - 1}`, "");
    }
  };

  useEffect(() => {
    if (!values.employeeName || !values.startDate) return;
    getEmployeeAttendanceReport({
      type: Options.WEEKLY,
      employeeName: values.employeeName,
      date: "",
    }).then((events) => {
      let previousInfractions = events
        ?.filter((infraction) => {
          return infraction.title.toLowerCase() !== "excused";
        })
        .map((event) => {
          return {
            title: event.title,
            date: event.date,
            points: event.points,
          };
        });
      //sorting according to date
      if (previousInfractions) {
        for (let i = 0; i < previousInfractions.length - 1; i++) {
          for (let j = i + 1; j < previousInfractions.length; j++) {
            if (previousInfractions[i].date > previousInfractions[j].date) {
              [previousInfractions[i], previousInfractions[j]] = [
                previousInfractions[j],
                previousInfractions[i],
              ];
            }
          }
        }
      }
      previousInfractions = previousInfractions?.filter((item:any) => {
        const itemDate = new Date(item.date)
        itemDate.setHours(0, 0, 0, 0);
        return itemDate >= new Date(values.startDate || '') && itemDate <= new Date(values.endDate || '');
       });
      setFieldValue("previousInfractions", previousInfractions);
      console.log(previousInfractions)
    });
  }, [values.employeeName,values.startDate ]);

  return (
    <form
      className="flex flex-wrap gap-x-7 gap-y-6"
      noValidate
      onSubmit={handleSubmit}
    >
      <AutoComplete
        data={employees}
        label="Employee Name"
        placeholder="Employee Name"
        name="employeeName"
        error={touched.employeeName && errors.employeeName}
        value={values.employeeName}
        className="w-full lg:w-[47%] lg:max-w-[397px]"
        handleClick={(item: string) => setFieldValue("employeeName", item)}
      />

      <DatePicker
        label={"Date"}
        placeholder="MM/DD/YYYY"
        name="date"
        error={touched.date && errors.date}
        onClick={(date: string) => setFieldValue("date", date)}
        className="w-full lg:w-[47%] lg:max-w-[397px]"
      />
      <div className="flex flex-wrap gap-x-6 w-full">
        {Array.from(Array(counter).keys()).map((i) => {
          return (
            <Fragment key={i}>
              <Select
                label="Type of absence"
                options={INFRACTIONOPTIONSACTION}
                //@ts-ignore
                value={values[`typeofAbsence${i}`]}
                className="w-full lg:w-[47%] lg:max-w-[397px]"
                onClick={(value: string) => {
                  setFieldValue(`typeofAbsence${i}`, value);
                }}
              />
              <DatePickerRange
                label={"Date of Absence"}
                placeholder="MM/DD/YYYY"
                name={`startDate`}
                error={touched.startDate && errors.startDate}
                onClick={(date: string) => {
                  const values = JSON.parse(date);
                    setFieldValue(`startDate`, values.startDate);
                    setFieldValue(`endDate`, values.endDate);
                }}
                className="w-full lg:w-[47%] lg:max-w-[397px]"
              />
            </Fragment>
          );
        })}
      </div>
      <div className="w-full flex flex-wrap justify-space gap-x-3 xl:w-[65%]">
        {/* <button
          onClick={() => handleCounter("add")}
          type="button"
          disabled={counter === 4}
          className="mt-[-15px] text-sm text-main font-bold dark:text-white"
        >
          + Add Infraction
        </button> */}
        {counter > 1 && 
        <button
          onClick={() => handleCounter("sub")}
          type="button"
          className="mt-[-15px] text-sm text-main font-bold dark:text-white"
        >
          - Remove Infraction
        </button>
        }
      </div>

      <Checkbox
        label="I disagree with the Employer's statement for these reasons:"
        name="disagree"
        checked={values.disagree}
        onChange={handleChange}
        className="w-full"
      />
      <Textarea
        label={"Add Comment"}
        placeholder="Your Comments"
        type="text"
        name="comment"
        error={touched.comment && errors.comment}
        value={values.comment}
        className="w-full"
        onChange={handleChange}
      />
      <Button
        type="submit"
        title={"Submit"}
        className="mx-auto dark:bg-success px-10"
      />
    </form>
  );
};
export default InfractionForm;
