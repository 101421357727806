import React, { useState } from "react";
import activeIcon from "assets/images/bulb_active__icon.png";
import inactiveIcon from "assets/images/bulb_inactive__icon.png";
import { useWindowSize } from "hooks/useWindowSize";
import lightIcon from "assets/images/sun__icon.png";
import darkIcon from "assets/images/moon__icon.png";
import { useTheme } from "context/ThemeProvider";
import { Theme } from "types";

interface IProps {
  className?: string;
}

const Switch: React.FC<IProps> = ({ className }) => {
  const { theme, setTheme } = useTheme();
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (checked) setTheme(Theme.DARK);
    else setTheme(Theme.LIGHT);
    setChecked(!checked);
  };
  const [checked, setChecked] = useState<boolean>(
    theme === Theme.DARK ? false : true
  );
  const { width } = useWindowSize();

  return (
    <React.Fragment>
      <input
        type="checkbox"
        id="switch"
        className="hidden"
        onChange={handleChange}
      />
      <div
        className={[
          "bg-[#f8f8f8] dark:bg-darkinput rounded-md py-3 px-4 w-full ",
          className,
        ].join(" ")}
      >
        <label
          htmlFor="switch"
          className={
            "flex gap-x-6 items-center cursor-pointer lg:justify-start justify-center"
          }
        >
          {width < 1024 && width > 650 ? (
            <img
              src={checked ? darkIcon : lightIcon}
              alt="sun"
              className="w-7 h-7 object-cover "
            />
          ) : (
            <>
              <div
                className={`flex gap-x-3 items-center transition-all ease-in-out duration-200 ${
                  checked ? "bg-white" : "bg-transparent"
                } rounded-md py-2 px-4`}
              >
                <img
                  src={checked ? activeIcon : inactiveIcon}
                  alt="icon"
                  className="h-5 w-5 object-contain"
                />
                <span
                  className={`font-sans font-semibold text-base  ${
                    checked
                      ? "text-primary"
                      : "text-disable dark:text-[#858585]"
                  }`}
                >
                  Light
                </span>
              </div>
              <div
                className={`flex gap-x-3 items-center transition-all ease-in-out duration-200 ${
                  !checked ? "bg-success" : "transparent"
                } rounded-md py-2 px-4`}
              >
                <img
                  src={!checked ? activeIcon : inactiveIcon}
                  alt="icon"
                  className="h-5 w-5 object-contain dark:invert-[1]"
                />
                <span
                  className={`font-sans font-semibold text-base ${
                    !checked ? "text-white" : "text-primary"
                  }`}
                >
                  Dark
                </span>
              </div>
            </>
          )}
        </label>
      </div>
    </React.Fragment>
  );
};

export default Switch;
