import { createContext, useEffect, useState } from "react";
import { User } from "types";
import { getUser } from "utils/user";
import { useDispatch } from "react-redux";
import { login, logout } from "redux/features/user/userSlice";

const AuthContext = createContext<User | undefined>(undefined);

interface IProps {
  children: React.ReactNode;
}

const AuthProvider: React.FC<IProps> = ({ children }) => {
  const [user, setUser] = useState<User | undefined>(undefined);
  const dispatch = useDispatch();

  useEffect(() => {
    const user = getUser();
    if (user) {
      setUser(user);
      dispatch(login(user));
    } else {
      dispatch(logout());
    }
  }, [dispatch]);

  return <AuthContext.Provider value={user}>{children}</AuthContext.Provider>;
};
export default AuthProvider;
