import { InferType } from "yup";
import {
  callLogSchema,
  createEmpSchema,
  createSupvSchema,
  forgetPasswordSchema,
  loginSchema,
  resetPasswordSchema,
  noticeSchema,
  infractionNoticeSchema,
} from "utils/validationSchema";

export interface SubRole {
  [key: string]: number;
}
export type CheckboxOptions = {
  [key: string]: boolean;
};
export interface Role {
  [key: string]: number;
}
export interface TypesOfAction {
  [key: string]: string;
}
export type Option = {
  label: string;
  value: string | number;
};
export type Task = {
  id: number;
  task: string;
  name: string;
  status: "pending" | "completed";
};
export type Data = {
  title: string;
  action?: string;
};

export enum Infraction {
  Sick1 = "Sick (1 hr notice)",
  Sick2 = "Sick (less than 1 hr notice)",
  NCNS = "NCNS (No call/No show)",
  LRI1 = "LRI (Late for scheduled shift 2 minutes) ",
  Personal1 = "Personal (1 hr notice)",
  Personal2 = "Personal (less than 1 hr notice)",
  LRI2 = "LRI (Late for scheduled shift 2 minutes, no work assigned)",
  LE = "LE (Left early/failure to complete entire shift)",
  FMLA = "FMLA",
}

export enum Disciplinary {
  "No Action" = "No Action",
  "Oral Warning" = "Oral Warning",
  "Written Warning" = "Written Warning",
  "Suspension1" = "Suspension1",
  "Suspension2" = "Suspension2",
  "Suspension3" = "Suspension3",
  "Termination" = "Termination",
}
export enum Admin {
  Supervisor = "Supervisor",
  Employee = "Employee",
  Mechanic = "Mechanic",
}

export enum Department {
  operations = "operations",
  maintenance = "maintenance",
  both = "both",
}

export interface Columns {
  col1: string;
  col2: string;
}

export enum DateProperty {
  YEAR = "year",
  MONTH = "month",
  DAY = "day",
}

export enum Options {
  WEEKLY = "Weekly",
  MONTHLY = "This Month",
  YEARLY = "This Year",
  CUSTOM = "Custom Date",
  ALL = "All",
  SEARCH = "Search",
}
export enum FormOptions {
  "Disciplinary Form" = "Disciplinary Form",
  "Infraction Notice Form" = "Infraction Notice Form",
}

export interface Event {
  title: string;
  date: string;
  id:string,
  isExcused: boolean;
  comment: string;
  color: string;
  employeeName?: string;
  points?: number | string;
  points_reduced?: string;
}

export interface ChartData {
  [key: string]: number;
}

export interface BarChart {
  [key: string]: {
    sick: number;
    personal: number;
    fmla: number;
    lri: number;
    ncns: number;
    other: number;
    execused: number;
    points_reduced: number;
    le: number;
  };
}
export enum Theme {
  LIGHT = "light",
  DARK = "dark",
}
export interface IndicatorValue {
  name: string;
  value: number;
  lastYear: number;
  rate: number;
  arrow: string;
}

export interface Employee {
  id: number | string;
  name: string;
}
export interface SelectedEmployee extends Employee {
  role: string;
}
export type LoginSchema = InferType<typeof loginSchema>;
export type CreateEmpSchema = InferType<typeof createEmpSchema>;
export type CreateSupvSchema = InferType<typeof createSupvSchema>;
export type CallLogSchema = InferType<typeof callLogSchema>;
export type ForgetPasswordSchema = InferType<typeof forgetPasswordSchema>;
export type ResetPasswordSchema = InferType<typeof resetPasswordSchema>;
export type NoticeSchema = InferType<typeof noticeSchema>;
export type InfractionNoticeSchema = InferType<typeof infractionNoticeSchema>;

export type User = {
  key: string;
  name: string;
  isAdmin: boolean;
  isSupervisor: boolean;
  designation: string;
  department: string;
};
export interface ApiResponse {
  isLoading: boolean;
  isError: boolean;
  error: string;
}

export type Members = {
  name: string;
  employeeId: string;
  department: string;
  role: string;
  designation: string;
  status: boolean;
};

export type Reminders = {
  id: number;
  name: string;
  points: number;
  reminder: string;
};

export type SubRoles = {
  operations: SubRole;
  maintenance: SubRole;
  both: SubRole;
};
export type ObjectType = {
  [key: string]: {};
};
export enum TableNames {
  NOOFEMPLOYEES = "# of Employees Scheduled Inc. EB/OT",
  VACATION = "Vacation",
  SICK = "Sick",
  WCI = "WCI",
  FMLA = "FMLA Calls Out",
  "NCNS/PERSONAL" = "NCNS/PERSONAL",
  OTHERS = "Others",
  OOS = "OOS",
  TOTAL = "Total Absenteeism",
  DAILY = "Daily Absenteeism%",
  TARGET = "Target",
}

export type EmployeeAttendanceData = {
  [key: string]: {
    [key: string]: {
      comments: string;
      excused: boolean;
      date: string;
      employeeName?: string;
      points: number | string;
      points_reduced: string;
    };
  };
};
