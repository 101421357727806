import React from "react";

interface IProps {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}
const Container: React.FC<IProps> = ({ children, className, style }) => {
  return (
    <div
      className={[
        "bg-white rounded-large border-[1px] border-solid border-main shadow-2xl dark:border-dark dark:bg-dark",
        className,
      ].join(" ")}
      style={style}
    >
      {children}
    </div>
  );
};

export default Container;
