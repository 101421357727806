import React from "react";
import uploadIcon from "assets/images/upload__icon.png";
interface IProps {
  name: string;
  label: string;
  saveSign: (sign: string, by: string) => void;
  sign: string | undefined;
}

const UploadSignature: React.FC<IProps> = ({ label, name, saveSign, sign }) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      const fr = new FileReader();
      fr.onload = function () {
        if (typeof fr.result === "string") saveSign(fr.result, name);
      };
      fr.readAsDataURL(files[0]);
    }
  };

  return (
    <div className="flex flex-col gap-y-2">
      <label className="text-sm font-bold font-sans text-main dark:text-white mb-[9px] mt-[10px]">
        {label}
      </label>
      <div className="relative w-[300px] h-[150px] bg-light hover:bg-[#e4e4e4] hover:transition-all hover:duration-300">
        <input
          type="file"
          name={name}
          className="h-full w-full opacity-0 cursor-pointer"
          accept=".png, .jpg, .jpeg"
          onChange={handleChange}
        />
        <img
          src={uploadIcon}
          alt="upload-icon"
          className="object-contain absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%]"
        />
      </div>
      {sign && (
        <img
          src={sign}
          alt="signature"
          className="w-[70px] mt-1 object-contain"
        />
      )}
    </div>
  );
};

export default UploadSignature;
