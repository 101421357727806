import {
  Event,
  Theme,
  Option,
  Options,
  IndicatorValue,
  Infraction,
  BarChart,
  ObjectType,
  ChartData,
  EmployeeAttendanceData,
  DateProperty,
  TableNames,
} from "types";
import { CHARTCOLORS, TABLEROWNAMES } from "constants/index";
import moment from "moment";
import ReactDOM from "react-dom/client";
import jsPDF from "jspdf";
import ExcelJS from "exceljs";

export const getInitialTheme = (): string => {
  if (typeof window !== "undefined" && window.localStorage) {
    const storedPrefs = window.localStorage.getItem("current-theme");
    if (typeof storedPrefs === "string") {
      return storedPrefs;
    }
    // if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
    //   return Theme.DARK;
    // }
  }
  return Theme.LIGHT;
};
function transformDateFormat(input: any) {
  if (!input.includes("-") || !input.includes(",")) {
    return input;
  }
  const dates = input.split(",");
  const transformedDates = dates.map((date: any) => {
    const parts = date.split("-");
    const formattedDate = `${parts[0]}/${parts[1]}/${parts[2]}`;
    return formattedDate;
  });
  const dateRange = transformedDates.join(" - ");
  return dateRange;
}
export const getCurrentWeek = () => {
  var startDate = moment().startOf("week").add(1, "day").format("YYYY-MM-DD");
  var endDate = moment().endOf("week").format("YYYY-MM-DD");

  return `${startDate},${endDate}`;
};
export const getCurrentMonth = () => {
  return moment().format("M");
};
export const getCurrentYear = () => {
  return moment().format("YYYY");
};
export const getFileName = (fileName: any) => {
  return fileName.split("/").pop().split("#")[0].split("?")[0];
};
export const getPeriod = (option: Option, startDate: Date, endDate: Date) => {
  if (option.value === Options.WEEKLY) {
    return `${moment(startDate).format("MM-DD-YYYY")},${moment(endDate).format(
      "MM-DD-YYYY"
    )}`;
  }
  if (option.value === Options.MONTHLY) {
    return `${moment(startDate).format("MMMM")},${moment(startDate).format(
      "YYYY"
    )}`;
  }
  if (option.value === Options.YEARLY) return moment(startDate).format("YYYY");
  if (option.value === Options.CUSTOM || option.value === Options.WEEKLY) {
    return `${moment(startDate).format("MM-DD-YYYY")},${moment(endDate).format(
      "MM-DD-YYYY"
    )}`;
  }
};

export const getDateProps = (date: Date, property: DateProperty) => {
  if (property === DateProperty.YEAR) return moment(date).year();
  if (property === DateProperty.MONTH) return moment(date).month() + 1;
  if (property === DateProperty.DAY) return moment(date).date();
  return moment(date).date();
};

export const getTitle = (item: Option) => {
  const month = moment().format("MMMM");
  const year = moment().format("YYYY");

  if (item.label === Options.MONTHLY) {
    return `Monthly Summary Report - ${month} , ${year}`;
  }

  if (item.label === Options.YEARLY) {
    return `Yearly Summary Report - ${year}`;
  }
  if (item.label === Options.WEEKLY) {
    var startDate = moment().startOf("week");
    var endDate = moment().endOf("week");

    const title = `Weekly Summary Report - ${startDate.format(
      "MMMM"
    )} ${startDate.format("DD")} - ${endDate.format("MMMM")} ${endDate.format(
      "DD"
    )} , ${year}`;

    return title;
  } else {
    const customDate = JSON.parse(item.label);
    const startDate = customDate.startDate;
    const endDate = customDate.endDate;
    const start = moment(startDate, "YYYY-MM-DD").format("MMMM DD, YYYY");
    const end = moment(endDate, "YYYY-MM-DD").format("MMMM DD, YYYY");
    return `Summary Report - ${start} - ${end}`;
  }
};

export const createEvents = (
  data: EmployeeAttendanceData,
  isAllEmployees?: boolean
) => {
  let events: Event[] = [];
  for (let [key, value] of Object.entries(data)) {
    if (Object.keys(value).length === 0) continue;

    Object.keys(value).forEach((id) => {
      if (isAllEmployees) {
        const event: Event = {
          employeeName: data[key][id].employeeName,
          points: data[key][id].points,
          title: key,
          date: data[key][id].date,
          isExcused: data[key][id].excused,
          id:id,
          comment: data[key][id].comments,
          color: data[key][id].excused
            ? CHARTCOLORS.light.Excused
            : CHARTCOLORS.light[key],
        };
        events.push(event);
        return;
      } else {
        const event: Event = {
          title: key,
          date: data[key][id].date,
          points: data[key][id].points,
          id:id,
          isExcused: data[key][id].excused,
          comment: data[key][id].comments,
          color: data[key][id].excused
            ? CHARTCOLORS.light.Excused
            : CHARTCOLORS.light[key],
          points_reduced: data[key][id].points_reduced,
        };
        events.push(event);
      }
    });
  }
  return events;
};

export const createInfractionComparsion = (data: {
  [key: string]: {
    [key: string]: {
      points: number;
      rate: number;
      arrow: string;
      count: number;
    };
  };
}) => {
  const currentYearInfractions = data["infraction_current_year"];
  const lastYearInfractions = data["infraction_previous_year"];

  const infractionsData: IndicatorValue[] = Object.keys(currentYearInfractions)
    .map((infraction) => {
      return {
        name: infraction,
        value:
          currentYearInfractions[infraction].points ??
          currentYearInfractions[infraction].count,
        rate: currentYearInfractions[infraction].rate,
        lastYear: Number(lastYearInfractions[infraction]),
        arrow: currentYearInfractions[infraction].arrow,
      };
    })
    .filter((item) => item.name !== "FMLA" && item.name !== "LRI");

  return infractionsData;
};

export const capatalize = (value: string) => {
  return value.substring(0, 1).toUpperCase() + value.substring(1);
};
export const formatDateUS = (
  date: string | undefined | Date,
  removeDays?: string,
  type?: "en"
) => {
  if (type === "en") {
    return moment(date).format("YYYY-MM-DD");
  }

  if (date instanceof Date && removeDays) {
    return moment(date).subtract(removeDays, "day").format("MM-DD-YYYY");
  } else {
    return moment(date).format("MM-DD-YYYY");
  }
};
export const formatDateforExcel = (
  date: string | undefined | Date,
  removeDays?: string,
  type?: "en"
) => {
  if (type === "en") {
    return moment(date).format("YYYY/MM/DD");
  }

  if (date instanceof Date && removeDays) {
    return moment(date).subtract(removeDays, "day").format("MM/DDYYY");
  } else {
    return moment(date).format("MM/DD/YYYY");
  }
};
export const getDifference = (startDate: string, endDate: string) => {
  return moment(endDate).diff(moment(startDate), "days");
};

export const getInfractionTitle = (infraction: string) => {
  if (infraction === Infraction.Sick1) return "Sick1";
  if (infraction === Infraction.Sick2) return "Sick2";
  if (infraction === Infraction.LRI1) return "LRI1";
  if (infraction === Infraction.LRI2) return "LRI2";
  if (infraction === Infraction.NCNS) return "NCNS";
  if (infraction === Infraction.Personal1) return "Personal1";
  if (infraction === Infraction.Personal2) return "Personal2";
  if (infraction === Infraction.LE) return "LE";
  else return infraction;
};
export const percentage = (num: number, total: number) => {
  if (total > 0) {
    return ((num / total) * 100).toFixed(0);
  }
  return 0;
};

export const prepareLineChartData = (data: { [key: string]: number }) => {
  const totalSum = Object.values(data).reduce((prev, curr) => prev + curr, 0);
  let lineChartData = {};
  if (totalSum > 0) {
    lineChartData = Object.keys(data).reduce((obj: any, key) => {
      obj[key] = Number(((data[key] / totalSum) * 100).toFixed(0));
      return obj;
    }, {});
  }
  return lineChartData;
};

export const printPDF = (
  Element: JSX.Element,
  filename: string,
  pageSize: number
) => {
  const div = document.createElement("div");
  ReactDOM.createRoot(div).render(Element);
  div.id = "table";
  const pdf = new jsPDF("p", "pt", [900, pageSize]);
  pdf.html(div, {
    callback: function () {
      pdf.save(filename);
    },
  });
};

export const createState = (columns: BarChart) => {
  let obj: ObjectType = {};
  Object.keys(columns).reduce((obj, row) => {
    let value: ObjectType = {};
    TABLEROWNAMES.reduce((value, col) => {
      if (col.name === TableNames.SICK) {
        value[col.name] = columns[row].sick;
        return value;
      }

      if (col.name === TableNames["NCNS/PERSONAL"]) {
        value[TableNames["NCNS/PERSONAL"]] =
          columns[row].ncns + columns[row].personal;

        return value;
      }

      if (col.name.includes("FMLA")) {
        value[col.name] = columns[row].fmla;
        return value;
      }

      if (col.name === TableNames.TARGET) {
        value[col.name] = 9;
        return value;
      }
      if (col.name.includes("Total Absenteeism")) {
        value[col.name] = columns[row].sick + columns[row].fmla;
        return value;
      } else {
        value[col.name] = "";
      }
      return value;
    }, value);
    obj[row] = value;
    return obj;
  }, obj);

  return obj;
};

export const transformData = (data: { [key: string]: ChartData }) => {
  const currInfractionPointsSum = Object.values(
    data.card_box_current_data
  ).reduce((prev, curr) => prev + curr, 0);

  const prevInfractionPointsSum = Object.values(
    data.card_box_previous_data
  ).reduce((prev, curr) => prev + curr, 0);

  const reportItems = {
    current: {
      Sick: percentage(
        data.card_box_current_data.sick,
        currInfractionPointsSum
      ),
      Personal: percentage(
        data.card_box_current_data.personal,
        currInfractionPointsSum
      ),
      FMLA: percentage(
        data.card_box_current_data.fmla,
        currInfractionPointsSum
      ),
      LRI: percentage(data.card_box_current_data.lri, currInfractionPointsSum),
      NCNS: percentage(
        data.card_box_current_data.ncns,
        currInfractionPointsSum
      ),
      Others: percentage(
        data.card_box_current_data.other,
        currInfractionPointsSum
      ),
    },
    previous: {
      Sick: percentage(
        data.card_box_previous_data.sick,
        prevInfractionPointsSum
      ),
      Personal: percentage(
        data.card_box_previous_data.personal,
        prevInfractionPointsSum
      ),
      FMLA: percentage(
        data.card_box_previous_data.fmla,
        prevInfractionPointsSum
      ),
      LRI: percentage(data.card_box_previous_data.lri, prevInfractionPointsSum),
      NCNS: percentage(
        data.card_box_previous_data.ncns,
        prevInfractionPointsSum
      ),
      Others: percentage(
        data.card_box_previous_data.other,
        prevInfractionPointsSum
      ),
    },
  };

  const totalAbsenses = {
    Sick: data.card_box_current_data.sick,
    Personal: data.card_box_current_data.personal,
    FMLA: data.card_box_current_data.fmla,
    LRI: data.card_box_current_data.lri,
    NCNS: data.card_box_current_data.ncns,
    Other: data.card_box_current_data.other,
    LE: data.card_box_current_data.le,
  };
  let barChart: BarChart = {};
  for (let [key, value] of Object.entries(data.bar_chart)) {
    const data = Object.keys(value)
      .filter((key) => key !== "absences")
      .reduce((obj: any, key) => {
        //@ts-ignore
        obj[key] = value[key];
        return obj;
      }, {});
    barChart[key] = data;
  }
  const result = {
    reportItems,
    totalAbsenses,
    barChart,
  };
  return { result };
};

export const getOptionsType = (option: Option) => {
  if (Options.WEEKLY === option.value) return Options.WEEKLY;
  if (Options.YEARLY === option.value) return Options.YEARLY;
  if (Options.MONTHLY === option.value) return Options.MONTHLY;
  if (Options.CUSTOM === option.value) return Options.CUSTOM;
  return Options.WEEKLY;
};

export const downloadExcel = async (
  fileName: string,
  workbook: ExcelJS.Workbook
) => {
  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = fileName;
  a.click();
  URL.revokeObjectURL(url);
};
const _calculateEachEmployeeTotalPoints = (data: Event[]) => {
  let employeesTotalPoints: {
    employeeName: string;
    points: number;
  }[] = [];

  for (let i = 0; i <= data.length - 1; i++) {
    const employeeName = data[i].employeeName;

    let totalPoints = 0;
    if (
      !employeesTotalPoints.find((emp) => emp.employeeName === employeeName)
    ) {
      for (let j = 0; j <= data.length - 1; j++) {
        if (employeeName === data[j].employeeName) {
          totalPoints += Number(data[j].points);
        }
      }
      employeesTotalPoints.push({
        employeeName: employeeName!,
        points: totalPoints,
      });
    }
  }
  return employeesTotalPoints;
};
const _sortEmployeeData = (
  data: { employeeName: string; points: number }[]
) => {
  return data.sort((a, b) => {
    if (a.points !== b.points) {
      // If points are equal, sort by names
      return b.points - a.points;
    } else {
      // Sort by points in ascending order
      return a.employeeName.localeCompare(b.employeeName);
    }
  });
};
export const createAllEmployeeAttendanceReport = async (arg: {
  data: Event[];
  periodType: string | undefined;
  totalPoints: number;
}) => {
  const { periodType, data, totalPoints } = arg;

  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("EmployeeLeaveTracker");
  worksheet.getCell("A1").value = "Employee Leave Tracker";
  worksheet.mergeCells("A1:E1");
  worksheet.getCell("A2").value = "";
  worksheet.mergeCells("A2:E2");
  worksheet.getCell("A3").value = "Period:";
  worksheet.getCell("B3").value = transformDateFormat(periodType);
  worksheet.mergeCells("B3:C3");
  worksheet.mergeCells("B4:C4");
  worksheet.getCell("A4").value = "Total Points:";
  worksheet.getCell("B4").value = totalPoints;
  const headingRow = worksheet.getRow(1);
  headingRow.height = 30;
  const emptyRow = worksheet.getRow(2);
  emptyRow.height = 10;
  const employeesTotalPoints = _calculateEachEmployeeTotalPoints(data);
  const orderedByPoints = _sortEmployeeData(employeesTotalPoints);

  const jsonData = orderedByPoints.map((el) => {
    return {
      "Employee Name": el.employeeName,
      Points: el.points,
    };
  });

  const columns = Object.keys(jsonData[0]).map((col, index) => {
    return {
      name: col,
      filterButton: true,
    };
  });

  worksheet.properties.defaultRowHeight = 60;
  worksheet.properties.defaultColWidth = 25;

  worksheet.addTable({
    name: "Employee Leave Tracker",
    ref: "A5",
    headerRow: true,
    totalsRow: false,

    style: {
      theme: "TableStyleMedium2",
      showFirstColumn: true,
    },
    columns,
    rows: jsonData.map((item) => {
      let data: any = [];
      Object.keys(item).map((key) => {
        //@ts-ignore
        data.push(item[key]);
        return "";
      });
      return data;
    }),
  });

  worksheet.eachRow((row, index) => {
    if (index === 1) {
      row.font = {
        name: "Bookman old style",
        size: 26,
        bold: true,
        color: { argb: "3f454e" },
      };
      row.alignment = {
        vertical: "middle",
        horizontal: "left",
      };
    } else {
      row.font = {
        size: 10,
        name: "Trebuchet MS (Body)",
        color: { argb: "38374f" },
      };
      row.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
    }

    if (index === 5) {
      row.eachCell((cell, cellNumber) => {
        if (
          cellNumber ===
            columns.findIndex((col) => col.name === "Comments") + 1 ||
          cellNumber ===
            columns.findIndex((col) => col.name === "Comments") + 2 ||
          cellNumber === columns.findIndex((col) => col.name === "Comments") + 3
        ) {
          cell.font = {
            color: { argb: "38374f" },
          };
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "ffffff" },
          };
        } else {
          cell.font = {
            color: { argb: "ffffff" },
          };
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "38374f" },
          };
        }
      });
    }
    if (index > 5) {
      if (index % 2 === 1) {
        row.eachCell((cell, cellNumber) => {
          if (
            cellNumber ===
            columns.findIndex((col) => col.name === "Comments") + 1
          ) {
            cell.font = {
              color: { argb: "38374f" },
              bold: false,
            };
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "ffffff" },
            };
          } else {
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "e6e6e6" }, // Set the desired background color
            };
          }
        });
      }
      if (index % 2 !== 1) {
        row.eachCell((cell) => {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "ffffff" }, // Set the desired background color
          };
        });
      }
    }
    row.eachCell((cell, cellNumber) => {
      if (
        cellNumber ===
        columns.findIndex((col) => col.name === "Type of Leave") + 1
      ) {
        const typeOfLeaveValue = cell.text;
        if (typeOfLeaveValue === "Sick") {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "f4aca4" },
          };
        }
        if (typeOfLeaveValue === "Personal") {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "f9a755" },
          };
        }
        if (typeOfLeaveValue === "LE") {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "797ba3" },
          };
        }
        if (typeOfLeaveValue === "LRI") {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "9ed179" },
          };
        }

        if (typeOfLeaveValue === "FMLA") {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "52b9b4" },
          };
        }
        if (typeOfLeaveValue === "NCNS") {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "ff0000" },
          };
        }
        if (typeOfLeaveValue === "Other") {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "f5e166" },
          };
        }
        if (typeOfLeaveValue === "Excused") {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "797ba3" },
          };
        }
      }
    });
    row.eachCell((cell) => {
      cell.border = {
        top: { style: "thin", color: { argb: "ffffff" } },
        left: { style: "thin", color: { argb: "ffffff" } },
        bottom: { style: "thin", color: { argb: "ffffff" } },
        right: { style: "thin", color: { argb: "ffffff" } },
      };
    });
    if (index === 1) {
      row.height = 35; // Set the desired row height
    } else {
      row.height = 30;
    }
  });
  const cellB4 = worksheet.getCell("B4");
  cellB4.fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "611358" },
  };
  cellB4.font = {
    color: { argb: "ffffff" },
    size: 18,
    bold: true,
  };
  const cellB3 = worksheet.getCell("B3");
  cellB3.font = {
    color: { argb: "ffffff" },
    size: 18,
    bold: true,
  };
  cellB3.fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "611358" },
  };

  const cellA3 = worksheet.getCell("A3");
  cellA3.font = {
    size: 18,
    bold: true,
  };
  cellA3.alignment = {
    horizontal: "center",
    vertical: "middle",
  };
  const cellA4 = worksheet.getCell("A4");
  cellA4.font = {
    size: 18,
    bold: true,
  };
  cellA4.alignment = {
    horizontal: "center",
    vertical: "middle",
  };
  const columnD = worksheet.getColumn("E");
  columnD.width = 115;
  columnD.alignment = {
    horizontal: "left",
    vertical: "middle",
  };
  const cellA5 = worksheet.getCell("A5"); //Comments heading
  cellA5.fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "38374f" },
  };
  cellA5.font = {
    color: { argb: "ffffff" },
  };
  const cellB5 = worksheet.getCell("B5"); //Comments heading
  cellB5.fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "38374f" },
  };
  cellB5.font = {
    color: { argb: "ffffff" },
  };

  downloadExcel("Attendance_Report.xlsx", workbook);
};

export const createSelectedEmployeeAttendanceReport = (arg: {
  data: Event[];
  employeeName: string;
  periodType: string | undefined;
  totalPoints: number;
}) => {
  const workbook = new ExcelJS.Workbook();

  const worksheet = workbook.addWorksheet("Employee Attendance Record");
  const { data, employeeName, periodType, totalPoints } = arg;
  worksheet.getCell("A1").value = "Employee Attendance Record";
  worksheet.mergeCells("A1:E1");
  worksheet.getCell("A2").value = "Employee Name:";
  worksheet.getCell("B2").value = employeeName;
  worksheet.getCell("A3").value = "Period:";
  worksheet.getCell("B3").value = transformDateFormat(periodType);
  worksheet.mergeCells("B3:C3");
  worksheet.mergeCells("B4:C4");
  worksheet.getCell("A4").value = "Total Points:";
  worksheet.getCell("B4").value = totalPoints;
  worksheet.mergeCells("B2:C2");
  const headingRow = worksheet.getRow(1);
  headingRow.height = 50;
  console.log("data", data);
  console.log(periodType);
  const formattedData = formatEventsByDate(data);
  const jsonData = formattedData.map((el) => {
    return {
      "Start Date": formatDateUS(el.date),
      "Type of Leave": el.title,
      Points: el.points,
      Comments: el.comment,
      "Points Reduced": el.points_reduced
        ? formatDateUS(el.points_reduced)
        : "",
    };
  });
  // console.log("jsonData", jsonData);
  const columns = Object.keys(jsonData[0]).map((col) => {
    if (col === "Comments") {
      return {
        name: col,
        filterButton: false,
        style: {
          font: {
            name: "Trebuchet MS (Body)",
            size: 10,
          },
          alignment: {
            vertical: "middle",
            horizontal: "left",
          },
          // fill: {
          //   fgcolor: { argb: "ffffff" },
          // },
        },
      };
    }
    return {
      name: col,
      filterButton: true,
    };
  });

  worksheet.properties.defaultRowHeight = 20;
  worksheet.properties.defaultColWidth = 25;

  worksheet.addTable({
    name: "Employee Leave Tracker",
    ref: "A5",
    headerRow: true,
    totalsRow: false,

    style: {
      theme: "TableStyleMedium2",
      showFirstColumn: true,
    },
    columns,
    rows: jsonData.map((item) => {
      let data: any = [];
      Object.keys(item).map((key) => {
        //@ts-ignore
        data.push(item[key]);
        return "";
      });
      return data;
    }),
  });

  worksheet.eachRow((row, index) => {
    if (index === 1) {
      row.font = {
        name: "Bookman old style",
        bold: true,
        size: 26,
        color: { argb: "3f454e" },
      };
    } else {
      row.font = {
        size: 10,
        color: { argb: "38374f" },
        name: "Trebuchet MS (Body)",
      };
    }
    if (index === 3 || index === 4 || index === 2) {
      row.font = {
        name: "Bookman old style",
        bold: true,
        size: 13,
        color: { argb: "3f454e" },
      };
    }
    if (index <= 5) {
      row.alignment = {
        horizontal: "left",
        vertical: "middle",
      };
    } else {
      row.alignment = {
        horizontal: "center",
        vertical: "middle",
      };
    }

    if (index === 5) {
      row.eachCell((cell, cellNumber) => {
        if (
          cellNumber ===
          columns.findIndex((col) => col.name === "Comments") + 1
        ) {
          cell.font = {
            color: { argb: "38374f" },
            bold: false,
          };
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "ffffff" },
          };
        } else {
          cell.font = {
            color: { argb: "ffffff" },
          };
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "38374f" },
          };
        }
      });
    }
    if (index > 5) {
      if (index % 2 === 1) {
        row.eachCell((cell, cellNumber) => {
          if (
            cellNumber ===
            columns.findIndex((col) => col.name === "Comments") + 1
          ) {
            cell.font = {
              color: { argb: "38374f" },
            };
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "ffffff" },
            };
          } else {
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "e6e6e6" }, // Set the desired background color
            };
          }
        });
      }
      if (index % 2 !== 1) {
        row.eachCell((cell) => {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "ffffff" }, // Set the desired background color
          };
        });
      }
    }
    worksheet.eachRow((row, index) => {
      row.eachCell((cell, cellNumber) => {
        if (
          cellNumber ===
          columns.findIndex((col) => col.name === "Type of Leave") + 1
        ) {
          const typeOfLeaveValue = cell.text;
          if (typeOfLeaveValue === "Sick") {
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "f4aca4" },
            };
          }
          if (typeOfLeaveValue === "Personal") {
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "f9a755" },
            };
          }
          if (typeOfLeaveValue === "LE") {
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "797ba3" },
            };
          }
          if (typeOfLeaveValue === "LRI") {
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "9ed179" },
            };
          }

          if (typeOfLeaveValue === "FMLA") {
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "52b9b4" },
            };
          }
          if (typeOfLeaveValue === "NCNS") {
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "ff0000" },
            };
          }
          if (typeOfLeaveValue === "Other") {
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "f5e166" },
            };
          }
          if (typeOfLeaveValue === "Excused") {
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "797ba3" },
            };
          }
        }
      });
      row.eachCell((cell, cellNumber) => {
        cell.border = {
          top: { style: "thin", color: { argb: "ffffff" } },
          left: { style: "thin", color: { argb: "ffffff" } },
          bottom: { style: "thin", color: { argb: "ffffff" } },
          right: { style: "thin", color: { argb: "ffffff" } },
        };
      });
      if (index === 1) {
        row.height = 35; // Set the desired row height
      } else {
        row.height = 30;
      }

      const cellB4 = worksheet.getCell("B4");
      cellB4.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "611358" },
      };
      cellB4.font = {
        color: { argb: "ffffff" },
        size: 18,
        bold: true,
      };
      const cellB2 = worksheet.getCell("B2");
      cellB2.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "611358" },
      };
      cellB2.font = {
        color: { argb: "ffffff" },
        size: 18,
        bold: true,
      };
      const cellB3 = worksheet.getCell("B3");
      cellB3.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "611358" },
      };
      cellB3.font = {
        color: { argb: "ffffff" },
        size: 18,
        bold: true,
      };
    });
    const cellA3 = worksheet.getCell("A3");
    cellA3.alignment = {
      horizontal: "center",
      vertical: "middle",
    };
    const cellA4 = worksheet.getCell("A4");
    cellA4.alignment = {
      horizontal: "center",
      vertical: "middle",
    };
    const cellA2 = worksheet.getCell("A2");
    cellA2.alignment = {
      horizontal: "center",
      vertical: "middle",
    };
    const columnD = worksheet.getColumn("D");
    columnD.width = 65;
    columnD.alignment = {
      horizontal: "left",
      vertical: "middle",
    };
    const cellD5 = worksheet.getCell("D5"); //Comments heading
    cellD5.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "38374f" },
    };
    cellD5.font = {
      color: { argb: "ffffff" },
    };
  });

  downloadExcel(`Attendance_Report_${employeeName}.xlsx`, workbook);
};

const formatEventsByDate = (data: Event[]) => {
  const formattedData = data;
  for (let i = 0; i < formattedData.length - 1; i++) {
    for (let j = i + 1; j < formattedData.length; j++) {
      if (formattedData[i].date > formattedData[j].date) {
        [formattedData[i], formattedData[j]] = [
          formattedData[j],
          formattedData[i],
        ];
      }
    }
  }
  return formattedData;
};
