import LoginForm from "pages/Login/components/LoginForm";
import AuthLayout from "components/layouts/AuthLayout";
export const Login = () => {
  return (
    <AuthLayout>
      <h1 className="text-2xl text-primary mb-4 lg:text-4xl font-bold font-sans xl:mb-6 2xl:mb-10 sm:mb-4 dark:text-white">
        Employee Attendance <br />
        Tracking
      </h1>
      <LoginForm />
    </AuthLayout>
  );
};
