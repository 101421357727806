import logo from "assets/images/logo.png";
import { NoticeSchema } from "types";
import { formatDateUS } from "utils";
import pointsTable from "assets/images/points_table.png";
interface IProps {
  data: NoticeSchema;
}
export const EmployeeNoticePDF: React.FC<IProps> = ({ data }) => {
  const recordOfConvo = (action: {
    "Oral Counseling"?: boolean | undefined;
    "Written Warning"?: boolean | undefined;
    "Sent Home w/out Pay"?: boolean | undefined;
    "Final Written Warning"?: boolean | undefined;
    Other?: boolean | undefined;
    Probation?: boolean | undefined;
    Dismissal?: boolean | undefined;
    "Suspension without pay 1 day"?: boolean | undefined;
    "Suspension without pay 3 days"?: boolean | undefined;
    "Suspension without pay 5 days"?: boolean | undefined;
  }) => {
    if (action["Oral Counseling"]) return "Oral Counseling";
    if (action["Final Written Warning"]) return "Final Written Warning";
    if (action["Written Warning"]) return "Written Warning";
    if (action["Suspension without pay 1 day"])
      return "Suspension without pay 1 day";
    if (action["Suspension without pay 3 days"])
      return "Suspension without pay 3 days";
    if (action["Suspension without pay 5 days"])
      return "Suspension without pay 5 days";
    if (action.Probation) return "Probation";
    if (action["Sent Home w/out Pay"]) return "Sent Home without Pay";
    if (action.Dismissal) return "Dismissal/Termination";
    else return "";
  };
  return (
    <div className="w-[870px] h-[1200px] ml-10 mt-4 mr-4 font-helvetica">
      <div className="flex justify-start items-center gap-x-[100px] m-4 mt-4 font-sans">
        <img src={logo} className="mt-4 w-[230px] object-cover" alt="logo" />
        <h2 className="font-bold text-xl mt-4 font-helvetica">
          EMPLOYEE&ensp;NOTICE&ensp;OF&ensp;DISCIPLINE
        </h2>
      </div>
      <div className="w-[850px]">
        <table className="border-black border-collapse border justify-center items-center ml-6 w-[800px]">
          <colgroup>
            <col width={"120px"} />
            <col width={"120px"} />
            <col width={"120px"} />
          </colgroup>

          <tbody>
            <tr>
              <td className="text-xs border-r border-black h-5 pb-2 font-bold">
                Employee
              </td>
              <td className="text-xs border-r border-black h-5 pb-2 font-bold">
                Work Location
              </td>
              <td className="text-xs border-black h-5 pb-2 font-bold">
                Date of Discipline Action Given
              </td>
            </tr>
            <tr>
              <td className="border-black border-r h-5 pb-1 font-bold">
                {data.employeeName}
              </td>
              <td className="border-black border-r h-5 pb-1 font-bold">
                {data.workLocation}
              </td>
              <td className="border-black h-5 pb-1 font-bold">
                {data.date ? formatDateUS(data.date) : ""}
              </td>
            </tr>
            <tr>
              <td className="text-xs border-t border-r border-black h-5 font-bold">
                Employee ID #{" "}
              </td>
              <td className="text-xs border-t border-r border-black h-5 font-bold">
                Date of Occurrence{" "}
              </td>
              <td className="text-xs border-t border-black h-5 font-bold">
                Supervisor Issuing Action
              </td>
            </tr>
            <tr>
              <td className="border-black border-r h-7 pb-1 font-bold">
                {data.employeeID}
              </td>
              <td className="border-black border-r h-7 pb-1 font-bold">
                {data.dateOfOccurence === "Various"
                  ? data.dateOfOccurence
                  : formatDateUS(data.dateOfOccurence)}{" "}
              </td>
              <td className="border-black h-7 pb-1 font-bold">
                {data.issuedBy} {data.position ? ` - ${data.position}` : ''}
              </td>
            </tr>
          </tbody>
        </table>
        <div className="flex flex-row ml-[22px] w-[700px]">
          <div className="flex flex-col item-start">
            <p className="mt-2 font-bold text-sm">
              <strong>Violation</strong>
            </p>
            <div className="mt-4 text-xs flex flex-row gap-x-4 w-[600px]">
              <div className="mt-1 flex flex-row items-center justify-start">
                <input
                  type="checkbox"
                  id="oral-counseling"
                  name="oral-counseling"
                  readOnly
                  checked
                />
                <label className="ml-2 mt-[-15px]" htmlFor="oral-counseling">
                  Attendance
                </label>
              </div>
              <div className="mt-1 flex flex-row items-center justify-start">
                <input
                  type="checkbox"
                  id="written-warning"
                  name="written-warning"
                  checked
                  readOnly
                />
                <label className="ml-2 mt-[-15px]" htmlFor="written-warning">
                  Violation of Company Policies or Procedures
                  <strong className="text-red font-normal ml-1">
                    (General Order 2020-07 01)
                  </strong>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="ml-[22px] w-[400px]">
          <p className="font-bold text-sm text-red"> PREVIOUS WARNING </p>
          <div className="flex flex-row gap-x-6">
            <div className="mt-4 flex flex-row items-center justify-start">
              <input
                type="checkbox"
                id="oral-counseling"
                name="oral-counseling"
                checked={data.previousWarnings["Oral Warning"].checked}
                readOnly
              />
              <label
                className="ml-2 mt-[-15px] text-xs"
                htmlFor="oral-counseling"
              >
                Oral Counseling
              </label>
            </div>
            <div className="mt-1 flex flex-row items-center justify-start">
              <p className="text-xs ml-[88.4px]">Date: </p>
              <span className="w-[150px] absolute left-[320px] top-[315px] border-b border-black" />
              <p className="text-[10px] ml-2 pb-1 mt-[-5px]">
                {data.previousWarnings["Oral Warning"].date
                  ? formatDateUS(data.previousWarnings["Oral Warning"].date)
                  : ""}
              </p>
            </div>
          </div>

          <div className="flex flex-row gap-x-6">
            <div className="mt-1 flex flex-row items-center justify-start">
              <input
                type="checkbox"
                id="oral-counseling"
                name="oral-counseling"
                readOnly
                checked={data.previousWarnings["Written Warning"].checked}
              />
              <label
                className="ml-2 mt-[-15px] text-xs"
                htmlFor="oral-counseling"
              >
                Written Warning
              </label>
            </div>
            <div className="flex flex-row items-center justify-start">
              <p className="text-xs  mt-[-12px] ml-[89px]">Date:</p>
              <span className="w-[150px] absolute left-[320px] top-[332px] border-b border-black" />
              <p className="text-[10px]  mt-[-14px] ml-2 pb-1 ">
                {data.previousWarnings["Written Warning"].date
                  ? formatDateUS(data.previousWarnings["Written Warning"].date)
                  : ""}
              </p>
            </div>
          </div>
          <div className="flex flex-row gap-x-6">
            <div className="mt-1 flex flex-row items-center justify-start">
              <input
                type="checkbox"
                id="oral-counseling"
                name="oral-counseling"
                checked={data.previousWarnings["Suspension 1 Day"].checked}
                readOnly
              />
              <label
                className="ml-2 mt-[-15px] text-xs"
                htmlFor="oral-counseling"
              >
                Suspension without pay{" "}
                <span className="text-[#bf0101] font-bold">1</span> day
              </label>
            </div>
            <div className="flex flex-row items-center justify-start">
              <p className=" mt-[-13px] text-xs ml-[15px]">Date: </p>
              <span className="w-[150px] absolute left-[320px] top-[349px] border-b border-black" />
              <p className="text-[10px] mt-[-14px] ml-2 pb-1">
                {data.previousWarnings["Suspension 1 Day"].date
                  ? formatDateUS(data.previousWarnings["Suspension 1 Day"].date)
                  : ""}
              </p>
            </div>
          </div>
          <div className="flex flex-row gap-x-6">
            <div className="mt-1 flex flex-row items-center justify-start">
              <input
                type="checkbox"
                id="oral-counseling"
                name="oral-counseling"
                checked={data.previousWarnings["Suspension 3 Days"].checked}
                readOnly
              />
              <label
                className="ml-2 mt-[-13px] text-xs"
                htmlFor="oral-counseling"
              >
                Suspension without pay{" "}
                <span className="text-[#bf0101] font-bold">3</span> days
              </label>
            </div>
            <div className="flex flex-row items-center justify-start">
              <p className="text-xs  mt-[-13px] ml-[9.2px]">Date:</p>
              <span className="w-[150px] absolute left-[320px] top-[367px] border-b border-black" />
              <p className="text-[10px] mt-[-15px] ml-2 pb-1">
                {data.previousWarnings["Suspension 3 Days"].date
                  ? formatDateUS(
                      data.previousWarnings["Suspension 3 Days"].date
                    )
                  : ""}
              </p>
            </div>
          </div>
          <div className="flex flex-row gap-x-6">
            <div className="mt-1 flex flex-row items-center justify-start">
              <input
                type="checkbox"
                id="oral-counseling"
                name="oral-counseling"
                readOnly
                checked={data.previousWarnings["Suspension 5 Days"].checked}
              />
              <label
                className="ml-2 mt-[-15px] text-xs"
                htmlFor="oral-counseling"
              >
                Suspension without pay{" "}
                <span className="text-[#bf0101] font-bold">5</span> days
              </label>
            </div>
            <div className="flex flex-row items-center justify-start">
              <p className="text-xs  mt-[-13px] ml-[9.2px]">Date:</p>
              <span className="w-[150px] absolute left-[320px] top-[384.4px] border-b border-black" />
              <p className="text-[10px] mt-[-10px] ml-2 pb-1">
                {data.previousWarnings["Suspension 5 Days"].date
                  ? formatDateUS(
                      data.previousWarnings["Suspension 5 Days"].date
                    )
                  : ""}
              </p>
            </div>
          </div>
        </div>
        <div className="ml-[22px]">
          <p className="font-bold text-md w-[750px]">
            {" "}
            EXPLANATION&ensp;OF&ensp;EMPLOYEE&ensp;CONDUCT:{" "}
            <strong className="text-sm text-red font-normal">
              (attach any supporting documentation)
            </strong>
          </p>
          <div className="flex flex-col">
            <div className="flex flex-row gap-x-6">
              <div className="flex flex-row items-center justify-start ">
                <p className="text-sm font-bold">Date of Violation: </p>
                <span className="w-[200px] absolute left-[200px] top-[432px] border-b border-black mt-1" />
                <p className="text-xs ml-6">
                  {data.dateOfOccurence === "Various"
                    ? data.dateOfOccurence
                    : formatDateUS(data.dateOfOccurence)}
                </p>
              </div>
            </div>
            <div className="flex flex-row gap-x-6">
              <div className="flex flex-row items-center justify-start">
                <p className="text-sm font-bold">Place of Violation: </p>
                <span className="w-[200px] absolute left-[200px] top-[451px] border-b border-black mt-1" />
                <p className="text-xs ml-6"></p>
              </div>
            </div>
            <div className="flex flex-row gap-x-6">
              <div className="flex flex-row items-center justify-start">
                <p className="text-sm font-bold">Time of Violation:</p>
                <span className="w-[200px] absolute left-[200px] top-[470px] border-b border-black mt-1" />
                <p className="text-xs ml-6"></p>
              </div>
            </div>
          </div>
          <div className="flex flex-col">
            <p className="text-sm font-bold">Statment of Violation: </p>
            <p className="text-sm mr-4">
              Operator {data.employeeName}, you have incurred{" "}
              <strong className="text-[#bf0101]">
                {data.infractionPoints}
              </strong>{" "}
              points/incidents of unscheduled and unauthorized absence (meaning
              an absence not protected or approved) in this rolling calendar
              year (twelve-month period). Please refer to General order 2020-07
              01 Attendance policy dated September 2020.
            </p>
          </div>

          <div className="mt-4 flex flex-col">
            <p className="text-md font-bold">
              RECORD&ensp;&ensp;OF&ensp;&ensp;CONVERSATION:
            </p>
            <p className="text-sm mr-4">
              Absenteeism has a negative impact on the ability of RATP Dev to
              provide consistent, reliable service to our customers, <br /> as
              well as your fellow co-workers. The goal is to correct your
              pattern of absence, the accumulation of additional points will
              result in progressive discipline up to and including termination.
              &nbsp;
              <strong className="text-[#bf0101]">
                Disciplinary Action - &#91;{recordOfConvo(data.typeOfAction)}
                &#93;.&ensp;
                {data.recordOfConvo}
              </strong>
            </p>
          </div>
          <div>
            <p className="mt-4 text-md font-bold">Disciplinary Action </p>
            <div className="flex flex-row gap-x-2 justify-between mt-2 w-[710px]">
              <div className="mt-1 flex flex-row items-center justify-start">
                <input
                  type="checkbox"
                  id="oral-counseling"
                  name="oral-counseling"
                  readOnly
                  checked={data.typeOfAction["Oral Counseling"]}
                />
                <label
                  className="ml-2 mt-[-15px] text-sm"
                  htmlFor="oral-counseling"
                >
                  Oral Counseling
                </label>
              </div>
              <div className="mt-1 ml-[5px] flex flex-row items-center justify-start">
                <input
                  type="checkbox"
                  id="oral-counseling"
                  checked={data.typeOfAction["Written Warning"]}
                  name="oral-counseling"
                  readOnly
                />
                <label
                  className="ml-2 mt-[-15px] text-sm"
                  htmlFor="oral-counseling"
                >
                  Written Warning
                </label>
              </div>
              <div className="mt-1 ml-[0px] flex flex-row items-center justify-start">
                <input
                  type="checkbox"
                  id="oral-counseling"
                  name="oral-counseling"
                  checked={data.typeOfAction["Suspension without pay 1 day"]}
                  readOnly
                />
                <label
                  className="ml-2 mt-[-15px] text-sm"
                  htmlFor="oral-counseling"
                >
                  Suspension without pay{" "}
                  <span className="text-[#bf0101] font-bold">1</span> day
                  <span className="opacity-0">s</span>
                </label>
              </div>
            </div>
            <div className="flex flex-row gap-x-2 justify-between mt-2 w-[710px]">
              <div className="flex flex-row items-center justify-start">
                <input
                  type="checkbox"
                  id="oral-counseling"
                  name="oral-counseling"
                  checked={data.typeOfAction["Final Written Warning"]}
                  readOnly
                />
                <label
                  className="ml-2 mt-[-15px] text-sm"
                  htmlFor="oral-counseling"
                >
                  Final Written Warning
                </label>
              </div>
              <div className="ml-[22px] flex flex-row items-center justify-start">
                <input
                  type="checkbox"
                  id="oral-counseling"
                  name="oral-counseling"
                  readOnly
                  checked={false}
                />
                <label
                  className="ml-2 mt-[-15px] text-sm"
                  htmlFor="oral-counseling"
                >
                  Sent Home w/out Pay
                </label>
              </div>
              <div className="ml-[15px] flex flex-row items-center justify-start">
                <input
                  type="checkbox"
                  id="oral-counseling"
                  name="oral-counseling"
                  checked={data.typeOfAction["Suspension without pay 3 days"]}
                  readOnly
                />
                <label
                  className="ml-2 mt-[-15px] text-sm"
                  htmlFor="oral-counseling"
                >
                  Suspension without pay{" "}
                  <span className="text-[#bf0101] font-bold">3</span> days
                </label>
              </div>
            </div>
            <div className="flex flex-row gap-x-2 justify-between mt-2 w-[710px]">
              <div className="flex flex-row items-center justify-start">
                <input
                  type="checkbox"
                  id="oral-counseling"
                  name="oral-counseling"
                  readOnly
                  checked={data.typeOfAction.Dismissal}
                />
                <label
                  className="ml-2 mt-[-15px] text-sm"
                  htmlFor="oral-counseling"
                >
                  Dismissal/Termination
                </label>
              </div>
              <div className="ml-[-90px] flex flex-row items-center justify-start">
                <input
                  type="checkbox"
                  id="oral-counseling"
                  name="oral-counseling"
                  checked={false}
                  readOnly
                />
                <label
                  className="ml-2 mt-[-15px] text-sm"
                  htmlFor="oral-counseling"
                >
                  Other
                </label>
              </div>
              <div className="ml-1 flex flex-row items-center justify-start">
                <input
                  type="checkbox"
                  id="oral-counseling"
                  name="oral-counseling"
                  readOnly
                  checked={data.typeOfAction["Suspension without pay 5 days"]}
                />
                <label
                  className="ml-2 mt-[-15px] text-sm"
                  htmlFor="oral-counseling"
                >
                  Suspension without pay{" "}
                  <span className="text-[#bf0101] font-bold">5</span> days
                </label>
              </div>
            </div>
          </div>
          <p className="text-sm font-bold mr-4 mt-4 mb-3">
            Collective Bargaining Agreement (CBA) Article 15: Section 2, which
            states in part, “The Company reserves the right to skip steps
            entirely for more serious infractions.”
          </p>
          <p className="text-md font-bold mt-2">
            Corrective Actions to be Taken
          </p>
          <p className="text-sm mr-4 mt-1 mb-3">
            Punctual and regular attendance is an essential responsibility of
            each employee at RATP Dev. Employees are expected to report to work
            as scheduled, on time and prepared to start working.
          </p>
          <span className="block mt-[100px] text-sm mb-[80px]">Page | 1 </span>
          <div className="flex justify-start items-center gap-x-[100px] m-4 mt-9 font-sans">
            <img
              src={logo}
              className="mt-4 w-[230px] object-cover"
              alt="logo"
            />
            <h2 className="font-bold text-xl mt-4 font-helvetica">
              EMPLOYEE&ensp;NOTICE&ensp;OF&ensp;DISCIPLINE
            </h2>
          </div>
          <p className="mt-6">
            It is expected that you will immediately improve your attendance to
            meet company attendance policy standards. A copy of the attendance
            policy is attached, please read the policy thoroughly, and stop by
            if you have any questions about the policy.
          </p>
          <p className="text-sm font-bold mr-4 mt-4 mb-10">
            Failure to take corrective measures to avoid future occurrence or
            offenses of this nature may result in further disciplinary action up
            to and including dismissal.
          </p>
          <div className="mt-4 flex flex-row items-center justify-start">
            <input
              type="checkbox"
              id="oral-counseling"
              name="oral-counseling"
              readOnly
            />
            <label
              className="ml-2 mt-[-15px] text-sm"
              htmlFor="oral-counseling"
            >
              I have read this Notice of Discipline and understand it.
            </label>
          </div>
          <div className="mt-1 flex flex-row items-center justify-start">
            <input
              type="checkbox"
              id="oral-counseling"
              name="oral-counseling"
              readOnly
            />
            <label
              className="ml-2 mt-[-15px] text-sm"
              htmlFor="oral-counseling"
            >
              Employees refused to sign this form and all attached
              documentation.
            </label>
          </div>
          <div className="flex flex-col gap-y-[60px] mt-8">
            <div className="flex flex-row gap-x-2 mr-4 w-[700px] relative">
              <p>Employee’s Signature</p>
              <img
                className="ml-2 mt-[-8px] w-[80px] object-cover"
                alt="emp-sign"
                src={data.employeeSign}
              />
              <span className="w-[250px] absolute left-[160px] top-[27px] border-b border-black" />
              <p className="ml-[190px]">Date:</p>
              <p className="ml-2 text-sm">
                {data.employeeSignDate
                  ? formatDateUS(data.employeeSignDate)
                  : ""}
              </p>
              <span className="w-[150px] absolute left-[490px] top-[25px] border-b border-black" />
              <p className="text-xs text-red absolute left-0 top-[22px]">
                {" "}
                Employee's signature does not indicate agreement or consent to
                discipline.
              </p>
            </div>

            <div className="flex flex-row gap-x-2 mr-4 w-[700px] relative">
              <p>Union Representative</p>
              <img
                className="ml-2 mt-[-23px] w-[100px] object-cover"
                alt="emp-sign"
                src={data.unionRepSign}
              />
              <span className="w-[250px] absolute left-[160px] top-[27px] border-b border-black" />
              <p className="ml-[170px] ">Date:</p>
              <p className="ml-2 text-sm">
                {data.unionRepSignDate
                  ? formatDateUS(data.unionRepSignDate)
                  : ""}
              </p>
              <span className="w-[150px] absolute left-[488px] top-[25px] border-b border-black " />
              <p className="text-xs text-red absolute left-0 top-[22px]">
                {" "}
                Signature indicates presence during the meeting.
              </p>
            </div>

            <div className="relative w-[700px] flex justify-between items-center">
              <div className="flex flex-col gap-y-[2px] relative mt-[22px]">
                <img
                  className="w-[100px] object-contain absolute left-0 top-[-50px]"
                  alt="emp-sign"
                  src={data.supervisorSign}
                />
                <span className="w-[220px] border-b border-black" />
                <p className="text-base leading-[0px]">{data.issuedBy}</p>
              </div>
              <div
                className={`flex flex-col gap-y-[2px] ${
                  data.supervisorSignDate ? "mt-0" : "mt-5"
                }`}
              >
                <p className="text-xs leading-5">
                  {data.supervisorSignDate
                    ? formatDateUS(data.supervisorSignDate)
                    : ""}
                </p>
                <span className="w-[120px] border-b border-black" />
                <p className="text-base leading-[0px]">Date</p>
              </div>
              <div className="flex flex-col gap-y-[2px] ">
                <p className="text-xs leading-5">{data.position}</p>
                <span className="w-[150px] border-b border-black mt-[17.8px] " />
                <p className="text-base leading-[0px]">Position</p>
              </div>
            </div>
          </div>
          <hr className="w-full border-black border-2 mt-10" />
          <hr className="w-full border-black border mt-1 mb-1" />
          <p className="text-sm mt-1">
            The above disciplinary action has been noted and this form will be
            made part of the above employee’s File.
          </p>
        </div>

        <div className="mr-4 ml-[22px] mt-3">
          <p className="text-sm font-bold">Points Structure</p>
          <ul className="text-sm">
            <li className="text-xs">
              {" "}
              Late for Scheduled Shift,two (2) minutes and worked –
              <strong>1 point</strong>
            </li>
            <li className="text-xs">
              {" "}
              Late for Scheduled Shift,two (2) minutes and No run assigned –
              <strong>2 points</strong>
            </li>
            <li className="text-xs">
              {" "}
              Failure to complete entire shift –<strong>2 points</strong>
            </li>
            <li className="text-xs">
              {" "}
              No call/ No show (NCNS) –<strong>4 point</strong>
            </li>
            <li className="text-xs">
              {" "}
              Absence for sickness/illness with
              <strong>minimum 1 hours’ notice,</strong>each day is 1 additional
              point, Max 3 points (Dr's note required after 3 days)
            </li>
            <li className="text-xs">
              {" "}
              Absence for sickness/illness with less than 1 hours’ notice, each
              day is 1 additional point, Max 3 points (Dr's note required after
              3 days) – <strong> 2 points</strong>
            </li>
            <li className="text-xs">
              {" "}
              30 Calendar days with no infractions –{" "}
              <strong>1 point deducted</strong>
            </li>
          </ul>
          <p className="text-sm ml-4 font-bold item-center justify-center flex mt-2">
            Discipline Structure – No notice will be provided after the 6th
            point
          </p>
          <img
            src={pointsTable}
            alt="pointTable"
            className="mt-2 ml-[210px] flex items-center justify-center w-[400px] object-cover"
          />
          <span className="block mt-[110px] text-sm">Page | 2 </span>
        </div>
      </div>
    </div>
  );
};
