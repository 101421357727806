import React, { useState, useEffect, useRef } from "react";
import { Options, Option } from "types";
import arrowIcon from "assets/images/arrow__icon.png";
import Datepicker from "react-tailwindcss-datepicker";
import { capatalize, formatDateUS } from "utils";
import { DateValueType } from "react-tailwindcss-datepicker/dist/types";
import moment from "moment";
interface IProps {
  className?: string;
  options: Option[];
  selected: Option;
  onSelect: (x: Option) => void;
  customDate: boolean;
}

const Select: React.FC<IProps> = ({
  className,
  options,
  onSelect,
  selected,
  customDate,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef<HTMLDivElement>(null);

  //for custom date
  const [date, setDate] = useState<DateValueType>(null);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const handleOutsideClick = (event: any) => {
    if (selectRef.current && !selectRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  //custom date handler
  const handleDateChange = (date: DateValueType) => {
    if (date?.startDate && date.endDate) {
      const { startDate, endDate } = date;
      setDate(date);
      const customDateOption: Option = {
        value: Options.CUSTOM,
        label: JSON.stringify({
          startDate,
          endDate,
        }),
      };
      onSelect(customDateOption);
      setIsOpen(false);
    } else return;
  };
  useEffect(() => {
    if (selected.value !== Options.CUSTOM) setDate(null);
  }, [selected]);
  return (
    <div
      ref={selectRef}
      className={[
        `relative  rounded-md  ${
          isOpen && "rounded-b-none"
        } border-[1px] border-main min-w-[150px] sm:min-w-[197px] shadow-2xl  dark:bg-darkinput dark:border-darkinput cursor-pointer `,
        className,
      ].join(" ")}
    >
      <div
        className="flex w-full  justify-between items-center sm:px-5 px-3 py-[10px]"
        onClick={toggleDropdown}
      >
        <label className="font-sans font-base sm:text-[18px] text-[12px]  font-medium text-black dark:text-white cursor-pointer">
          {selected.value === Options.CUSTOM
            ? formatDateUS(JSON.parse(selected.label).startDate) +
              "-" +
              formatDateUS(JSON.parse(selected.label).endDate)
            : capatalize(selected.label)}
        </label>
        <img
          src={arrowIcon}
          alt="dropdown-icon"
          className={`w-6 h-6 object-cover${
            isOpen
              ? "rotate-180 transition-all ease-in duration-250 dark:brightness-[100]"
              : "rotate-0 transition-all ease-out duration-250 dark:brightness-[100]"
          }`}
        />
      </div>

      {isOpen && (
        <div
          className={
            "rounded-b-md absolute bg-white border border-main w-full  dark:border-b-white z-10"
          }
        >
          {" "}
          <ul className="list-none">
            {options
              .filter((item) => item.value !== selected.value)
              .map((item, index) => {
                return (
                  <li
                    key={index}
                    className={`font-sans font-base font-medium text-black  px-4 py-2  border-b-main ${"border-b-[1px]"} dark:text-white dark:bg-darkinput dark:border-darkinput  dark:border-b-white cursor-pointer`}
                    role="menuitem"
                    onClick={() => {
                      onSelect(item);
                      setIsOpen(false);
                    }}
                  >
                    {typeof item.value === "string"
                      ? capatalize(item.value)
                      : item.value}
                  </li>
                );
              })}
            {customDate && (
              <li className="dark:bg-darkinput dark:border-darkinput border-b-main px-4 datepicker-range-select">
                <Datepicker
                  value={date}
                  onChange={handleDateChange}
                  placeholder={"Custom Date"}
                  minDate={moment("2022-01-01").toDate()}
                  maxDate={moment().toDate()}
                  displayFormat={"MM/DD/YYYY"}
                  showFooter={true}
                  startWeekOn={"sun"}
                  showShortcuts={true}
                  inputClassName={
                    "focus:outline-none focus:border-none focus:shadow-none dark:text-white dark:bg-darkinput dark:border-darkinput dark:placeholder:text-white placeholder:text-black placeholder:font-sans placeholder:font-medium ml-[-13px]"
                  }
                  configs={{
                    footer: {
                      cancel: "Cancel",
                      apply: "Save",
                    },
                  }}
                />
              </li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Select;
