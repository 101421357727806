/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import WrapperLayout from "components/layouts/AppLayout";
import Container from "components/Container/Container";
import Heading from "components/Heading/Heading";
import Select from "components/Select/Select";
import Table from "pages/Reports/components/Table";
import Loader from "components/Loader/Loader";
import Error from "components/Error/Error";
import { OPTIONS } from "constants/index";
import { Department, Option, Options } from "types";
import { getCurrentMonth, getCurrentWeek, getCurrentYear } from "utils";
import { useDashboard } from "hooks/useDashboard";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import { DashboardSlice } from "redux/features/dashboard/dashboardSlice";

export const Reports = () => {
  const [selectedOption, setSelectedOption] = useState<Option>(OPTIONS[0]);
  const { getChartData, chartDataState } = useDashboard();

  const { data } = useSelector<RootState, DashboardSlice>(
    (state) => state.dashboard
  );

  //HANDLERS
  const fetchDashboardData = () => {
    if (selectedOption.label === Options.WEEKLY) {
      getChartData(Options.WEEKLY, getCurrentWeek(), Department.operations);
    }
    if (selectedOption.label === Options.MONTHLY) {
      const monthly = getCurrentMonth() + "," + getCurrentYear();
      getChartData(Options.MONTHLY, monthly, Department.operations);
    }
    if (selectedOption.label === Options.YEARLY) {
      getChartData(Options.YEARLY, getCurrentYear(), Department.operations);
    }
    if (selectedOption.value === Options.CUSTOM) {
      const { startDate, endDate } = JSON.parse(selectedOption.label);
      getChartData(
        Options.CUSTOM,
        `${startDate},${endDate}`,
        Department.operations
      );
    }
  };
  //HANDLER
  const handleSelect = useCallback((item: Option) => {
    setSelectedOption(item);
  }, []);

  //EFFECTS
  useEffect(() => {
    fetchDashboardData();
  }, [selectedOption]);
  return (
    <WrapperLayout>
      <div className="flex flex-col gap-y-5 xl:flex-row items-start justify-between mt-6 gap-x-6">
        <div className="flex flex-col items-start justify-between gap-y-5  w-full">
          <Container className="px-8 flex justify-between items-center w-full h-[119px]">
            <Heading title="Reports" />
            <Select
              className="w-auto"
              options={OPTIONS}
              selected={selectedOption}
              onSelect={handleSelect}
              customDate={true}
            />
          </Container>
          <Container
            className={`card py-10 px-8 flex flex-col items-start w-full overflow-y-auto ${
              (chartDataState.isLoading || chartDataState.isError) &&
              "min-h-[500px]"
            }`}
          >
            {chartDataState.isLoading ? (
              <Loader className="bg-dark dark:bg-white" />
            ) : chartDataState.isError ? (
              <Error
                message="Something went wrong. Please try again"
                onClick={() => handleSelect(selectedOption)}
              />
            ) : (
              <Table columns={data.barChart} />
            )}
          </Container>
        </div>
      </div>
    </WrapperLayout>
  );
};
