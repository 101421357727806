import React, { useEffect, useState, useRef } from "react";
import arrowIcon from "assets/images/arrow__icon.png";
import { Option } from "types";
import { capatalize } from "utils";

interface IProps {
  label: string;
  options: Option[];
  onClick: (x: string) => void;
  className?: string;
  style?: React.CSSProperties;
  value: string;
}

const Select2: React.FC<IProps> = ({
  label,
  className,
  options,
  style,
  value,
  onClick,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef<HTMLDivElement>(null);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOutsideClick = (event: any) => {
    if (selectRef.current && !selectRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);
  return (
    <div
      ref={selectRef}
      className={["flex flex-col cursor-pointer", className].join(" ")}
      style={style}
    >
      <label className="text-sm font-bold font-sans text-main mb-[19px] dark:text-white cursor-pointer">
        {label}
      </label>
      <div
        className={[
          `relative ${
            isOpen
              ? "rounded-md rounded-b-none border-[1px] border-b-0"
              : "rounded-md border-[1px]"
          } py-4 px-5 w-full border-[#c1c1c1] border-solid  dark:bg-darkinput dark:border-darkinput  `,
        ].join(" ")}
      >
        <div
          className="flex w-full justify-between items-center  dark:bg-darkinput  "
          onClick={toggleDropdown}
        >
          <label className="text-black font-sans font-bold text-base  dark:text-white max-w-[90%] overflow-hidden text-ellipsis whitespace-nowrap">
            {capatalize(value)}
          </label>
          <img
            src={arrowIcon}
            alt="dropdown-icon"
            className={`w-6 h-6 object-cover dark:invert-[1] ${
              isOpen
                ? "rotate-180 transition-all ease-in duration-250"
                : "rotate-0 transition-all ease-out duration-250"
            }`}
          />
        </div>

        {isOpen && (
          <ul className="list-none rounded-b-md absolute bg-white border border-[#c1c1c1] w-full top-[56px] left-0 z-10 dark:bg-darkinput">
            {options
              .filter((item) => item.label !== value)
              .map((item, index) => {
                return (
                  <li
                    key={index}
                    className={`cursor-pointer font-sans font-base font-medium text-black px-4 py-2 dark:text-white  border-b-[#c1c1c1] ${
                      index === options.length - 1
                        ? "border-b-0"
                        : "border-b-[1px]"
                    }`}
                    role="menuitem"
                    onClick={() => {
                      onClick(item.label);
                      toggleDropdown();
                    }}
                  >
                    {capatalize(item.label)}
                  </li>
                );
              })}
          </ul>
        )}
      </div>
    </div>
  );
};

export default Select2;
