import { ChartData } from "types";

interface IProps {
  className?: string;
  data: ChartData;
  background: string[];
}

const ChartList: React.FC<IProps> = ({ data, className, background }) => {
  return (
    <div className={[`flex flex-wrap gap-5 mt-14 `, className].join(" ")}>
      {Object.keys(data).map((item, index) => (
        <span className="group flex flex-row gap-x-2 " key={index}>
          <span
            className={`w-[16px] h-[16px] rounded-full mt-1  dark:text-white`}
            style={{ background: `${background?.[index] ?? "transparent"}` }}
          />
          <p>
            <strong className="dark:text-white">{`${data[item]}`}</strong>
          </p>
          <p className="dark:text-white"> {`${item}`}</p>
        </span>
      ))}
    </div>
  );
};

export default ChartList;
