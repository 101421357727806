import React from "react";

interface IProps {
  title: string;
  className?: string;
}

const Heading: React.FC<IProps> = ({ title, className }) => {
  return (
    <h1
      className={[
        "font-sans font-bold text-primary dark:text-white text-2xl sm:text-3xl",
        className,
      ].join(" ")}
    >
      {title}
    </h1>
  );
};

export default Heading;
