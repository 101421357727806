import React from "react";
import styles from "./styles.module.css";
interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
}

const Checkbox: React.FC<IProps> = ({
  id,
  name,
  label,
  checked,
  className,
  onChange,
  value,
}) => {
  return (
    <label
      className={["flex items-center gap-x-3 relative", className].join(" ")}
      htmlFor={id}
    >
      <input
        type="checkbox"
        id={id}
        checked={checked}
        name={name}
        className="hidden"
        onChange={onChange}
        value={value}
      />
      <span
        className={[
          "w-5 h-5 rounded-[3px] bg-light dark:bg-darkinput relative",
          styles.checkmark,
        ].join(" ")}
      />
      <span className="font-sans text-primary dark:text-white font-semibold text-lg">
        {label}
      </span>
    </label>
  );
};

export default Checkbox;
