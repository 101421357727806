import dashboardIcon from "assets/images/dashboard__icon.png";
import callLogIcon from "assets/images/call_log__icon.png";
import profileIcon from "assets/images/profile__icon.png";
import reportCardIcon from "assets/images/reportcard__icon.png";
import teamIcon from "assets/images/team__icon.png";
import reportsIcon from "assets/images/reports__icon.png";

import {
  Data,
  Disciplinary,
  Infraction,
  Option,
  Columns,
  LoginSchema,
  Admin,
  CallLogSchema,
  ForgetPasswordSchema,
  ResetPasswordSchema,
  CreateEmpSchema,
  CreateSupvSchema,
  NoticeSchema,
  Department,
  InfractionNoticeSchema,
  TableNames,
  Options,
} from "types";
import { Column } from "react-table";

export const PATHS = {
  HOME: "/home",
  LOGIN: "/login",
  FORGOTPASSWORD: "/forgotpassword",
  RESETPASSWORD: "/password/reset/confirm/:uid/:token",
  DEFAULT: "*",
  CALLOUTLOG: "/calloutlog",
  EMPLOYEEMANAGEMENT: "/employeemanagement",
  UPDATEEMPLOYEE: "/updateemployee",
  FORM: "/form",
  ATTENDANCEREPORT: "/attendancereport",
  TEAM: "/team",
  REPORTS: "/reports",
};

export const NAVITEMS = [
  {
    title: "Dashboard",
    icon: dashboardIcon,
    to: PATHS.HOME,
  },
  {
    title: "Call out log",
    icon: callLogIcon,
    to: PATHS.CALLOUTLOG,
  },
  {
    title: "Employee Management",
    icon: profileIcon,
    to: PATHS.EMPLOYEEMANAGEMENT,
  },
  {
    title: "Attendance Report",
    icon: reportCardIcon,
    to: PATHS.ATTENDANCEREPORT,
  },
  {
    title: "Team",
    icon: teamIcon,
    to: PATHS.TEAM,
  },
  {
    title: "Reports",
    icon: reportsIcon,
    to: PATHS.REPORTS,
  },
];

export const OPTIONS: Option[] = [
  {
    value: Options.WEEKLY,
    label: "Weekly",
  },
  {
    value: Options.MONTHLY,
    label: "This Month",
  },
  {
    value: Options.YEARLY,
    label: "This Year",
  },
];
export const ARFILTEROPTIONS: Option[] = [
  {
    value: Options.ALL,
    label: Options.ALL,
  },
  {
    value: Options.SEARCH,
    label: Options.SEARCH,
  },
];

export const PRINTDATA: Data = {
  title: "Click <strong>'Print'</strong> to download Disciplinary Form",
  action: "Print",
};

export const UPLOADEDDOCUMENTSDATA: Data = {
  title: "Click <strong>'Upload'</strong> to upload Disciplinary Form",
  action: "Upload",
};

export const INFRACTIONOPTIONS: Option[] = [
  {
    label: Infraction.Sick1,
    value: 1,
  },
  {
    label: Infraction.Sick2,
    value: 2,
  },

  {
    label: Infraction.Personal1,
    value: 1,
  },
  {
    label: Infraction.Personal2,
    value: 2,
  },
  {
    label: Infraction.LRI1,
    value: 1,
  },
  {
    label: Infraction.LRI2,
    value: 2,
  },
  {
    label: Infraction.LE,
    value: 2,
  },
  {
    label: Infraction.NCNS,
    value: 4,
  },
  {
    label: Infraction.FMLA,
    value: 0,
  },
];
export const INFRACTIONOPTIONSACTION = [
  {
    label: Infraction.Sick1,
    value: 1,
  },
  {
    label: Infraction.Sick2,
    value: 2,
  },

  {
    label: Infraction.Personal1,
    value: 1,
  },
  {
    label: Infraction.Personal2,
    value: 2,
  },
  {
    label: Infraction.LRI1,
    value: 1,
  },
  {
    label: Infraction.LRI2,
    value: 2,
  },
  {
    label: Infraction.LE,
    value: 2,
  },
  {
    label: Infraction.NCNS,
    value: 4,
  },
];

export const DISCIPLINARYOPTIONS: Option[] = [
  {
    label: "No Action",
    value: 0,
  },
  {
    label: "Oral Warning",
    value: 7,
  },
  {
    label: "Written Warning",
    value: 8,
  },
  {
    label: "Written Warning/ 1-day suspension",
    value: 9,
  },
  {
    label: "Written Warning/ 3-day suspension",
    value: 10,
  },
  {
    label: "Written Warning/ 5-day suspension",
    value: 11,
  },
  {
    label: "Termination",
    value: 12,
  },
];
export const ADMINOPTIONS: Option[] = [
  { label: "Supervisor", value: "Supervisor" },
  { label: "Employee", value: "Employee" },
];
export const DEPARTMENTOPTIONS: Option[] = [
  { label: "operations", value: "operations" },
  { label: "maintenance", value: "maintenance" },
  { label: "both", value: "both" },
];
export const FORMOPTIONS: Option[] = [
  { label: "Disciplinary Form", value: "Disciplinary Form" },
  { label: "Infraction Notice Form", value: "Infraction Notice Form" },
];
export const COLUMNS: Column<Columns>[] = [
  {
    Header: "#",
    accessor: "col1" as keyof Columns, // accessor is the "key" in the data
  },
  {
    Header: "Name",
    accessor: "col2" as keyof Columns, // accessor is the "key" in the data
  },
  {
    Header: "Employee ID",
    accessor: "col3" as keyof Columns, // accessor is the "key" in the data
  },
  {
    Header: "Department",
    accessor: "col4" as keyof Columns, // accessor is the "key" in the data
  },
  {
    Header: "Role",
    accessor: "col5" as keyof Columns, // accessor is the "key" in the data
  },
  {
    Header: "Designation",
    accessor: "col6" as keyof Columns, // accessor is the "key" in the data
  },
  {
    Header: "Active",
    accessor: "col7" as keyof Columns, // accessor is the "key" in the data
  },
];
export const FILESCOLUMNS: Column<Columns>[] = [
  {
    Header: "Date",
    accessor: "col1" as keyof Columns, // accessor is the "key" in the data
  },
  {
    Header: "File",
    accessor: "col2" as keyof Columns, // accessor is the "key" in the data
  },
];

export const LOGININITIALVALUES: LoginSchema = {
  email: "",
  password: "",
};
export const FROGETPASSWORDVALUES: ForgetPasswordSchema = {
  email: "",
};
export const RESETPASSWORDVALUES: ResetPasswordSchema = {
  password1: "",
  password2: "",
};

export const CALLLOGINITIALVALUES: CallLogSchema = {
  employeeID: "",
  employeeName: "",
  infractionType: Infraction.Sick1,
  disciplinaryAction: Disciplinary["No Action"],
  date: "",
  comment: "",
};
export const INFRACTNOTICEINITIALVALUES: InfractionNoticeSchema = {
  employeeName: "",
  date: "",
  typeofAbsence0: "",
  typeofAbsence1: "",
  typeofAbsence2: "",
  typeofAbsence3: "",
  dateofAbsence0: "",
  dateofAbsence1: "",
  dateofAbsence2: "",
  dateofAbsence3: "",
  startDate:"",
  endDate:"",
  comment: "",
  previousInfractions: [],
  disagree: false,
};
export const CREATEEMPINITIALVALUES: CreateEmpSchema = {
  employeeID: "",
  email: "",
  employeeName: "",
  workLocation: "8401 Darcy Road, Forestville, Maryland 20747",
  deptName: Department.operations,
  admin: Admin.Employee,
  subRole: [],
  status: true,
};
export const CREATESUPVINITIALVALUES: CreateSupvSchema = {
  employeeID: "",
  email: "",
  employeeName: "",
  designation: "",
  workLocation: "8401 Darcy Road, Forestville, Maryland 20747",
  deptName: Department.operations,
  admin: Admin.Employee,
  subRole: [],
  status: true,
};
export const NOTICEINITIALVALES: NoticeSchema = {
  employeeName: "",
  employeeID: "",
  workLocation: "RATP/Dev – 8401 Darcy Road, Forestville MD, 20747",
  employeeDepartment: Department.operations,
  date: "",
  dateOfOccurence: "",
  issuedBy: "",
  recordOfConvo: "",
  typeOfAction: {
    "Oral Counseling": false,
    "Written Warning": false,
    "Final Written Warning": false,
    Probation: false,
    Dismissal: false,
    "Suspension without pay 1 day": false,
    "Suspension without pay 3 days": false,
    "Suspension without pay 5 days": false,
  },

  previousWarnings: {
    "Oral Warning": {
      checked: false,
      date: "",
    },
    "Written Warning": {
      checked: false,
      date: "",
    },
    "Suspension 1 Day": {
      checked: false,
      date: "",
    },
    "Suspension 3 Days": {
      checked: false,
      date: "",
    },
    "Suspension 5 Days": {
      checked: false,
      date: "",
    },
  },
  employeerDisagree: false,
  employeeSign: "",
  employeeSignDate: "",
  supervisorSign: "",
  supervisorSignDate: "",
  unionRepSign: "",
  unionRepSignDate: "",
  infractionPoints: 0,
};

export const CHARTCOLORS: { [key: string]: { [key: string]: string } } = {
  light: {
    //Do not change the order.
    Sick: "#f4aca4",
    FMLA: "#52b9b4",
    Other: "#f5e166",
    LRI: "#9ed179",
    NCNS: "#ff0000",
    Personal: "#f9a755",
    LE: "#797ba3",
    Excused: "#797ba3",
    "Points Reduced": "#9ed179",
  },
  dark: {
    Sick: "#B04C41",
    FMLA: "#9A5715",
    Other: "#006C66",
    LRI: "#52b9b4",
    Personal: "#50882E",
    NCNS: "#FF0000",
    LE: "#A58D00",
    Excused: "#797ba3",
    "Points Reduced": "#52b9b4",
  },
};

export const INDICATORSCOLORS: { [key: string]: { [key: string]: string } } = {
  "Points Reduced": {
    lightBgColor1: "#7030A0",
    lightBgColor2: "#C4FF9F",
    darkBgColor1: "#7030A0",
    darkBgColor2: "#50882E",
  },
  workingDays: {
    lightBgColor1: "#7030A0",
    lightBgColor2: "#C4FF9F",
    darkBgColor1: "#7030A0",
    darkBgColor2: "#50882E",
  },
  Sick: {
    lightBgColor1: CHARTCOLORS.light.Sick,
    lightBgColor2: "#C4FF9F",
    darkBgColor1: CHARTCOLORS.dark.Sick,
    darkBgColor2: "#50882E",
  },
  Personal: {
    lightBgColor1: CHARTCOLORS.light.Personal,
    lightBgColor2: "#9EFF62",
    darkBgColor1: CHARTCOLORS.dark.Personal,
    darkBgColor2: "#2A7000",
  },
  Other: {
    lightBgColor1: CHARTCOLORS.light.Other,
    lightBgColor2: "#FFB69F",
    darkBgColor1: CHARTCOLORS.dark.Other,
    darkBgColor2: "#7B331C",
  },
  NCNS: {
    lightBgColor1: CHARTCOLORS.light.NCNS,
    lightBgColor2: "#C4FF9F",
    darkBgColor1: CHARTCOLORS.dark.NCNS,
    darkBgColor2: "#50882E",
  },
  LRI: {
    lightBgColor1: CHARTCOLORS.light.LRI,
    lightBgColor2: "#C4FF9F",
    darkBgColor1: CHARTCOLORS.light.LRI,
    darkBgColor2: "#50882E",
  },
  LE: {
    lightBgColor1: CHARTCOLORS.light.LE,
    lightBgColor2: "#C4FF9F",
    darkBgColor1: CHARTCOLORS.dark.LE,
    darkBgColor2: "#50882E",
  },
  Excused: {
    lightBgColor1: CHARTCOLORS.light.LE,
    lightBgColor2: "#C4FF9F",
    darkBgColor1: CHARTCOLORS.dark.LE,
    darkBgColor2: "#50882E",
  },
};

export const EMPTYSIGN: string =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAASwAAACWCAYAAABkW7XSAAAAAXNSR0IArs4c6QAABGlJREFUeF7t1MEJADAMA7Fm/3Xzb6FbHCgTGDl4dvceR4AAgYDAGKxASyISIPAFDJZHIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRB4RxVDB6bL/ysAAAAASUVORK5CYII=";

export const TABLEROWNAMES: {
  name: string;
  editable: boolean;
}[] = [
  {
    name: TableNames.NOOFEMPLOYEES,
    editable: true,
  },
  {
    name: TableNames.VACATION,
    editable: true,
  },
  {
    name: TableNames.SICK,
    editable: false,
  },
  {
    name: TableNames.WCI,
    editable: true,
  },
  {
    name: TableNames.FMLA,
    editable: false,
  },
  {
    name: TableNames["NCNS/PERSONAL"],
    editable: false,
  },
  {
    name: TableNames.OOS,
    editable: true,
  },
  {
    name: TableNames.OTHERS,
    editable: true,
  },
  {
    name: TableNames.TOTAL,
    editable: false,
  },
  {
    name: TableNames.DAILY,
    editable: false,
  },
  {
    name: TableNames.TARGET,
    editable: false,
  },
];
