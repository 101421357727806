import React from "react";
import ReactDOM from "react-dom";
import { useApp } from "context/AppProvider";
import { useLocation } from "react-router-dom";
import { PATHS } from "constants/index";
type ModalProps = {};

const Modal: React.FC<ModalProps> = () => {
  const {
    isModalOpen: isOpen,
    closeModal: handleClose,
    handleDelete,
  } = useApp();

  const { pathname } = useLocation();
   return ReactDOM.createPortal(
    <div
      className={[
        "fixed flex justify-center items-center top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50",
        isOpen ? "visible opacity-1" : "opacity-0 invisible",
      ].join(" ")}
    >
      <div className="relative bg-white p-8 max-w-[600px] rounded-md text-center flex flex-col gap-y-10">
        <h1 className="text-primary text-3xl font-sans font-medium">
          Are you sure?  
        </h1>
        <p className="text-primary text-lg font-sans font-normal">
          {(pathname === PATHS.HOME) &&
            "Do you really want to undo the reminder? This process cannot be undone."}
          {pathname === PATHS.TEAM &&
            "Do you really want to delete the employee? This process cannot be undone."}
            {pathname === PATHS.ATTENDANCEREPORT &&
            "Do you really want to delete the file? This process cannot be undone."}
        </p>
        <div className="flex justify-center gap-x-4">
          <button
            className="text-white rounded-md px-10 py-2 text-xl bg-[#d9d9d9] transition-all duration-200 hover:bg-[#c4c4c4] font-sans font-medium"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            className="text-white rounded-md px-10 py-2 text-xl bg-[#ff007f] transition-all duration-200 hover:bg-[#d90166] font-sans font-medium"
            onClick={handleDelete}
          >
           {pathname === PATHS.TEAM || pathname === PATHS.ATTENDANCEREPORT ? 'Delete':'Complete'} 
          </button>
        </div>
      </div>
    </div>,
    document.getElementById("modal") as HTMLElement
  );
};

export default Modal;
