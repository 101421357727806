import React from "react";
import SignatureCanvas from "react-signature-canvas";

interface IProps {
  sign: SignatureCanvas | null;
  setSign: React.Dispatch<React.SetStateAction<SignatureCanvas | null>>;
  clearSign: (sign: SignatureCanvas, by: string) => void;
  saveSign: (sign: SignatureCanvas, by: string) => void;
  name: string;
  label: string;
}
const Signature: React.FC<IProps> = ({
  setSign,
  saveSign,
  clearSign,
  name,
  sign,
  label,
}) => {
  return (
    <div className="flex flex-col gap-y-2">
      <label className="text-sm font-bold font-sans text-main dark:text-white mb-[9px] mt-[10px]">
        {label} <span className="font-bold text-primary">&#40;Draw&#41;</span>
      </label>
      <SignatureCanvas
        penColor="black"
        backgroundColor="#f4f4f4"
        canvasProps={{ width: "300px", height: "150px" }}
        ref={(ref) => setSign(ref)}
      />

      <div className="flex gap-x-3">
        <button
          type="button"
          className="bg-light border border-primary font-sans font-normal text-base px-3 py-1"
          onClick={() => {
            if (sign) {
              saveSign(sign, name);
            }
          }}
        >
          Save
        </button>
        <button
          className="bg-light border border-primary font-sans font-normal text-base px-3 py-1"
          type="button"
          onClick={() => {
            if (sign) {
              clearSign(sign, name);
            }
          }}
        >
          Clear
        </button>
      </div>
    </div>
  );
};

export default Signature;
