import Heading from "components/Heading/Heading";
import logo from "assets/images/logo.png";
import Form from "pages/DisciplinaryForm/components/DisciplinaryForm";
import { useState } from "react";
import { FormOptions } from "types";
import { FORMOPTIONS, PATHS } from "constants/index";
import InfractionForm from "pages/DisciplinaryForm/components/InfractionForm";
import Select from "components/Select/Select2";
import { Link } from "react-router-dom";
export const DisciplinaryForm = () => {
  const [selectForm, setSelectForm] = useState<FormOptions>(
    FormOptions["Disciplinary Form"]
  );

  return (
    <div className="w-full bg-white dark:bg-dark  p-8 flex flex-col justify-center items-center relative">
      <Link
        className="text-main font-sans font-medium text-lg absolute top-10 right-10 underline "
        to={PATHS.DEFAULT}
      >
        Back to Home
      </Link>

      <div className="flex flex-col justify-between gap-y-10">
        <img
          src={logo}
          alt="logo"
          className="max-h-[65px] max-w-[304px] sm:w-4/5 w-3/5 object-cover"
        />
        <Select
          label="Select Form"
          className="w-[300px] lg:ml-6"
          options={FORMOPTIONS}
          value={selectForm}
          onClick={(value: any) => setSelectForm(value as FormOptions)}
        />
      </div>

      <Heading
        title={
          FormOptions["Disciplinary Form"] === selectForm
            ? "EMPLOYEE DISCIPLINARY ACTION FORM"
            : "ATTENDANCE POLICY INFRACTION NOTICE"
        }
        className="mt-10 text-center"
      />

      {selectForm === FormOptions["Disciplinary Form"] && (
        <div className="flex mt-10 xl:max-w-[68%] lg:max-w-[70%] w-full">
          <Form />
        </div>
      )}
      {selectForm === FormOptions["Infraction Notice Form"] && (
        <div className="flex mt-10 xl:max-w-[68%] lg:max-w-[70%] w-full">
          <InfractionForm />
        </div>
      )}
    </div>
  );
};
