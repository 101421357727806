import React, { Fragment, useEffect, useState } from "react";
import Input from "components/Input/Input";
import Select from "components/Select/Select2";
import Button from "components/Button/Button";
import CheckboxGroup from "pages/EmployeeManagement/components/CheckboxGroup";
import Switch from "components/Switch/Switch2";

import {
  ADMINOPTIONS,
  CREATEEMPINITIALVALUES,
  CREATESUPVINITIALVALUES,
  DEPARTMENTOPTIONS,
  PATHS,
} from "constants/index";
import { Admin, Department, SubRole } from "types";
import { useFormik } from "formik";
import { createEmpSchema, createSupvSchema } from "utils/validationSchema";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import { UserSlice } from "redux/features/user/userSlice";
import { Employee, Supervisor, useEmployee } from "hooks/useEmployee";
import { EmployeeSlice } from "redux/features/employee/employeeSlice";
import { useLocation, useParams } from "react-router-dom";
import { useApp } from "context/AppProvider";
import Loader from "components/Loader/Loader";

const Form = () => {
  const { user } = useSelector<RootState, UserSlice>((state) => state.user);
  const { updateEmployee, selectedEmployee } = useSelector<
    RootState,
    EmployeeSlice
  >((state) => state.employee);
  const { subroles } = useApp();
  const { pathname } = useLocation();
  const { id } = useParams();
  const updateUrl = `${PATHS.UPDATEEMPLOYEE}/${id}`;

  const [subRoleOptions, setSubRoleOptions] = useState<SubRole>(
    subroles.maintenance
  );

  const {
    employeeState,
    createEmployee,
    createSupervisor,
    deleteUpdateEmployeeInfo,
    editEmployee,
    updateEmployee: getEmployeeInfo,
    updateEmployeeState,
    changeSelectedEmployee,
  } = useEmployee();
  const {
    handleSubmit,
    handleChange,
    values,
    touched,
    errors,
    setFieldValue,
    getFieldProps,
  } = useFormik({
    initialValues: user?.isAdmin
      ? pathname === updateUrl && updateEmployee
        ? updateEmployee
        : CREATESUPVINITIALVALUES
      : pathname === updateUrl && updateEmployee
      ? updateEmployee
      : CREATEEMPINITIALVALUES,

    enableReinitialize: true,
    validationSchema: user?.isAdmin ? createSupvSchema : createEmpSchema,
    onSubmit: async (values, onSubmitProps) => {
      if (
        user?.isSupervisor ||
        (user?.isAdmin && values.admin === Admin.Employee)
      ) {
        const employee: Employee = {
          employee_name: values.employeeName,
          employee_id: values.employeeID,
          status: values.status!,
          department:
            user.department === Department.both
              ? values.deptName
              : user.department,
          work_location: CREATEEMPINITIALVALUES.workLocation!,
          sub_roles: values.subRole.map((role) => Number(role)),
        };

        if (pathname === updateUrl) {
          await editEmployee(employee, values.admin);
        } else {
          await createEmployee(employee);
        }
      } else if (user?.isAdmin && values.admin === Admin.Supervisor) {
        const supervisor: Supervisor = {
          employee_id: values.employeeID,
          name: values.employeeName,
          dept: values.deptName,
          email: values.email!,
          designation:values.designation!,
          work_location: CREATESUPVINITIALVALUES.workLocation!,
          password1: "None",
          password2: "None",
          role: values.admin.toLowerCase(),
          sub_roles: values.subRole.map((role) => Number(role)),
        };

        if (pathname === updateUrl) {
          await editEmployee(supervisor, values.admin);
        } else {
          await createSupervisor(supervisor);
        }
      }

      onSubmitProps.setSubmitting(false);
      onSubmitProps.resetForm();
    },
  });

  useEffect(() => {
    // @ts-ignore
    if (user?.department === Department.both) {
      // @ts-ignore
      setSubRoleOptions(subroles[values.deptName]);
    } else {
      // @ts-ignore
      setSubRoleOptions(subroles[user?.department]);
    }
  }, [values.deptName, setSubRoleOptions, subroles, user]);

  useEffect(() => {
    if (updateUrl === pathname && selectedEmployee) {
      getEmployeeInfo(selectedEmployee);
    }
    //cleanup
    return () => {
      deleteUpdateEmployeeInfo();
      changeSelectedEmployee(undefined);
    };
  }, [
    getEmployeeInfo,
    id,
    pathname,
    updateUrl,
    deleteUpdateEmployeeInfo,
    selectedEmployee,
    changeSelectedEmployee,
  ]);
  return (
    <Fragment>
      {updateEmployeeState.isLoading ? (
        <Loader className="bg-dark dark:bg-white" />
      ) : (
        <form
          className="flex flex-wrap gap-x-7 gap-y-7"
          noValidate
          onSubmit={handleSubmit}
        >
          <Input
            label={"Employee Name"}
            placeholder="Robert Anderson"
            type="email"
            name="employeeName"
            error={touched.employeeName && errors.employeeName}
            value={values.employeeName}
            className="w-full lg:w-[47%] lg:max-w-[397px]"
            onChange={handleChange}
          />
          <Input
            label={"Employee ID"}
            placeholder="RA-12323"
            type="text"
            name="employeeID"
            error={touched.employeeID && errors.employeeID}
            value={values.employeeID}
            className="w-full lg:w-[47%] lg:max-w-[397px]"
            onChange={handleChange}
          />

          {user?.isAdmin && (
            <Select
              label="Select Role"
              options={ADMINOPTIONS}
              value={values.admin}
              className="w-full lg:w-[47%] lg:max-w-[397px]"
              onClick={(item: string) => setFieldValue("admin", item)}
            />
          )}
          {user?.department === Department.both && (
            <Select
              label="Department Name"
              options={DEPARTMENTOPTIONS}
              value={values.deptName}
              className="w-full lg:w-[47%] lg:max-w-[397px]"
              onClick={(item: string) => setFieldValue("deptName", item)}
            />
          )}

          {user?.isAdmin && values.admin === Admin.Supervisor && (
            <Input
              label={"Email"}
              placeholder="email.com"
              type="email"
              name="email"
              error={touched.email && errors.email}
              value={values.email}
              className="w-full lg:w-[47%] lg:max-w-[397px]"
              onChange={handleChange}
            />
          )}
          {user?.isAdmin && values.admin === Admin.Supervisor && (
            <Input
              label={"Designation"}
              placeholder="Designation"
              type="text"
              name="designation"
              error={touched.designation && errors.designation}
              value={values.designation}
              className="w-full lg:w-[47%] lg:max-w-[397px]"
              onChange={handleChange}
            />
          )}
          {user?.isAdmin &&
            values.admin === Admin.Employee &&
            pathname === updateUrl && (
              <Switch
                label={"Status"}
                className="w-full lg:w-[47%] lg:max-w-[397px]"
                name="status"
                checked={values.status}
                onChange={handleChange}
              />
            )}
          <CheckboxGroup
            options={subRoleOptions}
            onChange={handleChange}
            name="subRole"
            error={touched.subRole && errors.subRole?.toString()}
            value={
              pathname === updateUrl
                ? getFieldProps("subRole").value.map((num: number) =>
                    num.toString()
                  )
                : getFieldProps("subRole").value
            }
          />
          <Button
            type="submit"
            isLoading={employeeState.isLoading}
            title={"Submit"}
            className="mx-auto px-10 dark:bg-success"
          />
        </form>
      )}
    </Fragment>
  );
};

export default Form;
