import { useContext, useState } from "react";
import { useEmployee } from "hooks/useEmployee";
import { createContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { UserSlice } from "redux/features/user/userSlice";
import { RootState } from "redux/store";
import { SubRoles } from "types";
import { PATHS } from "constants/index";
import { useDashboard } from "hooks/useDashboard";
type AppContextType = {
  subroles: SubRoles;
  closeModal: () => void;
  isModalOpen: boolean;
  handleDelete: () => void;
  openModal: (data: string | number, path: string) => void;
};
interface IProps {
  children: React.ReactNode;
}

const AppContext = createContext<AppContextType>({
  subroles: {
    operations: {},
    maintenance: {},
    both: {},
  },
  closeModal: () => {},
  isModalOpen: false,
  handleDelete: () => {},
  openModal: (data: string | number, path: string) => {},
});

const AppProvider: React.FC<IProps> = ({ children }) => {
  const { getAllEmployees, getSubRoles, removeEmployee, removeFile } = useEmployee();
  const { user } = useSelector<RootState, UserSlice>((state) => state.user);
  const { deleteTaskReminder } = useDashboard();
  const [subroles, setSubRoles] = useState<SubRoles>({
    operations: {},
    maintenance: {},
    both: {},
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [employeeId, setEmployeeId] = useState("");
  const [reminderId, setReminderId] = useState(0);
  const [fileData, setFileData] = useState("");
  const [pathname, setPathname] = useState("");
  const closeModal = () => {
    setIsModalOpen(false);
    setEmployeeId("");
    setReminderId(0);
    setFileData("")
  };
  const openModal = (data: string | number, path: string) => {
    if (path === PATHS.TEAM && typeof data === "string") {
      setEmployeeId(data);
    }
    if (path === PATHS.HOME) {
      setReminderId(Number(data));
    }
    if (path === PATHS.ATTENDANCEREPORT && typeof data === "string") {
      setFileData(data)
    }
    setPathname(path);
    setIsModalOpen(true);
  };
  const handleDelete = async () => {
    if (pathname === PATHS.TEAM) {
      await removeEmployee(employeeId);
    }
    if (pathname === PATHS.ATTENDANCEREPORT) {
      await removeFile(fileData);
    }
    if (pathname === PATHS.HOME) {
      deleteTaskReminder(reminderId);
    }
    closeModal();
  };
  useEffect(() => {
    if (user) {
      getAllEmployees();
      getSubRoles().then((roles) => {
        setSubRoles(roles);
      });
    }
  }, [getAllEmployees, user, getSubRoles]);

  return (
    <AppContext.Provider
      value={{ subroles, closeModal, isModalOpen, handleDelete, openModal }}
    >
      {children}
    </AppContext.Provider>
  );
};
export default AppProvider;

export const useApp = () => {
  return useContext(AppContext);
};
