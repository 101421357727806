/* eslint-disable react-hooks/exhaustive-deps */
import {
  useState,
  RefObject,
  Fragment,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from "react";
import { useSelector } from "react-redux";
import Container from "components/Container/Container";
import WrapperLayout from "components/layouts/AppLayout";
import Summary from "pages/AttendanceReport/components/Summary";
import Calendar from "pages/AttendanceReport/components/Calendar";
import Select from "components/Select/Select";
import SearchBar from "pages/AttendanceReport/components/SearchBar";
import Heading from "components/Heading/Heading";
import Error from "components/Error/Error";
import LineChart from "components/LineChart/LineChart";
import BarGraph from "components/BarGraph/BarGraph";
import Loader from "components/Loader/Loader";
import FullCalendar from "@fullcalendar/react";
import { UserSlice } from "redux/features/user/userSlice";
import moment from "moment";
import { DatesSetArg } from "@fullcalendar/core";
import { ARFILTEROPTIONS, OPTIONS } from "constants/index";
import { DateProperty, Option, Options, Event } from "types";
import {
  formatDateUS,
  getCurrentMonth,
  getCurrentWeek,
  getCurrentYear,
  getDateProps,
  getOptionsType,
  createAllEmployeeAttendanceReport,
  createSelectedEmployeeAttendanceReport,
  getPeriod,
} from "utils";

import { RootState } from "redux/store";
import { EmployeeSlice } from "redux/features/employee/employeeSlice";
import { useEmployee } from "hooks/useEmployee";
import { DashboardSlice } from "redux/features/dashboard/dashboardSlice";
import { useDashboard } from "hooks/useDashboard";
import EventPopup from "./components/EventPopup";
import Table from "./components/Table";
export const AttendanceReport = () => {
  //STATES
  const {
    employees,
    employeeAttendanceReport,
    selectedEmployee,
    totalPoints,
    infractionComparisonData,
    employeeFiles,
  } = useSelector<RootState, EmployeeSlice>((state) => state.employee);
  const tableData = useMemo(
    () =>
      employeeFiles?.map((item, index) => {
        return {
          col1: moment(item.choose_date).format("MM-DD-YYYY"),
          col2: item.file,
          col3: item.id,
          col4: item.employee_id,
        };
      }),
    [employeeFiles]
  );
  const { lineChart, data } = useSelector<RootState, DashboardSlice>(
    (state) => state.dashboard
  );
  const { emptyLineChartData, emptyBarChartData } = useDashboard();
  const {
    getEmployeeAttendanceReport,
    employeeState,
    getEmployeeAbsences,
    getEmployeeNotices,
    deleteEmployeeAbsences,
    changeSelectedEmployee,
    getEmployeeTotalPoints,
    getAllEmployeesAttendaceReport,
    getEmployeeFiles,
    getAbsences,
  } = useEmployee();
  const [searchValue, setSearchValue] = useState({
    wordEntered: selectedEmployee?.name ?? "",
    selectedEmployee: selectedEmployee?.name ?? "",
  });
  const [filters, setFilters] = useState<{
    date: string;
    periodType: Option;
    dataType: Option;
  }>({
    date: getCurrentWeek(),
    periodType: OPTIONS[0],
    dataType: ARFILTEROPTIONS[1],
  });
  const { user } = useSelector<RootState, UserSlice>((state) => state.user);
  const [calendarRenderedDates, setCalendarRenderedDates] = useState<{
    startDate: Date;
    endDate: Date;
    month: number | null;
    year: number | null;
  } | null>(null);
  //attendance report data
  const [reportData, setReportData] = useState<Event[]>([]);
  //event modal Props
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tableEmployee, setTableEmployee] = useState<any>(null);
  const [modalProps, setModalProps] = useState<string>("");
  //calendar Ref
  const calendarRef = useRef() as RefObject<FullCalendar>;

  //HANDLERS

  const createReportData = (arg: { startDate: Date; endDate: Date }) => {
    const { startDate, endDate } = arg;
    const start = formatDateUS(startDate, "", "en");
    const end = formatDateUS(endDate, "1", "en");
    const data = employeeAttendanceReport.filter((el) => {
      return el.date >= start && el.date <= end;
    });
    setReportData(data);
  };

  const handleCalendarChange = useCallback(
    (arg: DatesSetArg) => {
      if (arg.view.type === "dayGridWeek") {
        const startDate = formatDateUS(arg.start, "", "en");
        const endDate = formatDateUS(arg.end, "1", "en");
        setCalendarRenderedDates((prev) => {
          return {
            ...prev,
            startDate: arg.start,
            endDate: arg.end,
            month: null,
            year: null,
          };
        });

        if (selectedEmployee) {
          getEmployeeTotalPoints({
            employeeID: selectedEmployee.id,
            date: `${startDate},${endDate}`,
            type: getOptionsType(filters.periodType),
          });
          getEmployeeFiles(selectedEmployee.id as number);
          setTableEmployee(selectedEmployee);
          const dates = JSON.stringify({
            week: `${startDate},${endDate}`,
            month: `${moment(startDate).format("M")},${moment(startDate).format(
              "YYYY"
            )}`,
            year: moment(startDate).format("YYYY"),
          });
          getAbsences(selectedEmployee, dates);
        }
        if (filters.dataType.label === Options.ALL) {
          getEmployeeTotalPoints({
            employeeID: "all",
            date: `${startDate},${endDate}`,
            type: getOptionsType(filters.periodType),
          });
        }
      }

      if (arg.view.type === "dayGridMonth" || arg.view.type === "dayGridYear") {
        const month = getDateProps(arg.view.currentStart, DateProperty.MONTH);
        const year = getDateProps(arg.view.currentStart, DateProperty.YEAR);
        setCalendarRenderedDates((prev) => {
          return {
            ...prev,
            month,
            year,
            startDate: arg.view.currentStart,
            endDate: arg.view.currentEnd,
          };
        });

        if (selectedEmployee) {
          getEmployeeTotalPoints({
            employeeID: selectedEmployee.id,
            date: `${month},${year}`,
            type: getOptionsType(filters.periodType),
          });
          const dates = JSON.stringify({
            week: `${formatDateUS(
              arg.view.currentStart,
              "",
              "en"
            )},${formatDateUS(arg.view.currentEnd, "", "en")}`,
            month: `${month},${year}`,
            year: year,
          });
          getAbsences(selectedEmployee, dates);
        }
        if (filters.dataType.label === Options.ALL) {
          getEmployeeTotalPoints({
            employeeID: "all",
            date:
              arg.view.type === "dayGridMonth"
                ? `${month},${year}`
                : year.toString(),
            type: getOptionsType(filters.periodType),
          });
        }
      }
      if (arg.view.type === "customView") {
        const dates = filters.date.split(",");

        setCalendarRenderedDates((prev) => {
          return {
            ...prev,
            startDate: moment(dates[0]).toDate(),
            endDate: moment(dates[1]).toDate(),
            month: null,
            year: null,
          };
        });
        getEmployeeTotalPoints({
          employeeID: "all",
          date: `${dates[0]},${dates[1]}`,
          type: getOptionsType(filters.periodType),
        });
      }
    },
    [selectedEmployee, employeeAttendanceReport]
  );
  const handleSelect = useCallback((item: Option) => {
    if (item.value === Options.MONTHLY) {
      setFilters((filters) => {
        return {
          ...filters,
          date: `${getCurrentMonth()},${getCurrentYear()}`,
          periodType: item,
        };
      });
    }
    if (item.value === Options.WEEKLY) {
      setFilters((filters) => {
        return {
          ...filters,
          date: getCurrentWeek(),
          periodType: item,
        };
      });
    }

    if (item.value === Options.YEARLY) {
      setFilters((filters) => {
        return {
          ...filters,
          date: getCurrentYear(),
          periodType: item,
        };
      });
    }
    if (item.value === Options.CUSTOM) {
      const { startDate, endDate } = JSON.parse(item.label);
      setFilters((filters) => {
        return {
          ...filters,
          date: `${startDate},${endDate}`,
          periodType: item,
        };
      });
    }
  }, []);
  const handleSearchValue = (value: string) => {
    setSearchValue((prev) => {
      return {
        ...prev,
        selectedEmployee: value,
      };
    });
    //reset filters
    // setFilters({
    //   date: getCurrentWeek(),
    //   periodType: OPTIONS[0],
    //   dataType: ARFILTEROPTIONS[1],
    // });
  };

  const exportReport = () => {
    if (filters.dataType.label === Options.ALL && calendarRenderedDates) {
    const start = formatDateUS(calendarRenderedDates.startDate, "", "en");
    const end = formatDateUS(calendarRenderedDates.endDate, "1", "en");
      const excelData = employeeAttendanceReport.filter((el) => {
        return el.date >= start && el.date <= end;
      });
      const data = {
        data: excelData,
        periodType: getPeriod(
          filters.periodType,
          calendarRenderedDates.startDate,
          calendarRenderedDates.endDate
        ),
        totalPoints: totalPoints,
      };
      console.log('213',data)
      createAllEmployeeAttendanceReport(data);
    }
    if (selectedEmployee && calendarRenderedDates) {
      const data = {
        periodType: getPeriod(
          filters.periodType,
          calendarRenderedDates.startDate,
          calendarRenderedDates.endDate
        ),

        employeeName: selectedEmployee.name,
        data: reportData,
        totalPoints: totalPoints,
      };
      createSelectedEmployeeAttendanceReport(data);
    }
  };
  //EFFECTS
  useEffect(() => {
    deleteEmployeeAbsences();
    changeSelectedEmployee(undefined);
    emptyLineChartData();
    emptyBarChartData();
    // for all employees
    if (
      searchValue.selectedEmployee.length === 0 &&
      filters.dataType.label === Options.ALL
    ) {
      getAllEmployeesAttendaceReport();
    }
    //for specific employee
    if (searchValue.selectedEmployee.length > 0) {
      const data = {
        type: getOptionsType(filters.periodType),
        employeeName: searchValue.selectedEmployee,
        date: filters.date,
      };
      const employee = employees.find(
        (emp) => emp.name === searchValue.selectedEmployee
      );
      getEmployeeAttendanceReport(data);

      if (employee) {
        const dates = JSON.stringify({
          week: getCurrentWeek(),
          month: `${getCurrentMonth()},${getCurrentYear()}`,
          year: getCurrentYear(),
        });
        changeSelectedEmployee({ ...employee, role: "employee" });
        getEmployeeAbsences(employee, dates);
        getEmployeeNotices(employee);
        getEmployeeFiles(employee.id as number);
        setTableEmployee(employee);
        //get total points and report data for selected employee
        if (filters.periodType.value === Options.YEARLY) {
          getEmployeeTotalPoints({
            employeeID: employee?.id,
            date: filters.date,
            type: getOptionsType(filters.periodType),
          });
          setCalendarRenderedDates((prev) => {
            return {
              ...prev,
              startDate: moment().startOf("year").toDate(),
              endDate: moment().endOf("year").toDate(),
              month: null,
              year: null,
            };
          });
        }
        if (filters.periodType.value === Options.CUSTOM) {
          const dates = filters.date.split(",");
          getEmployeeTotalPoints({
            employeeID: employee?.id,
            date: filters.date,
            type: getOptionsType(filters.periodType),
          });
          setCalendarRenderedDates((prev) => {
            return {
              ...prev,
              startDate: moment(dates[0]).toDate(),
              endDate: moment(dates[1]).add(1, "day").toDate(),
              month: null,
              year: null,
            };
          });
        }
      }
    }

    //cleanup
    return () => {
      changeSelectedEmployee(undefined);
      deleteEmployeeAbsences();
      emptyLineChartData();
    };
  }, [searchValue.selectedEmployee, filters, employees]);
  useEffect(() => {
    if (modalProps.length > 0) {
      setIsModalOpen(true);
    }
  }, [modalProps]);

  useEffect(()=>{
    console.log(employeeAttendanceReport)
    if(employeeAttendanceReport.length){
      createReportData({
        startDate: calendarRenderedDates?.startDate || new Date(),
        endDate: calendarRenderedDates?.endDate || new Date(),
      });
    }
  },[employeeAttendanceReport])
  return (
    <WrapperLayout>
      <div className="flex flex-col gap-y-5 xl:flex-row items-start justify-between mt-6 gap-x-6">
        <div className="flex flex-col items-start justify-between gap-y-5 max-w-[960px] w-full">
          <Container className="px-3 sm:px-8 flex justify-between items-center w-full h-[119px]">
            <Heading title="Attendance Report" />
            <div className="flex gap-x-4 gap-y-4 flex-col md:flex-row">
              <Select
                options={ARFILTEROPTIONS}
                className="w-auto order-1 md:order-2"
                selected={filters.dataType}
                onSelect={(item: Option) => {
                  setSearchValue({
                    selectedEmployee: "",
                    wordEntered: "",
                  });
                  setFilters((filters) => {
                    return {
                      ...filters,
                      dataType: item,
                    };
                  });
                }}
                customDate={false}
              />
              <Select
                options={OPTIONS}
                className="w-auto order-1 md:order-2"
                selected={filters.periodType}
                onSelect={handleSelect}
                customDate={true}
              />
            </div>
          </Container>
          <Container className="card relative py-10 px-8 flex flex-col items-start w-full max-h-[520px] overflow-y-auto overflow-x-auto min-h-[400px]">
            <div
              className={`flex gap-x-4 w-full ${
                filters.dataType.label === Options.ALL
                  ? "mb-3 justify-between"
                  : "mb-5 justify-start"
              }`}
            >
              {filters.dataType.label === Options.ALL ? (
                <h2 className="font-bold font-sans text-2xl text-primary dark:text-white">
                  All Employees
                </h2>
              ) : (
                <SearchBar
                  data={employees}
                  value={searchValue.wordEntered}
                  setValue={setSearchValue}
                  placeholder={"Search Employee Name"}
                  className={`min-w-[240px] sm:w-full ${
                    searchValue.selectedEmployee ? "mt-0" : "mt-20"
                  }`}
                  onSelect={handleSearchValue}
                />
              )}

              <button
                onClick={exportReport}
                disabled={reportData.length === 0 || employeeState.isLoading}
                className={`px-3 py-1 dark:bg-success bg-primary text-white rounded-md min-w-[140px] md:min-w-[150px]  disabled:bg-disable  ${
                  searchValue.selectedEmployee ||
                  filters.dataType.label === Options.ALL
                    ? "block"
                    : "hidden"
                }`}
              >
                Export Report
              </button>
            </div>

            {employeeState.isLoading ? (
              <Loader className="bg-dark dark:bg-white" />
            ) : employeeState.isError ? (
              <Error
                message="Something went wrong. Please try again"
                onClick={() => {
                  const data = {
                    type: getOptionsType(filters.periodType),
                    employeeName: searchValue.selectedEmployee,
                    date: filters.date,
                  };
                  getEmployeeAttendanceReport(data);
                }}
              />
            ) : (
              <Fragment>
                {(searchValue.selectedEmployee.length > 0 ||
                  filters.dataType.label === Options.ALL) &&
                  !employeeState.isLoading && (
                    <Fragment>
                      <h1
                        className={`absolute ${
                          filters.dataType.label === Options.ALL
                            ? "sm:top-[80px] top-[112px]"
                            : "sm:top-[100px] top-[130px]"
                        } font-sans font-bold text-primary text-2xl dark:text-white`}
                      >
                        Total Points: {totalPoints}
                      </h1>
                      {filters.dataType.label !== Options.ALL &&
                      (filters.periodType.value === Options.YEARLY ||
                        filters.periodType.value === Options.CUSTOM) ? (
                        <Fragment>
                          <BarGraph data={data.barChart} />
                          <LineChart data={lineChart} />
                        </Fragment>
                      ) : (
                        <>
                          <Calendar
                            ref={calendarRef}
                            events={employeeAttendanceReport}
                            handleCalendarChange={handleCalendarChange}
                            view={getOptionsType(filters.periodType)}
                            comparisonData={infractionComparisonData}
                            setModalProps={setModalProps}
                            customDates={filters.date}
                          />
                        </>
                      )}
                      {selectedEmployee && tableData && tableData.length ? (
                        <Table data={tableData} employee={tableEmployee} />
                      ) : null}
                    </Fragment>
                  )}
                {isModalOpen && selectedEmployee && user?.isAdmin && (
                  <EventPopup
                    key={modalProps}
                    data={modalProps}
                    onClose={() => {
                      setModalProps("");
                      setIsModalOpen(false);
                    }}
                  />
                )}
              </Fragment>
            )}
          </Container>
        </div>
        <Container
          className="py-5 px-7 w-full xl:max-w-[390px] overflow-y-auto"
          style={{ maxHeight: "calc(100vh - 135px)" }}
        >
          <Summary />
        </Container>
      </div>
    </WrapperLayout>
  );
};
