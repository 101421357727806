import { User } from "types";
export enum KEYS {
  USER = "user",
  TOKEN = "token",
  PERSIST = "persist:root",
}

export const getUser = () => {
  const result = localStorage.getItem(KEYS.USER);
  if (result) {
    const user: User = JSON.parse(result);
    return user;
  }
  return null;
};
export const setUser = (user: User) => {
  const data = JSON.stringify(user);
  const token = user.key;
  localStorage.setItem(KEYS.TOKEN, token);
  localStorage.setItem(KEYS.USER, data);
};

export const deleteUser = () => {
  localStorage.removeItem(KEYS.USER);
  localStorage.removeItem(KEYS.TOKEN);
  // localStorage.removeItem(KEYS.PERSIST);
};
