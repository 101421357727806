import React from "react";
interface IProps {
  message: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}
const Error: React.FC<IProps> = ({ message, onClick }) => {
  return (
    <div className="m-auto text-center">
      <h3 className="font-sans font-normal text-base text-primary dark:text-white mb-1">
        {message}
      </h3>
      <button
        className="text-white rounded-md px-10 py-2 text-xl bg-[#ff007f] transition-all duration-200 hover:bg-[#d90166] font-sans font-medium"
        onClick={onClick}
      >
        Retry
      </button>
    </div>
  );
};

export default Error;
