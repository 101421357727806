import React, { useEffect, useMemo } from "react";
import Container from "components/Container/Container";
import WrapperLayout from "components/layouts/AppLayout";
import Table from "./components/Table";
import Heading from "components/Heading/Heading";
import { useTeams } from "hooks/useTeams";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import { TeamSlice } from "redux/features/team/teamSlice";
import Loader from "components/Loader/Loader";
import Error from "components/Error/Error";

export const Team = () => {
  const { getTeamMembers, teamState } = useTeams();

  const { teamMembers } = useSelector<RootState, TeamSlice>(
    (state) => state.team
  );

  const data = useMemo(
    () =>
      teamMembers.map((item, index) => {
        return {
          col1: index + 1,
          col2: item.name,
          col3: item.employeeId,
          col4: item.department,
          col5: item.role,
          col6: item.designation ? item.designation : "-",
          col7: String(item.status),
        };
      }),
    [teamMembers]
  );

  useEffect(() => {
    getTeamMembers();
  }, [getTeamMembers]);
  return (
    <WrapperLayout>
      <div className="flex flex-col gap-y-5 xl:flex-row items-start justify-between mt-6 gap-x-6">
        <div className="flex flex-col items-start justify-between gap-y-5  w-full">
          <Container className="px-8 flex justify-between items-center w-full h-[119px]">
            <Heading title="Team" />
          </Container>
          <Container
            className={`card py-10 px-8 flex flex-col items-start w-full overflow-y-auto ${
              (teamState.isLoading || teamState.isError) && "min-h-[500px]"
            }`}
          >
            {teamState.isLoading ? (
              <Loader className="bg-dark dark:bg-white" />
            ) : teamState.isError ? (
              <Error
                message="Something went wrong. Please try again"
                onClick={() => getTeamMembers()}
              />
            ) : (
              <Table data={data} />
            )}
          </Container>
        </div>
      </div>
    </WrapperLayout>
  );
};
