import { createSlice } from "@reduxjs/toolkit";
import { User } from "types";
import { KEYS } from "utils/user";

export type UserSlice = {
  user: User | undefined;
  isAuthenticated: boolean;
};
export const userSlice = createSlice({
  name: "user",
  initialState: {
    isAuthenticated: false,
    user: undefined,
  } as UserSlice,
  reducers: {
    login: (state, action) => {
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload,
      };
    },

    logout: (state) => {
      localStorage.removeItem(KEYS.PERSIST);
      return {
        ...state,
        isAuthenticated: false,
        user: undefined,
      };
    },
  },
});

export const { login, logout } = userSlice.actions;

export default userSlice.reducer;
