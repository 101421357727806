import { createSlice } from "@reduxjs/toolkit";
import {
  CreateEmpSchema,
  CreateSupvSchema,
  Data,
  Employee,
  Event,
  IndicatorValue,
  SelectedEmployee,
} from "types";

export type EmployeeSlice = {
  employees: Employee[];
  employeeAbsences: Data[];
  selectedEmployee: SelectedEmployee | undefined;
  employeeNotices: Data[];
  updateEmployee: CreateEmpSchema | CreateSupvSchema | undefined;
  employeeAttendanceReport: Event[];
  infractionComparisonData: IndicatorValue[];
  totalPoints: number;
  employeeFiles: any[];
};
export const employeeSlice = createSlice({
  name: "employee",
  initialState: {
    employees: [],
    employeeFiles: [],
    employeeAbsences: [],
    selectedEmployee: undefined,
    employeeNotices: [],
    updateEmployee: undefined,
    employeeAttendanceReport: [],
    totalPoints: 0,
    infractionComparisonData: [],
  } as EmployeeSlice,
  reducers: {
    fetchedEmployeesSuccess: (state, action) => {
      const employees = Object.keys(action.payload).map((item) => {
        return { id: item, name: action.payload[item] };
      });
      return {
        ...state,
        employees,
      };
    },
    fetchedEmployeeAbsencesSuccess: (state, action) => {
      return {
        ...state,
        employeeAbsences: action.payload,
      };
    },
    fetchedEmployeeFiles: (state, action) => {
      return {
        ...state,
        employeeFiles: action.payload,
      };
    },
    fetchedEmployeeNoticesSuccess: (state, action) => {
      return {
        ...state,
        employeeNotices: action.payload,
      };
    },
    emptyEmployeeAbsencesSuccess: (state) => {
      return {
        ...state,
        employeeAbsences: [],
        employeeNotices: [],
      };
    },
    updateSelectedEmployee: (state, action) => {
      return {
        ...state,
        selectedEmployee: action.payload,
      };
    },
    updateEmployeeSuccess: (state, action) => {
      return {
        ...state,
        updateEmployee: action.payload,
      };
    },
    fetchedAttendanceReportSuccess: (state, action) => {
      return {
        ...state,
        employeeAttendanceReport: action.payload.events,
        infractionComparisonData: action.payload.comparisonData,
      };
    },
    updateTotalPoints: (state, action) => {
      return {
        ...state,
        totalPoints: action.payload,
      };
    },
    emptyEmployeeAttendaceReport: (state) => {
      return {
        ...state,
        employeeAttendanceReport: [],
        infractionComparisonData: [],
      };
    },
  },
});

export const {
  fetchedEmployeesSuccess,
  fetchedEmployeeFiles,
  fetchedEmployeeAbsencesSuccess,
  emptyEmployeeAbsencesSuccess,
  fetchedEmployeeNoticesSuccess,
  updateEmployeeSuccess,
  fetchedAttendanceReportSuccess,
  updateSelectedEmployee,
  updateTotalPoints,
  emptyEmployeeAttendaceReport,
} = employeeSlice.actions;

export default employeeSlice.reducer;
