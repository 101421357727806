import React from "react";

interface IProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant: "warning" | "success";
}

const Button2: React.FC<IProps> = ({
  onClick = () => {},
  variant,
  className = "",
  title,
}) => {
  return (
    <button
      className={[
        "text-[12px] font-semibold text-white py-2 px-5 rounded-md font-sans",
        variant === "warning"
          ? "bg-warning dark:bg-taskwarning"
          : " bg-success dark:bg-tasksuccess",
        className,
      ].join(" ")}
      onClick={onClick}
      type="button"
    >
      {title}
    </button>
  );
};

export default Button2;
