import React, { memo } from "react";
import { useTheme } from "context/ThemeProvider";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Line } from "react-chartjs-2";
import { ChartData, Theme } from "types";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  Title,
  LineElement,
  Tooltip,
  PointElement,
} from "chart.js";

interface IProps {
  data: ChartData;
}
ChartJS.register(
  ChartDataLabels,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  PointElement,
  LineElement
);

const LineChart: React.FC<IProps> = ({ data }) => {
  const { theme } = useTheme();
  const options = {
    color: "white",
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context: any) => {
            return context.dataset.label + ": " + context.parsed.y + "%";
          },
        },
      },
      datalabels: {
        align: 0,
        offset: 10,
        clamp: true,
        color: theme === Theme.LIGHT ? "black" : "white",
        clip: false,
        font: {
          size: 14,
          family: "Raleway",
          lineHeight: 1.5,
          weight: 700,
        },
        padding: {
          top: 10,
          bottom: 20,
        },
        formatter: function (value: any) {
          return value > 0 ? value + "%" : "";
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,

    scales: {
      x: {
        ticks: {
          autoSkip: false,
          color: "black",
          font: {
            size: 15,
            weight: "700",
          },
        },
        stacked: true,
        grid: {
          display: false,
        },
      },

      y: {
        display: false,
        stacked: true,
        grid: {
          display: true,
        },
        ticks: {
          min: 0,
          max: 100,
          callback: function (value: any) {
            return value + "%";
          },
        },
      },
    },
  };
  const labels = Object.keys(data);
  const dataArray = [
    {
      label: "Total Absences",
      borderColor: "#00B4A9",
      data: Object.values(data),
      pointStyle: "false",
      pointRadius: 5,
      pointHoverRadius: 7,
    },
  ];

  const lineChartData = {
    labels: labels,
    datasets: dataArray,
  };

  return (
    <div className="w-full min-w-[500px] min-h-[400px] mt-10">
      <Line options={options} data={lineChartData} redraw={true} />
    </div>
  );
};

export default memo(LineChart);
