import axios from "axios";
import { KEYS } from "utils/user";
/**
 * axios instance
 */
export const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

// request header
api.interceptors.request.use(
  async (config) => {
    let token = getToken();
    if (token) {
      config.headers.Authorization = "Token " + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// response parse
api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    throw error;
  }
);

const getToken = () => {
  return localStorage.getItem(KEYS.TOKEN);
};
