import { Fragment } from "react";
import { useSelector } from "react-redux";
import Notices from "components/ActionsSummary/components/Notices";
import Absences from "components/ActionsSummary/components/Absences";
import { RootState } from "redux/store";
import { EmployeeSlice } from "redux/features/employee/employeeSlice";
import { DashboardSlice } from "redux/features/dashboard/dashboardSlice";
import TaskReminders from "components/TaskReminders/TaskReminders";

const Summary = () => {
  const { employeeAbsences, employeeNotices, selectedEmployee } = useSelector<
    RootState,
    EmployeeSlice
  >((state) => state.employee);

  const { reminders } = useSelector<RootState, DashboardSlice>(
    (state) => state.dashboard
  );

  return (
    <Fragment>
      <h2 className="font-sans font-bold text-lg text-primary mb-8 ml-[-10px] dark:text-white">
        Summary
      </h2>
      <Absences data={employeeAbsences} isLoading={false} />
      {/* <UploadedDocuments data={UPLOADEDDOCDATA} /> */}
      <Notices data={employeeNotices} isLoading={false} />
      <TaskReminders
        tasks={selectedEmployee ? reminders : []}
        isLoading={false}
      />
    </Fragment>
  );
};

export default Summary;
