import React from "react";

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  label: string;
}
const Switch2: React.FC<IProps> = ({
  label,
  checked,
  name,
  onChange,
  className,
}) => {
  return (
    <div className={["flex flex-col", className].join(" ")}>
      <span className="text-sm font-bold font-sans text-main mb-[19px] dark:text-white">
        {label}
      </span>
      <label htmlFor={name} className="flex items-center cursor-pointer">
        <div className="relative">
          <input
            type="checkbox"
            id={name}
            name={name}
            className="sr-only"
            onChange={onChange}
            checked={checked}
          />
          <span className="block bg-light w-14 h-8 rounded-full" />
          <span
            className={`dot absolute left-1 top-1 w-6 h-6 rounded-full transition ${
              checked ? "translate-x-full bg-success" : "bg-white "
            }  `}
          />
        </div>
      </label>
    </div>
  );
};
export default Switch2;
