import { Chart, ArcElement, plugins } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import ChartList from "./ChartList";
import { ChartData, Theme } from "types";
import { useTheme } from "context/ThemeProvider";
import { CHARTCOLORS } from "constants/index";
interface IProps {
  title: string;
  data: ChartData;
  className?: string;
}

Chart.register(ArcElement, plugins);

const Charts: React.FC<IProps> = ({ title, data, className }) => {
  const labels = Object.keys(data);
  const dataVal = Object.values(data);
  const totalSum = dataVal.reduce((prev, curr) => prev + curr, 0);
  const { theme } = useTheme();
  const charttext = theme === Theme.LIGHT ? "black" : "white";
  const textCenter = {
    id: "textCenter",
    beforeDatasetsDraw(chart: Chart, args: any, pluginOption: any) {
      const { ctx } = chart;
      const xCoor = chart.getDatasetMeta(0).data[0].x;
      const yCoor = chart.getDatasetMeta(0).data[0].y;
      ctx.save();
      ctx.font = "bolder 64px raleway";
      ctx.fillStyle = charttext;
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.fillText(`${totalSum}`, xCoor, yCoor - 10);
      ctx.font = "16px raleway";
      ctx.fillText(`${title}`, xCoor, yCoor + 30);
    },
  };

  const backgroundColor = labels.map((color) => {
    let bgColors: { [key: string]: string } = {};
    if (theme === Theme.LIGHT) bgColors = CHARTCOLORS.light;
    else bgColors = CHARTCOLORS.dark;

    return bgColors[color];
  });
  const chartData = {
    labels: labels,
    datasets: [
      {
        label: "# of Votes",
        data: dataVal,
        backgroundColor,
        hoverOffset: 4,
        cutoutPercentage: 80,
        minSliceAngle: 36,
      },
    ],
  };
  return (
    <div className="flex flex-row gap-x-3 mt-10">
      <div className="w-[212px] h-[212px] relative">
        <Doughnut
          redraw={true}
          data={chartData}
          options={{
            elements: {
              arc: {
                borderJoinStyle: "round",
                borderAlign: "inner",
                borderWidth: 0,
                borderRadius: 34,
              },
            },
            cutout: "80%",
            responsive: true,
            plugins: {
              legend: {
                display: false,
              },
              datalabels: {
                display: false,
              },
              tooltip: {
                enabled: false,
              },
            },
          }}
          plugins={[textCenter]}
        />
      </div>
      <div>
        <ChartList
          data={data}
          className={className}
          background={chartData.datasets[0].backgroundColor}
        />
      </div>
    </div>
  );
};

export default Charts;
