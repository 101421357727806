import { Routes, Route, Navigate } from "react-router-dom";
import { PATHS } from "constants/index";
import { Dashboard } from "pages/Dashboard";
import { CallOutLog } from "pages/CallOutLog";
import { EmployeeManagement } from "pages/EmployeeManagement";
import { Team } from "pages/Team";
import { Reports } from "pages/Reports";
import { AttendanceReport } from "pages/AttendanceReport";
import { DisciplinaryForm } from "pages/DisciplinaryForm/index";
import { ProtectedRoutes } from "routes/components/ProtectedRoutes";

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path={PATHS.HOME} element={<Dashboard />} />
      <Route path={PATHS.CALLOUTLOG} element={<CallOutLog />} />
      <Route path={PATHS.EMPLOYEEMANAGEMENT} element={<EmployeeManagement />} />
      <Route
        path={`${PATHS.UPDATEEMPLOYEE}/:id`}
        element={
          <ProtectedRoutes>
            <EmployeeManagement />
          </ProtectedRoutes>
        }
      />
      <Route path={PATHS.FORM} element={<DisciplinaryForm />} />
      <Route path={PATHS.REPORTS} element={<Reports />} />

      <Route path={PATHS.ATTENDANCEREPORT} element={<AttendanceReport />} />
      <Route path={PATHS.TEAM} element={<Team />} />
      <Route path={PATHS.DEFAULT} element={<Navigate to={PATHS.HOME} />} />
    </Routes>
  );
};
