import React from "react";
import Container from "components/Container/Container";
import WrapperLayout from "components/layouts/AppLayout";
import Form from "pages/EmployeeManagement/components/Form";
import Heading from "components/Heading/Heading";
export const EmployeeManagement = () => {
  return (
    <WrapperLayout>
      <div className="flex flex-col gap-y-5 xl:flex-row items-start justify-between mt-6 gap-x-6">
        <div className="flex flex-col items-start justify-between gap-y-5 w-full">
          <Container className="px-8 flex items-center w-full h-[119px]">
            <Heading title="Employee Management" />
          </Container>
          <Container className="card py-10 px-8 w-full overflow-y-auto">
            <Form />
          </Container>
        </div>
      </div>
    </WrapperLayout>
  );
};
