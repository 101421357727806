import { AxiosError } from "axios";
import { api } from "config/api";
import { confirmPassword, signIn } from "hooks/useUser";
import { toast } from "react-toastify";

export const fetchUser = async ({ email, password }: signIn) => {
  try {
    const response = await api.post("login/", { email, password });
    return { data: response.data };
  } catch (error) {
    if (error instanceof AxiosError && error.response?.status !== 500) {
      const message: string = error.response?.data.non_field_errors[0];
      toast.error(message);
    } else {
      toast.error("Something went wrong. Please try again");
    }
  }
};

export const sendResetPasswordEmail = async (email: string) => {
  try {
    const response = await api.post("rest-auth/password/reset/", { email });
    return { data: response.data };
  } catch (error) {
    if (error instanceof AxiosError && error.response?.status !== 500) {
      const message: string = error.response?.data.error;
      toast.error(message);
    } else {
      toast.error("Something went wrong. Please try again");
    }
  }
};
export const setNewPassword = async ({
  uid,
  token,
  new_password1,
  new_password2,
}: confirmPassword) => {
  try {
    const response = await api.post(`password/reset/confirm/${uid}/${token}/`, {
      uid,
      token,
      new_password1,
      new_password2,
    });
    return { data: response.data };
  } catch (error) {
    toast.error("Something went wrong. Please try again");
  }
};
