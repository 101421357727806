//@ts-nocheck
import React, { Fragment } from "react";
import leftArrowIcon from "assets/images/leftArrow__icon.png";
import { getFileName } from "utils";
import {
  useTable,
  useGlobalFilter,
  usePagination,
  useSortBy,
} from "react-table";
import { UserSlice } from "redux/features/user/userSlice";
import { useSelector } from "react-redux";
import { FILESCOLUMNS as columns, PATHS } from "constants/index";
import deleteIcon from "assets/images/delete__icon.png";
import Tooltip from "components/Tooltip/Tooltip";
import { useApp } from "context/AppProvider";
interface IProps {
  data: any[];
  employee: any;
}

const Table: React.FC<IProps> = ({ data, employee }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    rows,
    state,
  } = useTable({ columns, data }, useGlobalFilter, useSortBy, usePagination);
  const { pageIndex } = state;
  const { user } = useSelector<RootState, UserSlice>((state) => state.user);
  const { openModal } = useApp();

  return (
    <Fragment>
      <div className="overflow-x w-full mt-8 ">
        <h1 className="font-sans font-bold text-primary text-2xl dark:text-white mb-3">
          {employee?.name} Files
        </h1>
        <table
          {...getTableProps()}
          className="border-t-[1px] border-[#dbdbdb] w-full"
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    className="py-4 text-left font-sans font-bold text-black dark:text-white text-base relative"
                    key={index}
                  >
                    {column.render("Header")}
                  </th>
                ))}
                {user?.isAdmin ? (
                  <th className="py-4 text-left font-sans font-bold text-black dark:text-white text-base">
                    Action
                  </th>
                ) : null}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  className="odd:bg-[#f8f8f8] even:bg-[#fcfcfc] dark:odd:bg-[#24364D] dark:even:bg-[#1B2D44] text-center"
                >
                  {row.cells.map((cell, index) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        className="py-3 text-left pr-12 font-sans font-medium dark:text-white text-primary text-sm"
                      >
                        {index === 1 ? (
                          cell.value ? (
                            <a
                              href={cell.value}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <u>{getFileName(cell.value)}</u>
                            </a>
                          ) : (
                            <span>-</span>
                          )
                        ) : (
                          cell.render("Cell")
                        )}
                      </td>
                    );
                  })}
                  {user?.isAdmin && (
                    <td>
                      <div
                        className="tooltip relative"
                        onClick={() => {
                          const data = JSON.stringify({
                            id: row.original.col3,
                            employee: employee.id,
                          });
                          openModal(data, PATHS.ATTENDANCEREPORT);
                        }}
                      >
                        <img
                          src={deleteIcon}
                          alt="delete-icon"
                          className="max-w-[22px] object-cover dark:invert-[1]"
                        />
                        <Tooltip text="Delete" />
                      </div>
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
        {rows.length === 0 && (
          <h3 className="w-full text-xl font-normal font-sans  text-primary dark:text-disable mt-4 text-center">
            No Files Available{" "}
          </h3>
        )}

        {rows.length > 9 && (
          <div className="flex flex-row gap-4 align-center justify-center mt-10 w-full">
            <button
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
              className="w-[36px] h-[36px] bg-[#F5F5F5]  dark:bg-success "
            >
              <img
                src={leftArrowIcon}
                alt="dropdown-icon"
                className="mx-auto object-cover dark:invert-[1]"
              />
            </button>
            <span className="font-medium text-xl font-sans bg-[#2A3390] dark:bg-darkinput w-[36px] py-1 text-center text-white">
              <strong>{pageIndex + 1}</strong>
            </span>
            <button
              className="w-[36px] h-[36px] bg-[#F5F5F5] dark:bg-success"
              onClick={() => {
                nextPage();
              }}
              disabled={!canNextPage}
            >
              <img
                src={leftArrowIcon}
                alt="dropdown-icon"
                className="mx-auto object-cover rotate-180 dark:invert-[1]"
              />
            </button>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default Table;
