import Loader from "components/Loader/Loader";
import React from "react";
interface IProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  leftIcon?: string;
  iconButton?: boolean;
  isLoading?: boolean;
}

const Button: React.FC<IProps> = ({
  onClick = () => {},
  leftIcon,
  type = "button",
  className = "",
  title,
  iconButton,
  isLoading,
}) => {
  return (
    <>
      {iconButton ? (
        <button
          className={[
            "bg-main text-white py-5 rounded-lg dark:bg-success",
            className,
          ].join(" ")}
          onClick={onClick}
          type={type}
        >
          <img src={leftIcon} alt="icon" className="mx-auto" />
        </button>
      ) : (
        <button
          className={[
            "bg-main text-xl font-extrabold text-white py-5 rounded-lg font-sans relative  dark:bg-darkinput dark:border-darkinput dark:text-white",
            className,
          ].join(" ")}
          onClick={onClick}
          type={type}
          disabled={isLoading}
        >
          {leftIcon && (
            <img
              src={leftIcon}
              alt="icon"
              className="absolute ml-[11%] max-h-[25px] max-w-[25px] object-cover"
            />
          )}
          {isLoading ? <Loader className="bg-white" /> : title}
        </button>
      )}
    </>
  );
};

export default Button;
