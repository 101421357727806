import { createSlice } from "@reduxjs/toolkit";
import { BarChart, ChartData, Task } from "types";

export type DashboardSlice = {
  data: {
    reportItems: {
      current: ChartData;
      previous: ChartData;
    };
    totalAbsenses: ChartData;
    barChart: BarChart;
  };
  reminders: Task[];
  lineChart: ChartData;
};
export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    data: {
      reportItems: {
        current: {
          Sick: 0,
          Personal: 0,
          FMLA: 0,
          LRI: 0,
          NCNS: 0,
          Others: 0,
        },
        previous: {
          Sick: 0,
          Personal: 0,
          FMLA: 0,
          LRI: 0,
          NCNS: 0,
          Others: 0,
        },
      },
      totalAbsenses: {
        Sick: 0,
        Personal: 0,
        FMLA: 0,
        LRI: 0,
        NCNS: 0,
        Others: 0,
      },
      barChart: {},
    },
    reminders: [],
    lineChart: {},
  } as DashboardSlice,
  reducers: {
    fetchedChartData: (state, action) => {
      return {
        ...state,
        data: action.payload,
      };
    },
    fetchedLineChartData: (state, action) => {
      return {
        ...state,
        lineChart: action.payload,
      };
    },
    deleteChartData: (state) => {
      return {
        ...state,
        data: {
          ...state.data,
          barChart: {},
        },
      };
    },
    deleteLineChartData: (state) => {
      return {
        ...state,
        lineChart: {},
      };
    },
    fetchedReminders: (state, action) => {
      return {
        ...state,
        reminders: action.payload,
      };
    },
  },
});

export const {
  fetchedChartData,
  fetchedReminders,
  fetchedLineChartData,
  deleteLineChartData,
  deleteChartData,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
