import React from "react";
import { NAVITEMS } from "constants/index";
import { Link, useLocation } from "react-router-dom";
import { useWindowSize } from "hooks/useWindowSize";

const NavItems = () => {
  const { pathname } = useLocation();
  const { width } = useWindowSize();

  return (
    <div className="flex flex-col gap-y-5 max-w-[297px] lg:w-4/5">
      {NAVITEMS.map((item, index) => {
        return (
          <Link
            key={index}
            to={item.to}
            className={`${
              pathname === item.to
                ? "bg-active py-5 dark:bg-success"
                : "bg-transparent py-4"
            } px-6 flex items-center gap-x-3 rounded-lg w-full`}
          >
            <img
              src={item.icon}
              alt="icon"
              className={`object-cover w-6 h-6 lg:w-5 lg:h-5 2xl:w-6 2xl:h-6 dark:brightness-[10] ${
                pathname === item.to && "dark:brightness-[10]"
              }`}
            />
            <span
              className={`font-sans lg:inline-block  ${
                width > 650 ? "hidden" : "inline-block"
              } ${
                pathname === item.to
                  ? "text-main font-extrabold dark:text-white"
                  : "text-primary font-medium  dark:text-white"
              } 2xl:text-lg text-sm capitalize`}
            >
              {item.title}
            </span>
          </Link>
        );
      })}
    </div>
  );
};

export default NavItems;
