import React, { Fragment } from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import { UserSlice } from "redux/features/user/userSlice";
import { PATHS } from "constants/index";
interface IProps {
  children: React.ReactNode;
}
export const ProtectedRoutes = ({ children }: IProps) => {
  const { user } = useSelector<RootState, UserSlice>((state) => state.user);

  if (!user?.isAdmin) {
    return <Navigate to={PATHS.DEFAULT} />;
  }
  return <Fragment>{children}</Fragment>;
};
