import { useCallback, useState } from "react";
import { toast } from "react-toastify";
import {
  addEmployee,
  addSupervisor,
  deleteEmployee,
  fetchAllEmployees,
  fetchEmployeeAbsences,
  fetchEmployeeByID,
  fetchSubRoles,
  postLog,
  updateEmployeeInfo,
  fetchEmployeeAttendanceReport,
  fetchEmployeeInfractionComparison,
  excuseInfraction,
  fetchEmployeeTotalPoints,
  fetchAllEmployeesAttendanceReport,
  uploadFile,
  getFiles,
  deleteFile,
} from "redux/api/employee";
import { useAppDispatch } from "redux/store";
import {
  fetchedEmployeesSuccess,
  fetchedEmployeeAbsencesSuccess,
  emptyEmployeeAbsencesSuccess,
  fetchedEmployeeNoticesSuccess,
  updateEmployeeSuccess,
  fetchedAttendanceReportSuccess,
  updateSelectedEmployee,
  emptyEmployeeAttendaceReport,
  updateTotalPoints,
  fetchedEmployeeFiles,
} from "redux/features/employee/employeeSlice";
import {
  Admin,
  ApiResponse,
  CreateEmpSchema,
  Data,
  Options,
  Employee as EmployeeType,
  SelectedEmployee,
  CreateSupvSchema,
  ChartData,
  Task,
  Reminders,
} from "types";
import { useTeams } from "./useTeams";
import {
  createEvents,
  createInfractionComparsion,
  prepareLineChartData,
  transformData,
} from "utils";
import {
  fetchedChartData,
  fetchedLineChartData,
  fetchedReminders,
} from "redux/features/dashboard/dashboardSlice";
export type Supervisor = {
  employee_id: string;
  email: string;
  password1: string;
  password2: string;
  name: string;
  dept: string;
  work_location: string;
  role: string;
  designation: string;
  sub_roles: Number[];
};
export type Employee = {
  employee_name: string;
  employee_id: string;
  department: string;
  work_location: string;
  status: boolean;
  sub_roles: Number[];
};
export type LogData = {
  employee_name: string;
  employee_id: string;
  infraction_type: number;
  infraction_label: string;
  disciplinary_action: number;
  choose_date: string;
  comments: string;
};

export type ExcusedInfraction = {
  isExcused: boolean;
  comment: string;
  employeeId: string | number;
  date: string;
};

export type EmployeeTotalPointsArg = {
  employeeID: string | number;
  date: string;
  type: Options;
};
export const useEmployee = () => {
  const [employeeState, setEmployeeState] = useState<ApiResponse>({
    isLoading: false,
    isError: false,
    error: "",
  });
  const [excusedInfractionState, setExcusedInfractionState] =
    useState<ApiResponse>({
      isLoading: false,
      isError: false,
      error: "",
    });
  const [updateEmployeeState, setUpdateEmployeeState] = useState<ApiResponse>({
    isLoading: false,
    isError: false,
    error: "",
  });
  const { getTeamMembers } = useTeams();
  const dispatch = useAppDispatch();

  /****UPDATING EMPLOYEE REDUX STORE****/
  const deleteEmployeeAbsences = useCallback(() => {
    dispatch(emptyEmployeeAbsencesSuccess());
  }, [dispatch]);

  const deleteUpdateEmployeeInfo = useCallback(() => {
    dispatch(updateEmployeeSuccess(undefined));
  }, [dispatch]);
  const changeSelectedEmployee = useCallback(
    (employee: SelectedEmployee | undefined) => {
      dispatch(updateSelectedEmployee(employee));
    },
    [dispatch]
  );
  const deleteEmployeeAttendanceReportData = useCallback(() => {
    dispatch(emptyEmployeeAttendaceReport());
  }, [dispatch]);
  const resetTotalPoints = useCallback(() => {
    dispatch(updateTotalPoints(0));
  }, [dispatch]);
  /****CRUD EMPLOYEE****/

  const getAllEmployees = useCallback(async () => {
    const response = await fetchAllEmployees();
    if (response) {
      dispatch(fetchedEmployeesSuccess(response.data));
    }
  }, [dispatch]);
  const createEmployee = useCallback(
    async (employee: Employee) => {
      //set loading to true
      setEmployeeState((prev) => {
        return { ...prev, isLoading: true };
      });
      const response = await addEmployee(employee);
      if (response) {
        getAllEmployees();
        toast.success("New employee created");
      }
      //set loading to false
      setEmployeeState((prev) => {
        return { ...prev, isLoading: false, isError: false };
      });
    },
    [getAllEmployees]
  );

  const uploadEmployeeFile = useCallback(async (data: any) => {
    //set loading to true
    setEmployeeState((prev) => {
      return { ...prev, isLoading: true };
    });
    await uploadFile(data);
    //set loading to false
    setEmployeeState((prev) => {
      return { ...prev, isLoading: false, isError: false };
    });
  }, []);

  const getEmployeeById = useCallback(
    async (id: string | number, role: string) => {
      const response = await fetchEmployeeByID(id, role);
      if (response) {
        return response.data;
      }
    },
    []
  );

  const removeEmployee = useCallback(
    async (data: string) => {
      //set loading to true
      setEmployeeState((prev) => {
        return { ...prev, isLoading: true };
      });
      const response = await deleteEmployee(data);
      if (response) {
        getTeamMembers();
        getAllEmployees();
        toast.success("Employee deleted");
      }
      //set loading to false
      setEmployeeState((prev) => {
        return { ...prev, isLoading: false, isError: false };
      });
    },
    [getAllEmployees, getTeamMembers]
  );

  const updateEmployee = useCallback(
    async (employee: SelectedEmployee) => {
      setUpdateEmployeeState((prev) => {
        return {
          ...prev,
          isLoading: true,
        };
      });
      const response = await getEmployeeById(employee.id, employee.role);
      if (response && employee.role === "employee") {
        const employee: CreateEmpSchema = {
          employeeID: response.employee_id,
          employeeName: response.employee_name,
          deptName: response.department,
          // workLocation: response.work_location,
          email: response.email ?? "",
          subRole: response.sub_roles,
          admin: Admin.Employee,
          status: response.status,
        };
        // console.log("response", response);
        dispatch(updateEmployeeSuccess(employee));
      }
      if (response && employee.role === "supervisor") {
        const supervisor: CreateSupvSchema = {
          employeeID: response.id,
          employeeName: response.name,
          deptName: response.dept,
          designation: response.designation,
          workLocation: response.work_location,
          email: response.email ?? "",
          subRole: response.sub_roles,
          admin: Admin.Supervisor,
          status: false,
        };
        // console.log("response", response);
        dispatch(updateEmployeeSuccess(supervisor));
      }
      setUpdateEmployeeState((prev) => {
        return {
          ...prev,
          isLoading: false,
        };
      });
    },
    [getEmployeeById, dispatch]
  );

  const editEmployee = useCallback(
    async (employee: Employee | Supervisor, type: string) => {
      //set loading to true
      setEmployeeState((prev) => {
        return { ...prev, isLoading: true };
      });
      const response = await updateEmployeeInfo(employee, type);
      if (response) {
        toast.success("Employee info updated");
      }
      //set loading to false
      setEmployeeState((prev) => {
        return { ...prev, isLoading: false, isError: false };
      });
    },
    []
  );

  /****CRUD SUPERVISOR****/

  const createSupervisor = useCallback(async (supervisor: Supervisor) => {
    //set loading to true
    setEmployeeState((prev) => {
      return { ...prev, isLoading: true };
    });
    const response = await addSupervisor(supervisor);
    console.log(response);
    if (response) {
      toast.success("New Supervisor created");
    }
    //set loading to false
    setEmployeeState((prev) => {
      return { ...prev, isLoading: false, isError: false };
    });
  }, []);

  /****EMPLOYEE DETAILS****/

  const getEmployeeNotices = useCallback(
    async (employee: EmployeeType) => {
      const response = await getEmployeeById(employee.id, "employee");
      if (response) {
        //save warnings in redux
        const notices: Data[] = response.notices.map(
          (item: { [key: string]: string }) => {
            const date = Object.values(item)[0].split("-");
            return {
              title: `${employee.name} was on issued disciplinary notice on <strong>${date[1]}-${date[2]}-${date[0]}</strong>`,
            };
          }
        );
        const reminders: Task[] = response.reminders.map((item: Reminders) => {
          return {
            id: item.id,
            name: response.employee_name,
            task: `${item.points} points ${item.reminder}`,
            status: "pending",
          };
        });
        dispatch(fetchedReminders(reminders));
        dispatch(fetchedEmployeeNoticesSuccess(notices));
      }
    },
    [dispatch, getEmployeeById]
  );

  const getSubRoles = useCallback(async () => {
    const response = await fetchSubRoles();
    return response?.data;
  }, []);

  const getEmployeeAbsences = useCallback(
    async (employee: EmployeeType, dates: string) => {
      setEmployeeState((prev) => {
        return { ...prev, isLoading: true };
      });
      const response = await fetchEmployeeAbsences(employee.id, dates);
      if (response?.data) {
        // console.log("response", response.data);

        const result: Data[] = Object.keys(response.data).map((type) => {
          //@ts-ignore
          const totalPoints = response.data[type].total_points;

          return {
            title: `Operator <strong>${employee.name}</strong> had <strong>${
              totalPoints === null ? 0 : totalPoints
            } infraction points this ${type.toLowerCase()}</strong>`,
          };
        });

        dispatch(fetchedEmployeeAbsencesSuccess(result));
        // changeSelectedEmployee({
        //   ...employee,
        //   role: "employee",
        // });
      }
      setEmployeeState((prev) => {
        return { ...prev, isLoading: false };
      });
    },

    [dispatch]
  );

  //for attendance report because we donot want to trigger loading state everytime we call it on att. rep. screen
  const getAbsences = useCallback(
    async (employee: SelectedEmployee, dates: string) => {
      try {
        const response = await fetchEmployeeAbsences(employee.id, dates);
        if (response?.data) {
          // console.log("response", response.data);

          const result: Data[] = Object.keys(response.data).map((type) => {
            //@ts-ignore
            const totalPoints = response.data[type].total_points;

            return {
              title: `Operator <strong>${employee.name}</strong> had <strong>${
                totalPoints === null ? 0 : totalPoints
              } infraction points this ${type.toLowerCase()}</strong>`,
            };
          });

          dispatch(fetchedEmployeeAbsencesSuccess(result));
        }
      } catch (e) {}
    },
    [dispatch]
  );

  const callOutLog = useCallback(async (data: LogData) => {
    //set loading to true
    setEmployeeState((prev) => {
      return { ...prev, isLoading: true };
    });
    const response = await postLog(data);
    if (response) {
      toast.success("Employee Informed");
    }
    //set loading to false
    setEmployeeState((prev) => {
      return { ...prev, isLoading: false, isError: false };
    });
  }, []);

  const getEmployeeInfractionComparison = useCallback(
    async (employeeName: string) => {
      const response = await fetchEmployeeInfractionComparison(employeeName);
      if (response?.data) {
        const result = createInfractionComparsion(response.data);
        return result;
      }
    },
    []
  );
  const getEmployeeFiles = useCallback(
    async (id: number) => {
      const response = await getFiles(id);
      dispatch(fetchedEmployeeFiles(response));
    },
    [dispatch]
  );
  const removeFile = useCallback(
    async (data: string) => {
      //set loading to true
      setEmployeeState((prev) => {
        return { ...prev, isLoading: true };
      });
      const response = await deleteFile(data);
      if (response) {
        const id = JSON.parse(data).employee;
        getEmployeeFiles(id);
        toast.success("File deleted");
      }
      //set loading to false
      setEmployeeState((prev) => {
        return { ...prev, isLoading: false, isError: false };
      });
    },
    [getEmployeeFiles]
  );
  const getEmployeeAttendanceReport = useCallback(
    async (data: { type: Options; date: string; employeeName: string }) => {
      //set loading to true
      setEmployeeState((prev) => {
        return { ...prev, isLoading: true, isError: false };
      });
      try {
        let exportReportData:any = null
        const response = await fetchEmployeeAttendanceReport(data);
        if((data.type === Options.YEARLY || data.type === Options.CUSTOM)){
          exportReportData = await fetchEmployeeAttendanceReport({...data, type:Options.WEEKLY});
        }
        const comparisonData = await getEmployeeInfractionComparison(
          data.employeeName
        );
        if (
          response.data &&
          (data.type === Options.YEARLY || data.type === Options.CUSTOM)
        ) {
          const { result } = transformData(response.data);
          dispatch(fetchedChartData(result));
          let chartData: ChartData = {};
          for (let [key, value] of Object.entries(response.data.bar_chart)) {
            const data = Object.keys(value as ChartData)
              .filter((key) => key === "absences")
              .reduce((obj: any, key) => {
                //@ts-ignore
                obj[key] = value[key];
                return obj;
              }, {});
            chartData[key] = data.absences;
          }

          const lineChartData = prepareLineChartData(chartData);

          dispatch(fetchedLineChartData(lineChartData));
        }
        if (
          response.data &&
          (data.type === Options.WEEKLY || data.type === Options.MONTHLY || exportReportData)
        ) {
          deleteEmployeeAttendanceReportData();
          //create events from data
          const events = createEvents(exportReportData ? exportReportData.data : response.data);
          //save in redux store
          dispatch(
            fetchedAttendanceReportSuccess({
              events,
              comparisonData,
            })
          );
          //returnning to use in infraction form
          return events;
        }
      } catch (error) {
        // console.log("error", error);
        //set error to true && loading to false
        setEmployeeState((prev) => {
          return { ...prev, isError: true, isLoading: false };
        });
      } finally {
        setEmployeeState((prev) => {
          return { ...prev, isLoading: false };
        });
      }
    },
    [
      getEmployeeInfractionComparison,
      dispatch,
      deleteEmployeeAttendanceReportData,
    ]
  );

  const excuseEmployeeeInfraction = useCallback(
    async (data: ExcusedInfraction, employeeName: string, id:string) => {
      //set loading to true
      setExcusedInfractionState((prev) => {
        return { ...prev, isLoading: true, isError: false };
      });
      try {
        const response = await excuseInfraction({...data,id});
        if (response.message.length > 0) {
          toast.success(response.message);
          getEmployeeAttendanceReport({
            type: Options.WEEKLY,
            date: "",
            employeeName,
          });
        }
      } catch (e) {
        setEmployeeState((prev) => {
          return { ...prev, isError: true, isLoading: false };
        });
      } finally {
        setExcusedInfractionState((prev) => {
          return { ...prev, isLoading: false };
        });
      }
    },
    [getEmployeeAttendanceReport]
  );

  const getEmployeeTotalPoints = useCallback(
    async (arg: EmployeeTotalPointsArg) => {
      resetTotalPoints();
      const response = await fetchEmployeeTotalPoints(arg);
      //save in redux store
      if (response.data.total_points) {
        dispatch(updateTotalPoints(response.data.total_points));
        return response.data.total_points;
      }
    },
    [dispatch, resetTotalPoints]
  );
  const getAllEmployeesAttendaceReport = useCallback(async () => {
    //set loading to true
    setEmployeeState((prev) => {
      return { ...prev, isLoading: true, isError: false };
    });
    try {
      const response = await fetchAllEmployeesAttendanceReport();

      if (response.data) {
        //create events from data
        // console.log("response.data", response.data);
        const events = createEvents(response.data, true);

        //save in redux store
        dispatch(
          fetchedAttendanceReportSuccess({
            events,
            comparisonData: [],
          })
        );
      }
    } catch (error) {
      // console.log("error", error);
      //set error to true && loading to false
      setEmployeeState((prev) => {
        return { ...prev, isError: true, isLoading: false };
      });
    } finally {
      setEmployeeState((prev) => {
        return { ...prev, isLoading: false };
      });
    }
  }, [dispatch]);

  return {
    updateEmployeeState,
    employeeState,
    createEmployee,
    createSupervisor,
    removeEmployee,
    getSubRoles,
    getAllEmployees,
    getEmployeeAbsences,
    callOutLog,
    deleteEmployeeAbsences,
    deleteUpdateEmployeeInfo,
    updateEmployee,
    getEmployeeNotices,
    editEmployee,
    getEmployeeAttendanceReport,
    changeSelectedEmployee,
    getEmployeeInfractionComparison,
    excusedInfractionState,
    excuseEmployeeeInfraction,
    getEmployeeTotalPoints,
    getAllEmployeesAttendaceReport,
    uploadEmployeeFile,
    getEmployeeFiles,
    removeFile,
    getAbsences,
  };
};
