import React, { useState } from "react";
import Datepicker from "react-tailwindcss-datepicker";
import { DateValueType } from "react-tailwindcss-datepicker/dist/types";
interface IProps {
  label: string;
  error: string | boolean | undefined;
  className?: string;
  onClick: (x: string) => void;
  style?: React.CSSProperties | undefined;
  placeholder: string;
  name: string;
}

const DatePicker: React.FC<IProps> = ({
  label,
  placeholder,
  onClick,
  error,
  name,
  style,
  className,
}) => {
  const handleValueChange = (newValue: DateValueType) => {
    if (newValue?.startDate) {
      setValues(newValue);
      onClick(newValue?.startDate.toString()!);
    }
    else onClick('')
  };
  const [values, setValues] = useState<DateValueType>(null);

  return (
    <div className={["datepicker", className].join(" ")} style={style}>
      <label
        className="text-sm font-bold font-sans text-main mb-[19px] dark:text-white"
        htmlFor={name}
      >
        {label}
      </label>
      <Datepicker
        value={values}
        onChange={handleValueChange}
        placeholder={placeholder || "Custom Date"}
        displayFormat={"MM/DD/YYYY"}
        startWeekOn={"sun"}
        useRange={false}
        asSingle={true}
        containerClassName={"mt-[19px]"}
        inputClassName="py-4 border rounded-md border-light text-black font-sans font-bold dark:border-darkinput dark:bg-darkinput text-base focus-visible:outline-main placeholder:font-normal placeholder:text-placeholder dark:placeholder:text-darkplaceholder dark:text-white "
      />
      {error && <p className="font-sans text-sm text-error mt-1">{error}</p>}
    </div>
  );
};

export default DatePicker;
