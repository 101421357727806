import { useCallback, useState } from "react";
import { toast } from "react-toastify";
import {
  fetchDashboardData,
  fetchTaskReminders,
  removeReminder,
} from "redux/api/dashboard";
import {
  fetchedChartData,
  fetchedReminders,
  fetchedLineChartData,
  deleteLineChartData,
  deleteChartData,
} from "redux/features/dashboard/dashboardSlice";
import { useAppDispatch } from "redux/store";
import { ApiResponse, Options, ChartData, Reminders, Task } from "types";
import { prepareLineChartData, transformData } from "utils";

export const useDashboard = () => {
  const [chartDataState, setChartDataState] = useState<ApiResponse>({
    isLoading: false,
    isError: false,
    error: "",
  });
  const [tasksState, setTasksState] = useState<ApiResponse>({
    isLoading: false,
    isError: false,
    error: "",
  });
  const dispatch = useAppDispatch();

  const getChartData = useCallback(
    async (type: Options, date: string, deptName: string) => {
      //set loading to true
      setChartDataState((prev) => {
        return { ...prev, isLoading: true };
      });
      try {
        const { data } = await fetchDashboardData(type, date, deptName);
        // console.log("data", data);
        if (data) {
          //save in redux store
          const { result } = transformData(data);

          dispatch(fetchedChartData(result));
          let chartData: ChartData = {};
          for (let [key, value] of Object.entries(data.bar_chart)) {
            const data = Object.keys(value as ChartData)
              .filter((key) => key === "absences")
              .reduce((obj: any, key) => {
                //@ts-ignore
                obj[key] = value[key];
                return obj;
              }, {});
            chartData[key] = data.absences;
          }
          const lineChartData = prepareLineChartData(chartData);
          dispatch(fetchedLineChartData(lineChartData));
        }

        //set loading to false
        setChartDataState((prev) => {
          return { ...prev, isLoading: false, isError: false };
        });
      } catch (error) {
        // console.log("error", error);
        //set error to true && loading to false
        setChartDataState((prev) => {
          return { ...prev, isError: true, isLoading: false };
        });
      }
    },
    [dispatch]
  );

  const getTaskReminders = useCallback(async () => {
    //set loading to true
    setTasksState((prev) => {
      return { ...prev, isLoading: true };
    });
    try {
      const { data } = await fetchTaskReminders();
      if (data) {
        //save in redux store
        const result: Task[] = data.map((item: Reminders) => {
          return {
            id: item.id,
            name: item.name,
            task: `${item.points} points ${item.reminder}`,
            status: "pending",
          };
        });
        dispatch(fetchedReminders(result));
      }
      //set loading to false
      setTasksState((prev) => {
        return { ...prev, isLoading: false, isError: false };
      });
    } catch (error) {
      // console.log("error", error);
      //set error to true && loading to false
      setTasksState((prev) => {
        return { ...prev, isError: true, isLoading: false };
      });
    }
  }, [dispatch]);

  const deleteTaskReminder = useCallback(
    async (id: number) => {
      setTasksState((prev) => {
        return { ...prev, isLoading: true };
      });
      const response = await removeReminder(id);
      if (response) {
        getTaskReminders();
        toast.success(response.data.message);
      }
    },
    [getTaskReminders]
  );
  const emptyLineChartData = () => {
    dispatch(deleteLineChartData());
  };
  const emptyBarChartData = () => {
    dispatch(deleteChartData());
  };
  return {
    chartDataState,
    getChartData,
    getTaskReminders,
    tasksState,
    deleteTaskReminder,
    emptyLineChartData,
    emptyBarChartData,
  };
};
