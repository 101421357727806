import React, { useState, useRef, useEffect } from "react";
import Input from "components/Input/Input";
import { Employee } from "types";
interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  error: string | boolean | undefined;
  data: Employee[];
  handleClick: (item: string) => void;
}

const AutoComplete: React.FC<IProps> = ({
  label,
  placeholder,
  error,
  type,
  style,
  className,
  name,
  data,
  handleClick,
  readOnly,
}) => {
  const employees = data.map((item) => item.name);
  const [results, setResults] = useState<string[]>([]);
  const [value, setValue] = useState("");
  const selectRef = useRef<HTMLDivElement>(null);

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setValue(value);
    if (value.length > 0) {
      const result = employees.filter((item) =>
        item.toLowerCase().includes(value.toLowerCase())
      );
      setResults(result);
    } else {
      setResults([]);
    }
  };
  const handleOutsideClick = (event: any) => {
    if (selectRef.current && !selectRef.current.contains(event.target)) {
      setResults([]);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <div className={["relative", className].join(" ")} ref={selectRef}>
      <Input
        label={label}
        readOnly={readOnly}
        style={style}
        placeholder={placeholder}
        type={type}
        name={name}
        error={error}
        value={value}
        className="w-full"
        onChange={handleChange}
      />
      {results.length > 0 && (
        <ul className="list-none rounded-b-md absolute bg-white border border-main w-full  dark:border-b-white dark:bg-darkinput max-h-[120px] overflow-y-auto p-0  z-20">
          {results.map((value, key) => {
            return (
              <li
                key={key}
                className="text-black font-sans font-bold  dark:text-white px-4 py-2 border-b-main border-b-[1px] dark:border-b-white"
                role="menuitem"
                onClick={() => {
                  handleClick(value);
                  setValue(value);
                  setResults([]);
                }}
              >
                {value}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default AutoComplete;
