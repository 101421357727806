import { api } from "config/api";
import {
  Employee,
  EmployeeTotalPointsArg,
  ExcusedInfraction,
  LogData,
  Supervisor,
} from "hooks/useEmployee";
import { Options } from "types";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import { getCurrentMonth, getCurrentYear } from "utils";
export const addEmployee = async (employee: Employee) => {
  try {
    const response = await api.post("employee/", employee);
    return { data: response.data };
  } catch (error) {
    if (error instanceof AxiosError && error.response?.status !== 500) {
      const message: string = error.response?.data.error;
      toast.error(message);
    } else {
      toast.error("Something went wrong. Please try again");
    }
  }
};

export const addSupervisor = async (supervisor: Supervisor) => {
  try {
    const response = await api.post("registration/", supervisor);
    if (response.data) {
      return { data: response.data };
    } else {
      toast.error(response.data.employee_id[0]);
      return undefined;
    }
  } catch (error) {
    if (error instanceof AxiosError && error.response?.status !== 500) {
      const message: string = error.response?.data.detail;
      toast.error(message);
    } else {
      toast.error("Something went wrong. Please try again");
    }
  }
};

export const fetchEmployeeByID = async (id: number | string, role: string) => {
  try {
    const response = await api.get(`employee/${id}?type=${role}`);
    return { data: response.data };
  } catch (error) {
    if (error instanceof AxiosError && error.response?.status !== 500) {
      const message: string = error.response?.data.error;
      toast.error(message);
    } else {
      toast.error("Something went wrong. Please try again");
    }
  }
};

export const deleteEmployee = async (data: string) => {
  try {
    const { id, role } = JSON.parse(data);
    const response = await api.delete(`employee/${id}?type=${role}`);
    return { data: response.data };
  } catch (error) {
    if (error instanceof AxiosError && error.response?.status !== 500) {
      const message: string = error.response?.data.error;
      toast.error(message);
    } else {
      toast.error("Something went wrong. Please try again");
    }
  }
};

export const deleteFile = async (data: string) => {
  try {
    const { id, employee } = JSON.parse(data);
    const response = await api.delete(`delete-pdf/${id}/${employee}`);
    return { data: response.data };
  } catch (error) {
    if (error instanceof AxiosError && error.response?.status !== 500) {
      const message: string = error.response?.data.error;
      toast.error(message);
    } else {
      toast.error("Something went wrong. Please try again");
    }
  }
};

export const uploadFile = async (data:any) => {
  try {
    await api.post("upload/", data);
    toast.success('File Upload Successfully')
  } catch (error) {
    if (error instanceof AxiosError && error.response?.status !== 500) {
      const message: string = error.response?.data.detail;
      toast.error(message);
    } else {
      toast.error("Something went wrong. Please try again");
    }
  }
};
export const getFiles = async (id:number) => {
  try {
    const {data} = await api.get("pdfs/"+id);
    return data
  } catch (error) {
    if (error instanceof AxiosError && error.response?.status !== 500) {
      const message: string = error.response?.data.detail;
      toast.error(message);
    } else {
      toast.error("Something went wrong. Please try again");
    }
  }
};
export const fetchSubRoles = async () => {
  try {
    const response = await api.get("get-subroles");
    return { data: response.data };
  } catch (error) {
    if (error instanceof AxiosError && error.response?.status !== 500) {
      const message: string = error.response?.data.error;
      toast.error(message);
    } else {
      toast.error("Something went wrong. Please try again");
    }
  }
};

export const fetchAllEmployees = async () => {
  try {
    const response = await api.get("employee-names/");
    return { data: response.data };
  } catch (error) {
    if (error instanceof AxiosError && error.response?.status !== 500) {
      const message: string = error.response?.data.error;
      toast.error(message);
    } else {
      toast.error("Something went wrong. Please try again");
    }
  }
};

export const fetchEmployeeAbsences = async (
  id: number | string,
  dates: string
) => {
  try {
    const { week, year, month } = JSON.parse(dates);
    const response = await api.get(
      `calloutlog-points/${id}?dates=${week}&month=${month}&year=${year}`
    );
    return { data: response.data };
  } catch (error) {
    if (error instanceof AxiosError && error.response?.status !== 500) {
      const message: string = error.response?.data.error;
      toast.error(message);
    } else {
      toast.error("Could not fetch Absences");
    }
  }
};
export const postLog = async (data: LogData) => {
  try {
    const response = await api.post("calloutlog/", data);
    return { data: response.data };
  } catch (error) {
    if (error instanceof AxiosError && error.response?.status !== 500) {
      const message: string = error.response?.data.error;
      toast.error(message);
    } else {
      toast.error("Something went wrong. Please try again");
    }
  }
};

export const updateEmployeeInfo = async (
  employee: Employee | Supervisor,
  type: string
) => {
  try {
    const response = await api.put(
      `employee/${employee.employee_id}?type=${type.toLowerCase()}`,
      employee
    );
    return { data: response.data };
  } catch (error) {
    if (error instanceof AxiosError && error.response?.status !== 500) {
      const message: string = error.response?.data.error;
      toast.error(message);
    } else {
      toast.error("Something went wrong. Please try again");
    }
  }
};

export const fetchEmployeeAttendanceReport = async (data: {
  type: Options;
  date: string;
  employeeName: string;
}) => {
  const { type, date, employeeName } = data;
  if (type === Options.MONTHLY || type === Options.WEEKLY) {
    const response = await api.get(
      `custom-infraction/${employeeName}/?type=week`
    );
    return { data: response.data };
  }
  if (type === Options.YEARLY) {
    const response = await api.get(
      `custom-infraction/${employeeName}/?year=${date}`
    );
    return { data: response.data };
  } else {
    const response = await api.get(
      `custom-infraction/${employeeName}/?custom_dates=${date}`
    );
    return { data: response.data };
  }
};

export const fetchEmployeeInfractionComparison = async (
  employeeName: string
) => {
  try {
    const response = await api.get(
      `infraction/comparison/${employeeName}/${getCurrentYear()}`
    );
    return { data: response.data };
  } catch (error) {
    if (error instanceof AxiosError && error.response?.status !== 500) {
      const message: string = error.response?.data.error;
      toast.error(message);
    } else {
      toast.error("Could not fetch infraction comparison");
    }
  }
};

export const excuseInfraction = async (data: any) => {
  const payload = {
    employee_id: data.employeeId,
    date: data.date,
    excused: data.isExcused,
    comments: data.comment,
    id:data.id
  };
  const response = await api.post("excused-absence/", payload);
  return response.data;
};

export const fetchEmployeeTotalPoints = async (
  data: EmployeeTotalPointsArg
) => {
  const { type, date, employeeID } = data;
  if (type === Options.MONTHLY) {
    const monthly = getCurrentMonth() + "," + getCurrentYear();
    const response = await api.get(`total-points/${employeeID}?month=${monthly}`);
    return { data: response.data };
  }
  if (type === Options.YEARLY) {
    const response = await api.get(`total-points/${employeeID}?year=${getCurrentYear()}`);
    return { data: response.data };
  } else {
    const response = await api.get(`total-points/${employeeID}?dates=${date}`);
    return { data: response.data };
  }
};

export const fetchAllEmployeesAttendanceReport = async () => {
  const response = await api.get("attendance-report/");
  return { data: response.data };
};
