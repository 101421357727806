import React, { Fragment, useEffect, useState } from "react";
import logo from "assets/images/logo.png";
import { useLocation } from "react-router-dom";
import Button from "components/Button/Button";
import logout__icon from "assets/images/login_btn__icon.png";
import Switch from "components/Switch/Switch";
import { useWindowSize } from "hooks/useWindowSize";
import crossIcon from "assets/images/cross__icon.png";
import menuIcon from "assets/images/menu__icon.png";
import NavItems from "components/Sidebar/components/NavItems";
import { useUser } from "hooks/useUser";
import { PATHS } from "constants/index";

interface IProps {}

const LeftSidebar: React.FC<IProps> = () => {
  const { pathname } = useLocation();
  const { width } = useWindowSize();
  const [isOpen, setIsOpen] = useState(false);
  const { signOut } = useUser();

  useEffect(() => {
    setIsOpen(false);
  }, [pathname]);

  return (
    <Fragment>
      {width < 650 && (
        <div
          className={`z-1 absolute right-1 top-2 bg-main dark:bg-success p-2 rounded-lg ${
            pathname === PATHS.FORM && "hidden"
          }`}
          onClick={() => setIsOpen(!isOpen)}
        >
          <img
            src={menuIcon}
            alt="cross"
            className="max-h-[25px] max-w-[25px] object-cover"
          />
        </div>
      )}

      <aside
        className={`bg-white border-[1px] border-main rounded-md border-solid h-[100vh] overflow-scroll lg:w-4/12 xl:w-[30%] ${
          pathname === PATHS.FORM ? "hidden" : "flex"
        }  flex-col lg:items-center pt-9 pb-0 justify-between dark:bg-dark dark:border-dark ${
          width > 650
            ? "sticky max-w-md items-center w-[120px]"
            : `fixed z-20  ${
                isOpen ? "w-full pl-5" : "w-[0px] dark:border-0 p-0"
              } items-start transition-all duration-300 ease-in-out`
        }`}
      >
        <img
          src={logo}
          alt="logo"
          className="max-h-[65px] max-w-[304px] sm:w-4/5 w-3/5 object-cover"
        />
        {width < 650 && (
          <div
            className="absolute right-1 top-1 bg-main dark:bg-success p-2 rounded-lg"
            onClick={() => setIsOpen(!isOpen)}
          >
            <img
              src={crossIcon}
              alt="cross"
              className="max-h-[25px] max-w-[25px] object-cover"
            />
          </div>
        )}
        <NavItems />
        <div className="max-w-[297px] w-4/5 text-center">
          <Switch className="mb-5" />
          <Button
            type="button"
            title={"Sign out"}
            leftIcon={logout__icon}
            className="w-full"
            onClick={signOut}
            iconButton={width < 1024 && width > 650}
          />
        </div>
      </aside>
    </Fragment>
  );
};

export default LeftSidebar;
