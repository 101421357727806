import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "App";
import Modal from "components/Modal/Modal";
import ThemeProvider from "context/ThemeProvider";
import AuthProvider from "context/AuthProvider";
import store from "redux/store";
import AppProvider from "context/AppProvider";
import { persistor } from "redux/store";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <ThemeProvider>
        <AuthProvider>
          <AppProvider>
            <Router>
              <App />
              <Modal />
            </Router>
          </AppProvider>
        </AuthProvider>
      </ThemeProvider>
    </PersistGate>
  </Provider>
);
