import { createSlice } from "@reduxjs/toolkit";
import { Members } from "types";

export type TeamSlice = {
  teamMembers: Members[];
};
export const teamSlice = createSlice({
  name: "team",
  initialState: {
    teamMembers: [],
  } as TeamSlice,
  reducers: {
    fetchedSuccess: (state, action) => {
      return {
        ...state,
        teamMembers: action.payload,
      };
    },
  },
});

export const { fetchedSuccess } = teamSlice.actions;

export default teamSlice.reducer;
